import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-m0160-forms',
    templateUrl: './m0160-forms.component.html',
    styleUrls: ['./m0160-forms.component.scss']
})
export class M0160FormsComponent implements OnInit {
    @Input() modifier: string;

    constructor() {}

    ngOnInit(): void {}
}
