import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetManagerComponent } from './asset-manager.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { ListComponent } from './list/list.component';
import { ListItemComponent } from './list/list-item/list-item.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgPipesModule } from 'ngx-pipes';
import { MatChipsModule } from '@angular/material/chips';
import { AssetEditComponent } from './dialogs/asset-edit/asset-edit.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    declarations: [
        AssetManagerComponent,
        ListComponent,
        ListItemComponent,
        AssetEditComponent,
        ConfirmComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        FormsModule,
        FlexLayoutModule,
        NgPipesModule,
        MatChipsModule,
        MatDialogModule,
        ReactiveFormsModule,
        ScrollingModule,
        ImageCropperModule
    ],
    exports: [AssetManagerComponent],
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE'
        }
    ]
})
export class AssetManagerModule {
    static components = {
        assetManager: AssetManagerComponent
    };
}
