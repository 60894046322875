import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-m0180-video-teaser',
    templateUrl: './m0180-video-teaser.component.html',
    styleUrls: ['./m0180-video-teaser.component.scss']
})
export class M0180VideoTeaserComponent implements OnInit {
    @Input() videotype: 'mp4' | 'youtube';
    @Input() size: 'small' | 'big';
    @Input() headline: string;
    @Input() headlineType: string;
    @Input() textColor: 'white' | 'black';
    @Input() text: string;
    @Input() ctaType: 'button' | 'link';
    @Input() youtubeid: string;
    @Input() mp4: string;
    @Input() ctaText: string;
    @Input() ctaLink: string;
    @Input() bgColor: string;

    youtube = '';

    constructor() {}

    ngOnInit(): void {
        if (this.youtubeid) {
            this.youtube =
                'https://www.youtube-nocookie.com/embed/' + this.youtubeid;
        }
    }
}
