import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubNavigationComponent } from './sub-navigation.component';
import { O020SubnavigationTextLinkModule } from '../../organisms/o020-subnavigation-text-link/o020-subnavigation-text-link.module';

@NgModule({
    declarations: [SubNavigationComponent],
    imports: [CommonModule, O020SubnavigationTextLinkModule]
})
export class SubNavigationModule {}
