import {Component, Input, OnInit} from '@angular/core';
import {AssetFile, AssetType} from '../../../interfaces/AssetFile';
import {ApiService} from '../../../services/api.service';
import {DownloadCenter, DownloadCenterItem, DownloadCenterService} from '../../../services/download-center.service';
import { MatDialog } from '@angular/material/dialog';
import { ShareDownloadOverlayComponent } from '../../helpers/share-download-overlay/share-download-overlay.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'px-download-center',
  templateUrl: './download-center.component.html',
  styleUrls: ['./download-center.component.scss']
})
export class DownloadCenterComponent implements OnInit {

    @Input() shared: string = '0';
    @Input() sharedurl: string = '';

    files: AssetFile[] = [];
    totalFileCount: number;
    downloadAllLink = '/';
    mySharedDownloadsJson: DownloadCenter = {files: []};

    isMobile: boolean;
    filesTotalSize = new BehaviorSubject<number>(0);

    constructor(
        private readonly api: ApiService,
        public downloadCenterService: DownloadCenterService,
        private readonly dialog: MatDialog,
        public breakpointObserver: BreakpointObserver
    ) {
    }

    ngOnInit(): void {
        // init shared Data
        this.initSharedData();
        // first load all file items
        this.loadFiles();
        this.breakpointObserver
            .observe(['(max-width: 992px)'])
            .subscribe((state: BreakpointState) => {
                this.isMobile = state.matches;
            });
    }

    initSharedData() {

        if (this.shared === '1') {

            const urlParams = new URLSearchParams(window.location.search);
            const filesFromUri = urlParams.get('files');
            if (filesFromUri) {
                const fileArrayFromUri = filesFromUri.split('-');
                if (fileArrayFromUri) {
                    /* */
                    for (const itemString of fileArrayFromUri) {
                        const itemAray = itemString.split('_');
                        this.mySharedDownloadsJson.files.push({
                            file: itemAray[0] ? parseInt(itemAray[0]) : 0,
                            variant: itemAray[1] ? parseInt(itemAray[1]) : 0
                        });
                    }
                }
            }
        }
    }

    /**
     * load all files for Downloadcenter and
     * create right data structure for business logic
     */
    async loadFiles() {
        const filesNumberArray = [];
        let filesTotalSizeTmp = 0;


        // get data from Localstorage if not shared link
        if (this.shared === '1') {
            for (const item of this.mySharedDownloadsJson.files) {
                filesNumberArray.push(item.file);
            }
        } else {
            if (this.downloadCenterService.myDownloadsJson !== null && this.downloadCenterService.myDownloadsJson.files !== null) {
                for (const item of this.downloadCenterService.myDownloadsJson.files) {
                    filesNumberArray.push(item.file);
                }
            }
        }


        // get real file data from API
        const data = await this.api.getFilesDownloadCenter(filesNumberArray);
        this.totalFileCount = data.count;
        this.files = data.files;

        // setup data structure
        this.files.map((f) => {
            // setup downloadCenterVariant
            f.downloadCenterVariant = 0;
            if (this.shared === '1') {
                const fileIndex = this.mySharedDownloadsJson.files.map((el) => el.file).indexOf(f.uid);
                if (fileIndex !== -1) {
                    f.downloadCenterVariant = this.mySharedDownloadsJson.files[fileIndex].variant;
                }
            } else {

                const fileIndex = this.downloadCenterService.myDownloadsJson.files.map((el) => el.file).indexOf(f.uid);
                if (fileIndex !== -1) {
                    f.downloadCenterVariant = this.downloadCenterService.myDownloadsJson.files[fileIndex].variant;
                }
            }
            // setup preview image
            f.downloadCenterPreview = this.api.getAssetPreview(f.uid, 300);
            // setup download link for single download button for current variant
            f.downloadCenterLink = this.api.getDownloadLink(f.uid, f.downloadCenterVariant);
            // setup the low resolution uid for dropdown
            const low = f.variants.find((v) => v.type === 'low')?.uid;
            if (low) {
                f.downloadCenterLowResId = low;
            }
            if (f.downloadCenterVariant + '' === '0') {
                filesTotalSizeTmp = filesTotalSizeTmp + f.size;
            } else {
                filesTotalSizeTmp = filesTotalSizeTmp + f.variants[0].size;
            }
        });

        this.filesTotalSize.next(filesTotalSizeTmp);

        // finally build download link for zip file with all files in chosen variant
        if (this.shared === '1') {
            this.downloadAllLink = this.api.getZipDownloadLink(this.mySharedDownloadsJson.files);
        } else {
            if (this.downloadCenterService.myDownloadsJson !== null && this.downloadCenterService.myDownloadsJson.files !== null) {
                this.downloadAllLink = this.api.getZipDownloadLink(this.downloadCenterService.myDownloadsJson.files);
            }
        }
    }

    /**
     * remove all file items from downloadcenter
     */
    clearAll(): void {
      this.downloadCenterService.removeAllItems();
      this.loadFiles();
    }

    /**
     * remove single file item from downloadcenter
     * @param item
     */
    removeItem(item: number) {
        this.downloadCenterService.removeItem(item);
        this.loadFiles();
    }

    /**
     * Download single file item via API
     *
     * @param item
     */
    downloadItem(item: number) {
        this.api.getDownloadLink(item);
    }

    /**
     * ngOnChange function when
     * variant dropdown changes for
     * a file item in Downloadcenter
     *
     * @param item
     */
    variantChange(item: DownloadCenterItem): void {
        if (this.shared === '0') {
            this.downloadCenterService.updateItem(item);
        } else {
            if ( this.mySharedDownloadsJson !== null &&  this.mySharedDownloadsJson.files.length > 0) {
                const index = this.mySharedDownloadsJson.files.map((el) => el.file).indexOf(item.file);
                if ( index !== -1) {
                    this.mySharedDownloadsJson.files[index] = item;
                } else {
                    this.mySharedDownloadsJson.files.push(item);
                }
            }
        }
        this.loadFiles();
    }

    /**
     * get share link from files in downloadcenter
     */
    get shareLink(): string {
        const files = [];
        let filesString = '';
        for ( const item  of this.files ) {
            files.push(item.uid + '_' + (item.downloadCenterVariant && (item.downloadCenterVariant + '' !== 'undefined')  ? item.downloadCenterVariant : '0'));
            filesString = this.sharedurl !== '' ? `${this.sharedurl}/?files=${files.join('-')}` :  `${window.location.origin}/geteilte-downloads?files=${files.join('-')}`;
        }

        return (filesString);
    }

    /**
     * Function to add share link to Clip Board
     *
     * @param link
     */
    addLinkToClipBoard(link: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = link;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    openShareDialog(): void {
        this.dialog.open(ShareDownloadOverlayComponent, {
            width: '810px',
            data: {
                copyToClipBoard: this.addLinkToClipBoard(this.shareLink),
                shareLink: this.shareLink
            }
        });
    }

    getFileType(type: AssetType): string {
        switch (type) {
            case 'image': {
                return 'Bild';
            }
            case 'video': {
                return 'Video';
            }
            case 'other': {
                return 'Sonstiges';
            }
            default: {
                return '';
            }
        }
    }
}
