import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { O030FilterGroup } from '../../../interfaces/organisms/o030-filter';
import { M0110MediaTile } from '../../../interfaces/molecules/m0110-media-tile';
import { O100ContactFooter } from '../../../interfaces/organisms/o100-contact-footer';

@Component({
    selector: 'px-t40-mediacenter',
    templateUrl: './t40-mediacenter.component.html',
    styleUrls: ['./t40-mediacenter.component.scss']
})
export class T40MediacenterComponent implements OnInit {
    @Input()
    filterGroups: O030FilterGroup[] = [];

    @Output()
    selectedFilters = new EventEmitter<{
        [filter: string]: Array<string | number>;
    }>();

    @Input()
    hasMore = true;

    @Input()
    tiles: M0110MediaTile[] = [];

    @Input()
    contactFooter: O100ContactFooter;

    @Output()
    loadMore = new EventEmitter<void>();

    constructor() {}

    ngOnInit(): void {}
}
