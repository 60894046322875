import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'px-video-overlay',
    templateUrl: './video-overlay.component.html',
    styleUrls: ['./video-overlay.component.scss']
})
export class VideoOverlayComponent implements OnInit {
    youtube = '';

    constructor(
        public dialogRef: MatDialogRef<VideoOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { youtubeid: string; mp4: string }
    ) {}

    ngOnInit(): void {
        if (this.data.youtubeid) {
            this.youtube =
                'https://www.youtube-nocookie.com/embed/' + this.data.youtubeid;
        }
    }

    close() {
        this.dialogRef.close(true);
    }
}
