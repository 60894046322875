import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0030NavigationButtonListComponent } from './m0030-navigation-button-list.component';
import { A00030PrimaryButtonModule } from '../../atoms/a00030-primary-button/a00030-primary-button.module';

@NgModule({
    declarations: [M0030NavigationButtonListComponent],
    imports: [CommonModule, A00030PrimaryButtonModule],
    exports: [M0030NavigationButtonListComponent]
})
export class M0030NavigationButtonListModule {}
