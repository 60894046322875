<px-a00160-expander
    [modifier]="'b'"
    [text]="label"
    (isOpen)="expanderIsOpen($event)"
>
    <div *ngIf="modifier === M0050FilterCardType.B" class="m0050__inputWrapper">
        <px-a00200-searchfield
            [inputControl]="searchControl"
            inputName="search"
            placeholder="Suche"
        ></px-a00200-searchfield>
    </div>
    <div class="m0050__tagList" [formGroup]="formGroup">
        <div class="m0050__tag" *ngFor="let tag of filteredTags">
            <px-a00210-input-checkbox
                [checkboxId]="filterId + '-' + tag.id"
                [modifier]="tag.modifier"
                [inputName]="tag.label"
                [inputControl]="getCheckboxControl(tag.id + '')"
                [label]="tag.label"
                [isTop]="tag.isTop"
            ></px-a00210-input-checkbox>
        </div>
    </div>
</px-a00160-expander>
