<div class="m0110 m0110--{{ modifier }}">
    <div class="image">
        <px-a00220-media-card
            [modifier]="preview.type"
            [image]="preview.image"
        ></px-a00220-media-card>
    </div>
    <div class="ctas">
        <px-a00100-download-button
            [modifier]="download.type"
            [title]="download.title"
            [downloads]="download.downloads"
            [file]="download.file"
        ></px-a00100-download-button>
    </div>
</div>
