import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'px-envkv-values',
    templateUrl: './envkv-values.component.html',
    styleUrls: ['./envkv-values.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EnvkvValuesComponent implements OnInit, AfterViewInit {
    addClass: boolean = false;
    _showArrow: boolean = true;
    @Input()
    set arrow(a: string) {
        this._showArrow = a === '1';
    }
    envkv: string = '';
    @Input() data: string;
    /* set data(data: string) {
        if(data) {
            this.envkv = data;
        }
    } */

    constructor(private renderer: Renderer2, private el: ElementRef) { }

    toggleClose() {
        this.addClass = !this.addClass;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        const footnoteElements = this.el.nativeElement.querySelectorAll('sup');
        footnoteElements.forEach((footnote: Element) => {
            this.renderer.listen(footnote, 'click', () => {
                const footnoteText = footnote.textContent;
                let scrollDistance = 0;

                if (footnote.classList.contains('star')) {
                    const footnotesElement = document.querySelector('.footnotes');
                    if (footnotesElement instanceof HTMLElement) {
                        scrollDistance = footnotesElement.offsetTop;
                    }
                } else {
                    const footerFootnotes = document.querySelectorAll('.footer__footnote');
                    footerFootnotes.forEach((footerFootnote: HTMLElement) => {
                        if (footerFootnote.textContent.includes(footnoteText)) {
                            scrollDistance =
                                footerFootnote.getBoundingClientRect().top +
                                window.pageYOffset -
                                120;
                        }
                    });
                }

                window.scrollTo({
                    top: scrollDistance,
                    behavior: 'smooth'
                });
            });
        });
    }
}
