import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00100DownloadButtonComponent } from './a00100-download-button.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgMathPipesModule, NgStringPipesModule } from 'ngx-pipes';
import { M0170OverlayModule } from '../../molecules/m0170-overlay/m0170-overlay.module';
import {A00300TooltipModule} from "../a00300-tooltip/a00300-tooltip.module";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [A00100DownloadButtonComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        InlineSVGModule.forRoot(),
        NgMathPipesModule,
        M0170OverlayModule,
        NgStringPipesModule,
        A00300TooltipModule,
        MatTooltipModule
    ],
    exports: [A00100DownloadButtonComponent]
})
export class A00100DownloadButtonModule {}
