<div class="m0140">
    <img [src]="image" class="image" [alt]="forename + ' ' + surname" />
    <div class="textWrapper">
        <div>
            <span class="name">{{ forename }} {{ surname }}</span>
            <br />
            <span class="job">{{ jobTitle }}</span>
        </div>
        <px-a00150-contact-links
            [modifier]="A00150ContactLinksType.D"
            [email]="email"
            [phone]="phone"
        ></px-a00150-contact-links>
    </div>
</div>
