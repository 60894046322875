import { Component, Input, OnInit } from '@angular/core';
import {
    M0100Pressfolder,
    M0100PressfolderType
} from '../../../interfaces/molecules/m0100-pressfolder';

@Component({
    selector: 'px-pressfolder',
    templateUrl: './pressfolder.component.html',
    styleUrls: ['./pressfolder.component.scss']
})
export class PressfolderComponent implements OnInit {
    _tiles: M0100Pressfolder[] = [];

    @Input()
    set tiles(str: string) {
        const data = JSON.parse(str);
        if (data) {
            for (const d of data) {
                this._tiles.push(this.buildTile(d));
            }
        }
    }
    constructor() {}

    ngOnInit(): void {}

    buildTile(obj: {
        image: string;
        links: Array<{ link: string; title: string }>;
        imageLabel: string;
    }): M0100Pressfolder {
        return {
            type: M0100PressfolderType.A,
            image: obj.image,
            links: obj.links,
            imageLabel: obj.imageLabel
        };
    }
}
