import { BrowserModule } from '@angular/platform-browser';
import {
    DoBootstrap,
    Injectable,
    Injector,
    NgModule,
    Type
} from '@angular/core';

import { createCustomElement } from '@angular/elements';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AssetManagerComponent } from './components/apps/asset-manager/asset-manager.component';
import { AssetManagerModule } from './components/apps/asset-manager/asset-manager.module';
import { PressMailerComponent } from './components/apps/press-mailer/press-mailer.component';
import { PressMailerModule } from './components/apps/press-mailer/press-mailer.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AtomsModule } from './components/atoms/atoms.module';
import { MediaCenterComponent } from './components/apps/media-center/media-center.component';
import { TemplatesModule } from './components/templates/templates.module';
import { OrganismsModule } from './components/organisms/organisms.module';
import { MoleculesModule } from './components/molecules/molecules.module';
import { MediaCenterModule } from './components/apps/media-center/media-center.module';
import { ShowForDevComponent } from './components/apps/show-for-dev/show-for-dev.component';
import { ShowForDevModule } from './components/apps/show-for-dev/show-for-dev.module';
import { LoadingOverlayComponent } from './components/helpers/loading-overlay/loading-overlay.component';
import { ContactBigComponent } from './components/apps/contact-big/contact-big.component';
import { ContactFooterComponent } from './components/apps/contact-footer/contact-footer.component';
import { ContactBigModule } from './components/apps/contact-big/contact-big.module';
import { ContactFooterModule } from './components/apps/contact-footer/contact-footer.module';
import { MainNavModule } from './components/apps/main-nav/main-nav.module';
import { MainNavComponent } from './components/apps/main-nav/main-nav.component';
import { FooterNavModule } from './components/apps/footer-nav/footer-nav.module';
import { FooterNavComponent } from './components/apps/footer-nav/footer-nav.component';
import { TextImageComponent } from './components/apps/text-image/text-image.component';
import { TextImageModule } from './components/apps/text-image/text-image.module';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    NativeDateAdapter
} from '@angular/material/core';
import { format, parse } from 'date-fns';
import { NewsManagerModule } from './components/apps/news-manager/news-manager.module';
import { NewsManagerComponent } from './components/apps/news-manager/news-manager.component';
import { KeyvisualComponent } from './components/apps/keyvisual/keyvisual.component';
import { KeyvisualModule } from './components/apps/keyvisual/keyvisual.module';
import { NewsComponent } from './components/apps/news/news.component';
import { NewsModule } from './components/apps/news/news.module';
import { NewsDetailComponent } from './components/apps/news-detail/news-detail.component';
import { LastNewsComponent } from './components/apps/last-news/last-news.component';
import { NewsDetailModule } from './components/apps/news-detail/news-detail.module';
import { LastNewsModule } from './components/apps/last-news/last-news.module';
import * as Hammer from 'hammerjs';
import {
    HammerGestureConfig,
    HAMMER_GESTURE_CONFIG,
    HammerModule
} from '@angular/platform-browser';

// Locale
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID } from '@angular/core';
import { SubNavigationModule } from './components/apps/sub-navigation/sub-navigation.module';
import { SubNavigationComponent } from './components/apps/sub-navigation/sub-navigation.component';
import { TermsOfUseService } from './services/terms-of-use.service';
import { TermsOfUseOverlayModule } from './components/helpers/terms-of-use-overlay/terms-of-use-overlay.module';
import { PressfolderModule } from './components/apps/pressfolder/pressfolder.module';
import { PressfolderComponent } from './components/apps/pressfolder/pressfolder.component';
import { DownloadCenterComponent } from './components/apps/download-center/download-center.component';
import { DownloadCenterModule } from './components/apps/download-center/download-center.module';

import { NewsDownloadOverlayComponent } from './components/helpers/news-download-overlay/news-download-overlay.component';
import { FixedImageGridComponent } from './components/apps/fixed-image-grid/fixed-image-grid.component';
import {FixedImageGridModule} from './components/apps/fixed-image-grid/fixed-image-grid.module';
import { ImageGalleryComponent } from './components/apps/image-gallery/image-gallery.component';
import {ImageGalleryModule} from './components/apps/image-gallery/image-gallery.module';
import { AccordionTeaserComponent } from './components/apps/accordion-teaser/accordion-teaser.component';
import {AccordionTeaserModule} from './components/apps/accordion-teaser/accordion-teaser.module';
import { PodcastModule } from './components/apps/podcast/podcast.module';
import { PodcastComponent } from './components/apps/podcast/podcast.component';
// import { TechnicalDifficultiesService } from './services/technical-difficulties.service';
// import { TechnicalDifficultiesOverlayModule } from './components/helpers/technical-difficulties-overlay/technical-difficulties-overlay.module';
import { ShareDownloadOverlayComponent } from './components/helpers/share-download-overlay/share-download-overlay.component';
import { VideoTeaserModule } from './components/apps/video-teaser/video-teaser.module';
import { VideoTeaserComponent } from './components/apps/video-teaser/video-teaser.component';
import { AudioPlayerModule } from './components/apps/audio-player/audio-player.module';
import { AudioPlayerComponent } from './components/apps/audio-player/audio-player.component';
import { ContentTeaserModule } from './components/apps/content-teaser/content-teaser.module';
import { ContentTeaserComponent } from './components/apps/content-teaser/content-teaser.component';
import { BulletListTeaserModule } from './components/apps/bullet-list-teaser/bullet-list-teaser.module';
import { BulletListTeaserComponent } from './components/apps/bullet-list-teaser/bullet-list-teaser.component';

registerLocaleData(localeDe, 'de-DE');

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TextVideoModule } from './components/apps/text-video/text-video.module';
import { TextVideoComponent } from './components/apps/text-video/text-video.component';
import { SocialMediaModule } from './components/apps/social-media/social-media.module';
import { SocialMediaComponent } from './components/apps/social-media/social-media.component';
import { EnvkvValuesComponent } from './components/apps/envkv-values/envkv-values.component';
import { NewFooterComponent } from './components/apps/new-footer/new-footer.component';
import { EnvkvValuesModule } from './components/apps/envkv-values/envkv-values.module';

// Hammer JS
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: { direction: Hammer.DIRECTION_ALL }
    };
}

@Injectable()
class CustomDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: object | string): string {
        if (displayFormat === 'input') {
            return format(date, 'dd.MM.yyyy');
        }

        return date.toDateString();
    }

    parse(value: string): Date | null {
        if (typeof value === 'string' && value) {
            return parse(value, 'dd.MM.yyyy', new Date());
        }
        return null;
    }
}

const MY_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AssetManagerModule,
        PressMailerModule,
        AtomsModule,
        FormsModule,
        ReactiveFormsModule,
        InlineSVGModule,
        NgbModule,
        TemplatesModule,
        OrganismsModule,
        MoleculesModule,
        MediaCenterModule,
        DownloadCenterModule,
        ShowForDevModule,
        ContactBigModule,
        ContactFooterModule,
        MainNavModule,
        FooterNavModule,
        TextImageModule,
        NewsManagerModule,
        NewsModule,
        KeyvisualModule,
        HammerModule,
        NewsDetailModule,
        LastNewsModule,
        SubNavigationModule,
        TermsOfUseOverlayModule,
        PressfolderModule,
        MatSnackBarModule,
        FixedImageGridModule,
        ImageGalleryModule,
        AccordionTeaserModule,
        VideoTeaserModule,
        ContentTeaserModule,
        BulletListTeaserModule,
        PodcastModule,
        AudioPlayerModule,
        TextVideoModule,
        SocialMediaModule,
        EnvkvValuesModule
    ],
    declarations: [LoadingOverlayComponent, NewsDownloadOverlayComponent, ShareDownloadOverlayComponent, NewFooterComponent],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
    ]
})
export class AppModule implements DoBootstrap {
    // tslint:disable-next-line:no-any
    protected elements: Array<{ selector: string; component: Type<any> }> = [
        {
            selector: 'px-main-nav',
            component: MainNavComponent
        },
        {
            selector: 'px-sub-navigation',
            component: SubNavigationComponent
        },
        {
            selector: 'px-asset-manager',
            component: AssetManagerComponent
        },
        {
            selector: 'px-press-mailer',
            component: PressMailerComponent
        },
        {
            selector: 'px-show-for-dev',
            component: ShowForDevComponent
        },
        {
            selector: 'px-media-center',
            component: MediaCenterComponent
        },
        {
            selector: 'px-download-center',
            component: DownloadCenterComponent
        },
        {
            selector: 'px-news',
            component: NewsComponent
        },
        {
            selector: 'px-news-detail',
            component: NewsDetailComponent
        },
        {
            selector: 'px-last-news',
            component: LastNewsComponent
        },
        {
            selector: 'px-contact-big',
            component: ContactBigComponent
        },
        {
            selector: 'px-contact-footer',
            component: ContactFooterComponent
        },
        {
            selector: 'px-text-image',
            component: TextImageComponent
        },
        {
            selector: 'px-footer-nav',
            component: FooterNavComponent
        },
        {
            selector: 'px-news-manager',
            component: NewsManagerComponent
        },
        {
            selector: 'px-keyvisual',
            component: KeyvisualComponent
        },
        {
            selector: 'px-pressfolder',
            component: PressfolderComponent
        },
        {
            selector: 'px-fixed-image-grid',
            component: FixedImageGridComponent
        },
        {
            selector: 'px-image-gallery',
            component: ImageGalleryComponent
        },
        {
            selector: 'px-accordion-teaser',
            component: AccordionTeaserComponent
        },
        {
            selector: 'px-video-teaser',
            component: VideoTeaserComponent
        },
        {
            selector: 'px-content-teaser',
            component: ContentTeaserComponent
        },
        {
            selector: 'px-bullet-list-teaser',
            component: BulletListTeaserComponent,
        },
        {
            selector: 'px-podcast',
            component: PodcastComponent
        },
        {
            selector: 'px-audio-player',
            component: AudioPlayerComponent
        },
        {
            selector: 'px-text-video',
            component: TextVideoComponent
        },
        {
            selector: 'px-social-media',
            component: SocialMediaComponent
        },
        {
            selector: 'px-envkv-values',
            component: EnvkvValuesComponent
        },
        {
            selector: 'px-new-footer',
            component: NewFooterComponent
        }
    ];

    protected lazyElements: Array<{
        selector: string;
        // tslint:disable-next-line:no-any
        lazyLoad(): Promise<Type<any>>;
    }> = [
        /*{
            selector: 'px-asset-manager',
            lazyLoad: (): Promise<Type<any>> => {
                return import('./components/apps/asset-manager/asset-manager.module')
                    .then(mod => mod.AssetManagerModule)
                    .then(module => module.components.assetManager);
            }
        }*/
    ];

    constructor(
        private injector: Injector,
        private tosService: TermsOfUseService
    ) {}

    ngDoBootstrap(): void {
        for (const el of this.elements) {
            const cEl = createCustomElement(el.component, {
                injector: this.injector
            });
            customElements.define(el.selector, cEl);
        }

        for (const lazyEl of this.lazyElements) {
            // tslint:disable-next-line
            const els = document.getElementsByTagName(lazyEl.selector);
            if (els.length > 0) {
                lazyEl.lazyLoad().then((component) => {
                    const cEl = createCustomElement(component, {
                        injector: this.injector
                    });
                    customElements.define(lazyEl.selector, cEl);
                });
            }
        }

        this.tosService.checkTos();
    }
}
