import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsManagerComponent } from './news-manager.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NewsEditComponent } from './news-edit/news-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PipesModule } from '../../../pipes/pipes.module';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgMathPipesModule, NgStringPipesModule } from 'ngx-pipes';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogModule } from '../../helpers/confirm-dialog/confirm-dialog.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CroppedAreaOverlayModule } from '../../helpers/cropped-area-overlay/cropped-area-overlay.module';

@NgModule({
    declarations: [NewsManagerComponent, NewsEditComponent],
    imports: [
        CommonModule,
        CKEditorModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        FlexLayoutModule,
        PipesModule,
        MatIconModule,
        ScrollingModule,
        NgStringPipesModule,
        NgMathPipesModule,
        MatCheckboxModule,
        MatChipsModule,
        MatButtonModule,
        ConfirmDialogModule,
        ImageCropperModule,
        CroppedAreaOverlayModule
    ]
})
export class NewsManagerModule {}
