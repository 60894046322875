import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0210AccordionTeaserComponent } from './m0210-accordion-teaser.component';

@NgModule({
    declarations: [M0210AccordionTeaserComponent],
    imports: [CommonModule],
    exports: [M0210AccordionTeaserComponent]
})
export class M0210AccordionTeaserModule {}
