import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00190InputTextareaComponent } from './a00190-input-textarea.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [A00190InputTextareaComponent],
    imports: [CommonModule, FormsModule],
    exports: [A00190InputTextareaComponent]
})
export class A00190InputTextareaModule {}
