import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    AfterViewInit,
    HostListener,
    PLATFORM_ID,
    Inject
} from '@angular/core';
import { O030FilterGroup } from '../../../interfaces/organisms/o030-filter';
import { M0050FilterCardTag } from '../../../interfaces/molecules/m0050-filter-card';

import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'px-o030-filter',
    templateUrl: './o030-filter.component.html',
    styleUrls: ['./o030-filter.component.scss']
})
export class O030FilterComponent implements OnInit, AfterViewInit {
    @Input()
    filters: O030FilterGroup[] = [];

    @Output()
    selectedFilters = new EventEmitter<{
        [filter: string]: Array<string | number>;
    }>();

    @ViewChild('o030') o030: ElementRef;

    selectedTags: {
        [filter: string]: Array<string | number>;
    } = {};

    selectedTagsForRendering: Array<{
        tag: M0050FilterCardTag;
        filterId: string;
    }> = [];

    expanderElements: object[] = [];
    previousExpander: {
        collapse: ElementRef;
        collapseButton: ElementRef;
        open: boolean;
    } = null;

    public sticky = false;
    isBrowser: boolean;

    constructor(
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if (this.isBrowser) {
            // tslint:disable-next-line: no-restricted-globals
            const scrollposition = window.pageYOffset;
            this.o030Sticky(scrollposition);
        }
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        // tslint:disable-next-line: no-restricted-globals
        const scrollposition = window.pageYOffset;
        this.o030Sticky(scrollposition);
    }

    public o030Sticky(scrollposition: number) {
        const o030position = this.o030.nativeElement.offsetTop;
        if (scrollposition > o030position) {
            this.sticky = true;
        } else {
            this.sticky = false;
        }
    }

    onSelected(identifier: string, selected: Array<string | number>) {
        this.selectedTags[identifier] = selected;

        this.buildTagsForRendering();

        this.selectedFilters.emit(this.selectedTags);
    }

    removeSelectedTag(filterId: string, tagId: number | string) {
        if (this.selectedTags.hasOwnProperty(filterId)) {
            this.selectedTags[filterId] = this.selectedTags[filterId].filter(
                (t) => t + '' !== tagId + ''
            );
            this.buildTagsForRendering();

            this.selectedFilters.emit(this.selectedTags);
        }
    }

    buildTagsForRendering() {
        const tags: Array<{
            tag: M0050FilterCardTag;
            filterId: string;
        }> = [];

        for (const k in this.selectedTags) {
            if (this.selectedTags.hasOwnProperty(k)) {
                const filterGroup = this.filters.find((f) => f.id === k);
                if (filterGroup) {
                    for (const tagId of this.selectedTags[k]) {
                        const tag = filterGroup.filterCard.tags.find(
                            (t) => t.id + '' === tagId + ''
                        );
                        if (tag) {
                            tags.push({
                                filterId: k,
                                tag
                            });
                        }
                    }
                }
            }
        }
        this.selectedTagsForRendering = tags;
    }

    expanderIsOpen(element: {
        collapse: ElementRef;
        collapseButton: ElementRef;
        open: boolean;
    }) {
        // Close previous expander if opened before another is opened
        if (this.previousExpander) {
            this.renderer.removeClass(
                this.previousExpander.collapse.nativeElement,
                'show'
            );
            this.renderer.addClass(
                this.previousExpander.collapseButton.nativeElement,
                'closed'
            );
            this.renderer.setAttribute(
                this.previousExpander.collapseButton.nativeElement,
                'aria-expanded',
                'false'
            );

            // Close element if same element is clicked
            if (this.previousExpander.collapse === element.collapse) {
                setTimeout(() => {
                    this.renderer.removeClass(
                        element.collapse.nativeElement,
                        'show'
                    );
                    this.renderer.addClass(
                        element.collapseButton.nativeElement,
                        'closed'
                    );
                    this.renderer.setAttribute(
                        element.collapseButton.nativeElement,
                        'aria-expanded',
                        'false'
                    );
                }, 0);

                // reset previous expander
                this.previousExpander = null;
                return;
            }
        }

        // Open current clicked expander
        setTimeout(() => {
            this.renderer.addClass(element.collapse.nativeElement, 'show');
            this.renderer.removeClass(
                element.collapseButton.nativeElement,
                'closed'
            );
            this.renderer.setAttribute(
                element.collapseButton.nativeElement,
                'aria-expanded',
                'true'
            );
        }, 0);

        // set previous expander
        this.previousExpander = element;
    }
}
