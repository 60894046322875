import { Component, Input, OnInit } from '@angular/core';
import { MenuEntry } from '../../../interfaces/MenuEntry';


@Component({
    selector: 'px-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
    _menu: MenuEntry[];
    _metaMenu: MenuEntry[];
    _downloadCenter: string;

    @Input()
    set menu(menu: string | MenuEntry[]) {
        if (typeof menu === 'string') {
            this._menu = JSON.parse(menu);
        } else {
            this._menu = menu;
        }
    }

    @Input()
    set metaMenu(metaMenu: string | MenuEntry[]) {
        if (typeof metaMenu === 'string') {
            this._metaMenu = JSON.parse(metaMenu);
        } else {
            this._metaMenu = metaMenu;
        }
    }
    @Input()
    set downloadCenter(downloadCenter: string ) {
        this._downloadCenter = downloadCenter;
    }

    constructor() {}

    ngOnInit(): void {}
}
