import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentTeaserComponent } from './content-teaser.component';
import { M0190ContentTeaserModule } from '../../molecules/m0190-content-teaser/m0190-content-teaser.module';

@NgModule({
    declarations: [ContentTeaserComponent],
    imports: [CommonModule, M0190ContentTeaserModule],
    exports: [ContentTeaserComponent]
})
export class ContentTeaserModule {}
