import { Component, Input, OnInit } from '@angular/core';

export interface ContentTeaser {
    image: string;
    responsiveImages: {
        image?: string;
        imageTablet?: string;
        imageMobile?: string;
    };
    headline: string;
    headlineType: string;
    text: string;
    ctaText: string;
    ctaLink: string;
    ctaSuperscript: string;
    textColor: string;
    bgColor: string;
    modifier: string;
    position: string;
}

@Component({
    selector: 'px-content-teaser',
    templateUrl: './content-teaser.component.html',
    styleUrls: ['./content-teaser.component.scss']
})
export class ContentTeaserComponent implements OnInit {
    content: ContentTeaser;

    @Input()
    set data(data: string) {
        this.content = JSON.parse(data);
    }

    constructor() {}

    ngOnInit(): void {}
}
