import { Component, OnInit, Input } from '@angular/core';
import { A00150ContactLinksType } from '../../../interfaces/atoms/a00150-contact-links';

@Component({
    selector: 'px-m0140-contact-card-small',
    templateUrl: './m0140-contact-card-small.component.html',
    styleUrls: ['./m0140-contact-card-small.component.scss']
})
export class M0140ContactCardSmallComponent implements OnInit {
    @Input() image: string;
    @Input() forename: string;
    @Input() surname: string;
    @Input() jobTitle: string;
    @Input() email: string;
    @Input() phone: string;

    A00150ContactLinksType = A00150ContactLinksType;

    constructor() {}

    ngOnInit(): void {}
}
