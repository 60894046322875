<div class="m-0240 mobile">
    <div class="m-0240__imageWrapper">
        <img [src]="image" [alt]="headline" />
    </div>
    <h3 class="m-0240__headline">
        {{ headline }}
    </h3>
    <p class="m-0240__text" [innerHTML]="text"></p>
    <figure>
        <audio #audioPlayer class='m-0240__audio'
               controls
               src='{{file}}'
        >
            Your browser does not support the audio element.
        </audio>
    </figure>
</div>

<div class="m-0240 desktop">
    <div class="content">
        <div class="row m-0240__desktopContent">
            <div class="col-md-8 m-0240__innerImage">
                <div class="m-0240__imageWrapper">
                    <img [src]="image" [alt]="headline" />
                </div>
            </div>
            <div class="col-md-4 m-0240__innerContent__right">
                <h3 class="m-0240__headline">
                    {{ headline }}
                </h3>
                <p class="m-0240__text" [innerHTML]="text"></p>
                <figure>
                    <audio #audioPlayer class='m-0240__audio'
                           controls
                           src='{{file}}'
                    >
                        Your browser does not support the audio element.
                    </audio>
                </figure>
            </div>
        </div>
    </div>
</div>
