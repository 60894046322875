import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'px-keyvisual',
    templateUrl: './keyvisual.component.html',
    styleUrls: ['./keyvisual.component.scss']
})
export class KeyvisualComponent implements OnInit {
    @Input() modifier: string;
    @Input() image: string;
    @Input() buttonFirstText: string;
    @Input() buttonFirstCta: string;
    @Input() buttonSecondText: string;
    @Input() buttonSecondCta: string;
    @Input() headline: string;
    @Input() headlineSecond: string;

    constructor() {}

    ngOnInit(): void {}
}
