import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionTeaserComponent } from './accordion-teaser.component';
import { M0210AccordionTeaserModule } from '../../molecules/m0210-accordion-teaser/m0210-accordion-teaser.module';

@NgModule({
    declarations: [AccordionTeaserComponent],
    imports: [CommonModule, M0210AccordionTeaserModule],
    exports: [AccordionTeaserComponent]
})
export class AccordionTeaserModule {}
