<div class="t40">
    <px-o030-filter
        [filters]="filterGroups"
        (selectedFilters)="selectedFilters.emit($event)"
    ></px-o030-filter>
    <px-o080-material-mediacenter
        [tiles]="tiles"
        [hasMore]="hasMore"
        (loadMore)="loadMore.emit()"
    ></px-o080-material-mediacenter>
    <px-o100-contact-footer
        *ngIf="contactFooter"
        [teaserHeadline]="contactFooter.teaserHeadline"
        [teaserDescription]="contactFooter.teaserDescription"
        [teaserLink]="contactFooter.teaserLink"
        [teaserLinkName]="contactFooter.teaserLinkName"
        [employees]="contactFooter.employees"
    ></px-o100-contact-footer>
</div>
