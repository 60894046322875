<div class="a00210 a002100--{{ modifier }}" [class.disabled]="disabled">
    <input
        [formControl]="inputControl"
        type="checkbox"
        autocomplete="off"
        name="{{ inputName }}"
        id="{{ checkboxId ? checkboxId : inputName }}"
        [attr.disabled]="disabled ? '' : null"
        [value]="inputName"
    />
    <label for="{{ checkboxId ? checkboxId : inputName }}" [class.top-category]="isTop">
        {{ label }}
        <span *ngIf="required">*</span>
    </label>
</div>
