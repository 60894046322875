import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageGalleryComponent } from './image-gallery.component';
import { M0220ImageGalleryModule } from '../../molecules/m0220-image-gallery/m0220-image-gallery.module';

@NgModule({
    declarations: [ImageGalleryComponent],
    imports: [CommonModule, M0220ImageGalleryModule],
    exports: [ImageGalleryComponent]
})
export class ImageGalleryModule {}
