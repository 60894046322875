import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00090PaginationComponent } from './a00090-pagination.component';

@NgModule({
    declarations: [A00090PaginationComponent],
    imports: [CommonModule],
    exports: [A00090PaginationComponent]
})
export class A00090PaginationModule {}
