import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    ApiService,
    AssetFilter,
    FileDataSource,
    NewsDataSource
} from '../../../../services/api.service';
import { LoadingOverlayService } from '../../../../services/loading-overlay.service';
import {
    News,
    NewsChapter,
    NewsSaveDto,
    NewsType
} from '../../../../interfaces/News';
import { CKEditor5 } from '@ckeditor/ckeditor5-angular/ckeditor';
import * as PXClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
    FormArray,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Category } from '../../../../interfaces/Category';
import { AssetFile, AssetType } from '../../../../interfaces/AssetFile';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { EnvkvVehicle } from 'src/app/interfaces/EnvkvVehicle';
import { EnvkvModel } from 'src/app/interfaces/EnvkvModel';
import {
    CropperPosition,
    Dimensions,
    ImageCroppedEvent,
    LoadedImage
} from 'ngx-image-cropper';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

@Component({
    selector: 'px-news-edit',
    templateUrl: './news-edit.component.html',
    styleUrls: ['./news-edit.component.scss']
})
export class NewsEditComponent implements OnInit {
    editor: CKEditor5.EditorConstructor = PXClassicEditor;

    editorConfig: CKEditor5.Config = {};

    NewsType = NewsType;
    AssetType = AssetType;

    news: News;

    teaserImageUrl = '';
    teaserImageSize: { width: number; height: number };

    headerImageUrl = '';

    form: FormGroup;

    matcher = new MyErrorStateMatcher();

    assetModels: Category[] = [];
    assetModelYears: Category[] = [];
    assetTags: Category[] = [];
    assetFilteredTags: Category[] = [];

    models: Category[] = [];
    selectedModels: Category[] = [];

    modelYears: Category[] = [];
    selectedModelYears: Category[] = [];

    allModelYears: EnvkvModel[] = [];
    allVehicleYears: EnvkvVehicle[] = [];

    selectedEnvkvModels: EnvkvModel[] = [];
    selectedEnvkvVehicles: EnvkvVehicle[] = [];

    tags: Category[] = [];
    filteredTags: Category[] = [];
    selectedTags: Category[] = [];
    tagSearch = '';

    selectedAssets: AssetFile[] = [];
    selectedNews: News[] = [];

    selectedTeaserAsset = 0;

    newsDataSource: NewsDataSource;
    fileDataSource: FileDataSource;

    @ViewChild(CdkVirtualScrollViewport, { static: false })
    viewport: CdkVirtualScrollViewport;

    filter: AssetFilter = {
        model: 0,
        modelYear: 0,
        tag: 0,
        dateFrom: null,
        dateTo: null
    };

    showCropper = false;
    cropperLoadedImage = new BehaviorSubject<LoadedImage>(null);
    scaledImageSize = new BehaviorSubject<Dimensions>(null);
    tmpCropperPosition: CropperPosition;
    cropperPosition = new BehaviorSubject<CropperPosition>(null);
    relativeCropperPosition = combineLatest([
        this.scaledImageSize,
        this.cropperLoadedImage,
        this.cropperPosition
    ]).pipe(
        map(([scaled, loadedImage, position]) => {
            if (!scaled || !loadedImage || !position) {
                return this.tmpCropperPosition || {};
            }
            const ratio = scaled.width / loadedImage.original.size.width;
            return {
                x1: position.x1 * ratio,
                y1: position.y1 * ratio,
                x2: position.x2 * ratio,
                y2: position.y2 * ratio
            };
        })
    );

    constructor(
        public dialogRef: MatDialogRef<NewsEditComponent>,
        private readonly api: ApiService,
        private readonly loading: LoadingOverlayService,
        @Inject(MAT_DIALOG_DATA) public data: { uid: number }
    ) {
        this.newsDataSource = new NewsDataSource(api);
        this.fileDataSource = new FileDataSource(api);
    }

    async ngOnInit(): Promise<void> {
        const id = this.loading.openOverlay('Lade News');
        try {
            await Promise.all([
                (async () => {
                    try {
                        this.allModelYears =
                            await this.api.getAllModelsYearsList();
                    } catch (e) {
                        throw new Error('Konnte Modell Liste nicht laden');
                    }
                })(),
                (async () => {
                    try {
                        this.allVehicleYears =
                            await this.api.getAllVehiclesYearsList();
                    } catch (e) {
                        throw new Error('Konnte Fahrzeug Liste nicht laden');
                    }
                })(),
                (async () => {
                    try {
                        const categories =
                            await this.api.backendGetAllNewsCategories();

                        this.models = categories.models;
                        this.modelYears = categories.modelYears;
                        this.tags = categories.others;

                        this.models.sort((a, b) =>
                            a.title.localeCompare(b.title)
                        );
                        this.modelYears.sort((a, b) =>
                            a.title.localeCompare(b.title)
                        );
                        this.tags.sort((a, b) =>
                            a.title.localeCompare(b.title)
                        );
                        this.filterTags();
                    } catch (e) {
                        throw new Error('Konnte Kategorien nicht laden');
                    }
                })(),
                (async () => {
                    try {
                        const assetCategories =
                            await this.api.backendGetAllAssetCategories();
                        this.assetModels = assetCategories.models;
                        this.assetModelYears = assetCategories.modelYears;
                        this.assetTags = assetCategories.others;

                        this.assetModels.sort((a, b) =>
                            a.title.localeCompare(b.title)
                        );
                        this.assetModelYears.sort((a, b) =>
                            a.title.localeCompare(b.title)
                        );
                        this.assetTags.sort((a, b) =>
                            a.title.localeCompare(b.title)
                        );
                        this.filterAssetsTags('');
                    } catch (e) {
                        throw new Error('Konnte Kategorien nicht laden');
                    }
                })(),
                (async () => {
                    this.cropperPosition.next(null);
                    if (this.data.uid === 0) {
                        this.news = {
                            uid: 0,
                            type: NewsType.NEWS,
                            models: [],
                            modelYears: [],
                            tags: [],
                            headline: '',
                            summary: '',
                            content: '',
                            date: new Date(),
                            teaserImage: '',
                            // headerImage: '',
                            chapters: [],
                            related: [],
                            assets: [],
                            envkv: '',
                            envkvModels: [],
                            envkvVehicles: [],
                            teaserAssetUid: 0,
                            pageId: 0
                        };
                    } else {
                        try {
                            this.news = await this.api.backendGetNewsDetail(
                                this.data.uid
                            );

                            if (this.news.crop) {
                                this.cropperPosition.next({
                                    ...this.news.crop
                                });
                            }
                            if (this.news.teaserAssetUid) {
                                this.selectedTeaserAsset =
                                    this.news.teaserAssetUid;
                            }
                        } catch (e) {
                            console.log(e);
                            throw new Error('Konnte news nicht laden');
                        }
                    }
                })()
            ]);
        } catch (e) {
            alert('Es ist ein Fehler aufgetreten: ' + e.message);
            this.dialogRef.close();
            this.loading.closeOverlay(id);
            return;
        }

        for (const m of this.news.models) {
            this.toggleModel(m);
        }
        for (const my of this.news.modelYears) {
            this.toggleModelYear(my);
        }
        for (const t of this.news.tags) {
            this.toggleTag(t);
        }

        for (const eM of this.news.envkvModels) {
            const model = this.allModelYears.find((m) => m.id === eM);
            this.toggleEnvkvModel(model);
        }

        for (const eV of this.news.envkvVehicles) {
            const vehicle = this.allVehicleYears.find((v) => v.vkcode === eV);
            this.toggleEnvkvVehicle(vehicle);
        }

        for (const related of this.news.related) {
            this.toggleSelectedNews(related);
        }

        // this.headerImageUrl = '/api/news/' + this.news.uid + '/header/show';
        this.teaserImageUrl =
            '/api/news/' +
            this.news.uid +
            '/teaser/show?t=' +
            Date.now() +
            '&noCrop=1';
        this.setTeaserImageSize(this.teaserImageUrl);

        this.buildForm();

        this.applyFileFilter();
        await this.resetFileDataSource();

        this.applyNewsFilter();
        await this.resetNewsDataSource();

        for (const asset of this.news.assets) {
            this.toggleSelectedAsset(asset);
        }

        this.loading.closeOverlay(id);
    }

    showCroppingTool() {
        //this.teaserImageUrl = this.teaserImageUrl.replace('&noCrop=0', '&noCrop=1');
        this.showCropper = true;
    }

    imageLoaded(imgData: LoadedImage) {
        this.cropperLoadedImage.next(imgData);
    }

    cropperLoaded(dimensions: Dimensions) {
        this.scaledImageSize.next(dimensions);
    }

    imageCropped(evt: ImageCroppedEvent) {
        this.tmpCropperPosition = evt.imagePosition;
    }

    saveCropping() {
        this.showCropper = false;
        this.scaledImageSize.next(null);
        this.cropperLoadedImage.next(null);
        this.cropperPosition.next({ ...this.tmpCropperPosition });
        //this.teaserImageUrl = this.teaserImageUrl.replace('&noCrop=1', '&noCrop=0');
    }

    async resetFileDataSource() {
        await this.fileDataSource.reset();
        this.viewport.setRenderedRange({
            start: 0,
            end: this.viewport.getRenderedRange().end + 1
        });
        this.viewport.checkViewportSize();
    }

    applyFileFilter() {
        this.fileDataSource.setFilter(this.filter);
        this.resetFileDataSource();
    }

    async resetNewsDataSource() {
        await this.newsDataSource.reset();
        this.viewport.setRenderedRange({
            start: 0,
            end: this.viewport.getRenderedRange().end + 1
        });
        this.viewport.checkViewportSize();
    }

    applyNewsFilter() {
        this.newsDataSource.setFilter(this.filter);
        this.resetNewsDataSource();
    }

    buildForm() {
        this.form = new FormGroup({
            uid: new FormControl(this.news?.uid),
            hide: new FormControl(this.news?.hide),
            type: new FormControl(this.news?.type),
            headline: new FormControl(this.news?.headline, [
                Validators.required
            ]),
            summary: new FormControl(this.news?.summary),
            pageId: new FormControl(this.news?.pageId),
            content: new FormControl(this.news?.content),
            date: new FormControl(this.news?.date, [Validators.required]),
            teaserImage: new FormControl(this.news?.teaserImage),
            // headerImage: new FormControl(this.news?.headerImage),
            chapters: new FormArray([]),
            related: new FormArray([])
        });

        for (const chapter of this.news?.chapters) {
            this.addChapter(chapter);
        }

        for (const related of this.news?.related) {
            this.addRelatedNews(related);
        }
    }

    filterAssetsTags(search: string) {
        if (!search) {
            this.assetFilteredTags = this.assetTags;
            return;
        }

        const tags = [];
        for (const tag of this.assetTags) {
            if (tag.title.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                tags.push(tag);
            }
        }
        this.assetFilteredTags = tags;
    }

    filterTags() {
        if (!this.tagSearch) {
            this.filteredTags = this.tags;
            return;
        }

        const tags = [];
        for (const tag of this.tags) {
            if (
                tag.title
                    .toLowerCase()
                    .indexOf(this.tagSearch.toLowerCase()) !== -1
            ) {
                tags.push(tag);
            }
        }
        this.filteredTags = tags;
    }

    toggleModel(model: Category) {
        const mIdx = this.models.findIndex((m) => m.uid === model.uid);
        const smIdx = this.selectedModels.findIndex(
            (sm) => sm.uid === model.uid
        );

        if (mIdx !== -1) {
            this.selectedModels.push(model);
            this.models.splice(mIdx, 1);
        } else if (smIdx !== -1) {
            this.models.push(model);
            this.models.sort((a, b) => a.title.localeCompare(b.title));
            this.selectedModels.splice(smIdx, 1);
        }
    }

    toggleModelYear(modelYear: Category) {
        const mIdx = this.modelYears.findIndex((m) => m.uid === modelYear.uid);
        const smIdx = this.selectedModelYears.findIndex(
            (sm) => sm.uid === modelYear.uid
        );

        if (mIdx !== -1) {
            this.selectedModelYears.push(modelYear);
            this.modelYears.splice(mIdx, 1);
        } else if (smIdx !== -1) {
            this.modelYears.push(modelYear);
            this.modelYears.sort((a, b) => a.title.localeCompare(b.title));
            this.selectedModelYears.splice(smIdx, 1);
        }
    }

    toggleEnvkvModel(model: EnvkvModel) {
        const mIdx = this.allModelYears.findIndex((m) => m.id === model.id);
        const smIdx = this.selectedEnvkvModels.findIndex(
            (sm) => sm.id === model.id
        );

        if (mIdx !== -1) {
            this.selectedEnvkvModels.push(model);
            this.allModelYears.splice(mIdx, 1);
        } else if (smIdx !== -1) {
            this.allModelYears.push(model);
            this.allModelYears.sort((a, b) => a.title.localeCompare(b.title));
            this.selectedEnvkvModels.splice(smIdx, 1);
        }
    }

    toggleEnvkvVehicle(vehicle: EnvkvVehicle) {
        const vIdx = this.allVehicleYears.findIndex((m) => m.id === vehicle.id);
        const svIdx = this.selectedEnvkvVehicles.findIndex(
            (sv) => sv.id === vehicle.id
        );

        if (vIdx !== -1) {
            this.selectedEnvkvVehicles.push(vehicle);
            this.allVehicleYears.splice(vIdx, 1);
        } else if (svIdx !== -1) {
            this.allVehicleYears.push(vehicle);
            this.allVehicleYears.sort((a, b) => a.title.localeCompare(b.title));
            this.selectedEnvkvVehicles.splice(svIdx, 1);
        }
    }

    toggleTag(tag: Category) {
        const tIdx = this.tags.findIndex((t) => t.uid === tag.uid);
        const stIdx = this.selectedTags.findIndex((st) => st.uid === tag.uid);

        if (tIdx !== -1) {
            this.selectedTags.push(tag);
            this.tags.splice(tIdx, 1);
        } else if (stIdx !== -1) {
            this.tags.push(tag);
            this.tags.sort((a, b) => a.title.localeCompare(b.title));
            this.selectedTags.splice(stIdx, 1);
        }
        this.filterTags();
    }

    addRelatedNews(related: News['related'][0]) {
        (this.form.controls.related as FormArray).push(
            new FormGroup({
                uid: new FormControl(related.uid),
                headline: new FormControl(related.headline),
                date: new FormControl(related.date),
                image: new FormControl(related.teaserImage),
                summary: new FormControl(related.summary)
            })
        );
    }

    addChapter(chapter: NewsChapter) {
        (this.form.controls.chapters as FormArray).push(
            new FormGroup({
                uid: new FormControl(chapter.uid),
                headline: new FormControl(chapter.headline),
                content: new FormControl(chapter.content)
            })
        );
    }

    addNewChapter() {
        this.addChapter({
            uid: 0,
            headline: '',
            content: ''
        });
    }

    removeChapter(index: number) {
        (this.form.controls.chapters as FormArray).removeAt(index);
    }

    async save() {
        const formValue = this.form.value;
        const news: NewsSaveDto = {
            uid: formValue.uid,
            hide: formValue.hide,
            headline: formValue.headline,
            summary: formValue.summary,
            content: '',
            teaserImage: formValue.teaserImage,
            // headerImage: formValue.headerImage,
            type: formValue.type,
            chapters: [],
            crop: this.cropperPosition.getValue(),
            models: this.selectedModels.map((m) => m.uid),
            modelYears: this.selectedModelYears.map((m) => m.uid),
            tags: this.selectedTags.map((t) => t.uid),
            date: Math.floor(formValue.date.getTime() / 1000),
            related: this.selectedNews.map((r) => r.uid),
            assets: this.selectedAssets.map((a) => a.uid),
            envkvModels: this.selectedEnvkvModels.map((m) => m.id),
            envkvVehicles: this.selectedEnvkvVehicles.map((v) => v.vkcode),
            teaserAssetUid: this.selectedTeaserAsset,
            pageId: parseInt(formValue.pageId)
        };

        if (
            news.type === NewsType.NEWS ||
            news.type === NewsType.PRESSFOLDER_2
        ) {
            news.content = formValue.content;
        }

        if (news.type === NewsType.PRESSFOLDER_2) {
            news.chapters = formValue.chapters;
        }

        if (this.form.invalid) {
            return;
        }

        const id = this.loading.openOverlay('Speichere News');
        try {
            await this.api.backendSaveNews(
                news,
                formValue.teaserImage
                // formValue.headerImage
            );
            this.dialogRef.close(true);
        } catch (e) {
            alert('Beim speichern ist ein Fehler aufgetreten');
        }
        this.loading.closeOverlay(id);
    }

    onTeaserImageChange(event: Event) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
            this.selectedTeaserAsset = 0;
            this.form.patchValue({
                teaserImage: target.files.item(0)
            });
            const reader = new FileReader();
            reader.onload = (evt) => {
                this.teaserImageUrl = evt.target.result.toString();
                this.setTeaserImageSize(this.teaserImageUrl);
            };
            reader.readAsDataURL(target.files.item(0));
            target.value = '';
        }
    }

    setTeaserImageSize(url: string) {
        const img = new Image();
        img.onload = () => {
            this.teaserImageSize = {
                width: img.width,
                height: img.height
            };
        };
        img.src = url;
    }

    selectAssetForTeaser(file: AssetFile) {
        this.form.patchValue({
            teaserImage: null
        });
        this.selectedTeaserAsset = file.uid;
        this.teaserImageUrl = `/api/asset/${file.uid}/show?t=${Date.now()}`;
        this.cropperPosition.next(null);
        this.showCropper = false;
        this.setTeaserImageSize(this.teaserImageUrl);
    }

    onHeaderImageChange(event: Event) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
            this.form.patchValue({
                headerImage: target.files.item(0)
            });
            const reader = new FileReader();
            reader.onload = (evt) => {
                this.headerImageUrl = evt.target.result.toString();
            };
            reader.readAsDataURL(target.files.item(0));
            target.value = '';
        }
    }

    toggleSelectedAsset(asset: AssetFile) {
        const idx = this.selectedAssets.findIndex((sa) => sa.uid === asset.uid);

        if (idx === -1) {
            this.selectedAssets.push(asset);
        } else {
            this.selectedAssets.splice(idx, 1);
        }
    }

    isAssetSelected(asset: AssetFile): boolean {
        return this.selectedAssets.findIndex((sa) => sa.uid === asset.uid) !== -1;
    }

    toggleSelectedNews(news: News) {
        const idx = this.selectedNews.findIndex((sn) => sn.uid === news.uid);

        if (idx === -1) {
            this.selectedNews.push(news);
        } else {
            this.selectedNews.splice(idx, 1);
        }
    }

    isSelectedNews(news: News): boolean {
        return this.selectedNews.findIndex((sn) => sn.uid === news.uid) !== -1;
    }
}
