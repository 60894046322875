import { Component, OnInit, Input } from '@angular/core';
import { O100ContactFooter } from '../../../interfaces/organisms/o100-contact-footer';

@Component({
    selector: 'px-contact-footer',
    templateUrl: './contact-footer.component.html',
    styleUrls: ['./contact-footer.component.scss']
})
export class ContactFooterComponent implements OnInit {
    _contactFooter: O100ContactFooter;

    @Input()
    set contactFooter(footer: string | O100ContactFooter) {
        if (typeof footer === 'string') {
            this._contactFooter = JSON.parse(footer);
        } else {
            this._contactFooter = footer;
        }
    }

    get contactFooter() {
        return this._contactFooter;
    }

    constructor() {}

    ngOnInit(): void {}
}
