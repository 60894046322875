<div class="container-fluid o020__wrapper" #o020 [class.sticky]="sticky">
    <div class="row">
        <px-a00160-expander
            [modifier]="'c'"
            [text]=""
            (isOpen)="expanderIsOpen($event)"
            *ngIf="isMobile && sticky"
        >
            <ng-container *ngTemplateOutlet="o020Element"></ng-container>
        </px-a00160-expander>
        <ng-container *ngIf="!isMobile || !sticky">
            <ng-container *ngTemplateOutlet="o020Element"></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #o020Element>
    <div class="container">
        <div class="o020">
            <px-m0020-link-lists
                [data]="data.m0020LinkListsContent"
            ></px-m0020-link-lists>
            <div class="o020__socialShare">
                <p class="o020__shareText">Artikel teilen:</p>
                <div
                    #shareButtons
                    data-mail-url="mailto:"
                    data-button-style="icon"
                    data-services='["mail","twitter","facebook","linkedin"]'
                    data-lang="de"
                    class="shariff"
                ></div>
                <div class='o020__downloadWrapper' *ngIf="news.assetsDownloadCenter || data.downloads">
                    <a class="o020__download" *ngIf="news.assetsDownloadCenter && news.assetsDownloadCenter.length > 0"
                       (click)="addNewsToDownlodCenter(news.assetsDownloadCenter)"
                    >
                        <div [inlineSVG]="checkAllAssetsInDownloadCenter(news.assetsDownloadCenter) ?
                        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_black.svg' : '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_border_black.svg'"></div>
                    </a>
                    <a *ngIf='data.downloads' class='o020__download' (click)='openDownload()'
                       [inlineSVG]="
                    '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/download/black.svg'"></a>
                </div>
            </div>
        </div>
    </div>
</ng-template>
