import { Component, Input, OnInit } from '@angular/core';
import {
    O120Image,
    O120Modifier
} from 'src/app/interfaces/organisms/o120-slider';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { VideoOverlayComponent } from 'src/app/components/helpers/video-overlay/video-overlay.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'px-o130-social-media',
    templateUrl: './o130-social-media.component.html',
    styleUrls: ['./o130-social-media.component.scss']
})
export class O130SocialMediaComponent implements OnInit {
    @Input() modifier: O120Modifier = O120Modifier.E;
    @Input() buttonText: string;
    @Input() buttonLink: string;
    @Input() name: string;
    @Input() check: string;
    @Input() bgColor: string;
    @Input() textColor: string;
    @Input() images?: O120Image[] = [];
    @Input() image1?: O120Image;
    @Input() image2?: O120Image;
    @Input() image3?: O120Image;

    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
    currentIndex = 0;
    transformValue = 0;
    trackStyle = {
        transform: 'translateX(0)'
    };
    btnPrevStyle = {
        opacity: '0.2',
        cursor: 'default'
    };
    btnNextStyle = {
        opacity: '0.2',
        cursor: 'default'
    };

    constructor(
        private readonly dialog: MatDialog,
        public breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver
            .observe([
                '(max-width: 767px)',
                '(min-width: 768px)',
                '(min-width: 922px)'
            ])
            .subscribe((state: BreakpointState) => {
                this.mobile = state.breakpoints['(max-width: 767px)'];
                this.tablet = state.breakpoints['(min-width: 768px)'];
                this.desktop = state.breakpoints['(min-width: 922px)'];
            });
    }

    ngOnInit(): void {
        if (this.images.length === 0) {
            if (this.image1) {
                this.images.push(this.image1);
            }
            if (this.image2) {
                this.images.push(this.image2);
            }
            if (this.image3) {
                this.images.push(this.image3);
            }
        }

        this.btnState();
    }

    openDialog(youtubeid: string, mp4: string): void {
        this.dialog.open(VideoOverlayComponent, {
            width: '1280px',
            maxWidth: '100vw',
            data: {
                youtubeid,
                mp4
            },
            panelClass: 'video-overlay-panel'
        });
    }

    prevSlide() {
        if (this.currentIndex === 0) {
            return;
        }
        this.currentIndex = this.currentIndex - 1;
        this.btnState();
        this.images.forEach(() => {
            this.transformValue = this.currentIndex * 33;
            this.trackStyle = {
                transform: 'translateX(' + -this.transformValue + '%)'
            };
        });
    }

    nextSlide() {
        if (this.tablet && this.currentIndex === 1) {
            return;
        }
        if (this.currentIndex === 2) {
            return;
        }
        this.currentIndex = this.currentIndex + 1;
        this.btnState();
        this.images.forEach(() => {
            this.transformValue = this.currentIndex * 33;

            this.trackStyle = {
                transform: 'translateX(' + -this.transformValue + '%)'
            };
        });
    }

    btnState() {
        // if (this.mobile) {
        //     this.endIndex = this.slides.length - 1;
        // } else if (this.tablet) {
        //     this.endIndex = this.slides.length - 2;
        // } else {
        //     this.endIndex = this.slides.length - 3;
        // }
        // if (this.currentIndex === 0) {
        //     this.btnPrevStyle = {
        //         opacity: '0.2',
        //         cursor: 'default'
        //     };
        // } else {
        //     this.btnPrevStyle = {
        //         opacity: '1',
        //         cursor: 'pointer'
        //     };
        // }
        // if (this.endIndex <= 0) {
        //     this.deactivateNextButton();
        // } else if (this.currentIndex === this.endIndex) {
        //     this.deactivateNextButton();
        // } else {
        //     this.activateNextButton();
        // }
    }

    deactivateNextButton() {
        this.btnNextStyle = {
            opacity: '0.2',
            cursor: 'default'
        };
    }

    activateNextButton() {
        this.btnNextStyle = {
            opacity: '1',
            cursor: 'pointer'
        };
    }
}
