
<div class="m-0110" data-require="m-0110-gallery">
    <div #slider class="m-0110__content m-0110__content--desktop"
    (swipeleft)="nextSlide()"
    (swiperight)="prevSlide()">
        <div class="m-0110__slider" [ngStyle]="trackStyle">
            <div class="m-0110__slide" *ngFor="let chunk of imagesChunks" [ngStyle]="{'minWidth.px': slideWidth}">
                <div class="m-0110__slideInner" *ngFor="let image of chunk" (click)="openDialog(image.index)">
                    <div class="a-00040">
                        <img class="img-fluid mobile-only"
                        src="{{image.imageMobile}}"
                        *ngIf="image.imageMobile">

                        <img class="img-fluid "
                        src="{{image.imageTablet}}"
                        *ngIf="image.imageTablet"
                        [ngClass]="{'tablet': image.imageMobile, 'mobile': !image.imageMobile}">

                        <img class="img-fluid"
                        src="{{image.image}}"
                        [ngClass]="{'desktop': image.imageTablet , 'from-tablet': !image.imageTablet && image.imageMobile}">
                    </div>
                    <div class="m-0110__overlay" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_enlarge_gallery.svg'" ></div>
                </div>
            </div>


        </div>
        <div class="m-0110__mobileEnlarge" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_enlarge_gallery.svg'"></div>
        <div class="m-0110__sliderBtn m-0110__sliderBtn--prev" [ngStyle]="btnPrevStyle" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_half.svg'" (click)="prevSlide()"></div>
        <div class="m-0110__sliderBtn m-0110__sliderBtn--next" [ngStyle]="btnNextStyle"
        [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_half.svg'" (click)="nextSlide()"></div>
    </div>
</div>
