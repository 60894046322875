<div class="a00180">
    <input
        [formControl]="inputControl"
        type="text"
        name="{{ inputName }}"
        id="{{ inputName }}"
        placeholder=""
        [class.hasError]="
            inputControl.invalid &&
            (inputControl.dirty || inputControl.touched || isSubmitted)
        "
    />
    <label for="{{ inputName }}">
        {{ placeholder }}
        <span *ngIf="required">*</span>
    </label>
</div>
<div
    class="a00180__error"
    *ngIf="
        inputControl.invalid &&
        (inputControl.dirty || inputControl.touched || isSubmitted)
    "
>
    {{ error }}
</div>
