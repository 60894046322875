<div class="o070 o070--{{ modifier }}">
    <div class="o070__tileWrapper">
        <div class="o070__tile" *ngFor="let tile of tiles">
            <px-m0100-pressfolder
                [modifier]="tile.type"
                [image]="tile.image"
                [links]="tile.links"
                [imageLabel]="tile.imageLabel"
            ></px-m0100-pressfolder>
        </div>
    </div>
</div>
