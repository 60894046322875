import { Component, OnInit, Input } from '@angular/core';

export interface Image {
    mode: string;
    image?: string;
    imageTablet?: string;
    imageMobile?: string;
    ctaText?: string;
    ctaLink?: string;
    videotype?: string;
    mp4?: string;
    ctaLinkOverlay?: string;
    youtubeid?: string;
}

export interface SocialMedia {
    buttonText: string;
    buttonLink: string;
    name: string;
    check: string;
    bgColor: string;
    textColor: string;
    image1: Image;
    image2: Image;
    image3: Image;
}

@Component({
    selector: 'px-social-media',
    templateUrl: './social-media.component.html',
    styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {
    content: SocialMedia;

    @Input()
    set data(data: string) {
        this.content = JSON.parse(data);
        if (this.content.image1) {
            this.images.push(this.content.image1);
        }
        if (this.content.image2) {
            this.images.push(this.content.image2);
        }
        if (this.content.image3) {
            this.images.push(this.content.image3);
        }
    }
    images: Image[] = [];

    constructor() {
    }

    ngOnInit(): void {
    }
}
