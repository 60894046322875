<section class='news-detail' *ngIf='news'>
    <div class='row'>
        <div class='col-12'>
            <px-m0010-keyvisual
                [image]="newsImage + ''"
                [altTitle]='news.headline'
                [modifier]='m0010KeyVisualModuleType'
                class='full-width-mobile'
            ></px-m0010-keyvisual>
        </div>
    </div>

    <px-o020-subnavigation-text-link
        *ngIf='o20DataAvailable'
        [data]='o020SubnavigationTextLink'
        [news]='news'
        class='full-width-mobile'
        #stickyElement
    ></px-o020-subnavigation-text-link>

    <ng-container *ngIf="envkvMitsubishi && envkvMitsubishi !== ''">
        <div class="envkv-values">
            <div class="envkv-text" [ngClass]="{closed: addClass}" [innerHTML]="envkvMitsubishi | safeHtml"></div>
            <div class="envkv_arrow show" [ngClass]="{closed: addClass}" (click)="toggleClose()">
                <svg xmlns="http://www.w3.org/2000/svg" id="a" viewBox="0 0 22 22" width="24" height="24" class="lazyloaded">
                    <path d="M11,22C4.93,22,0,17.07,0,11S4.93,0,11,0s11,4.93,11,11-4.93,11-11,11ZM11,1C5.49,1,1,5.49,1,11s4.49,10,10,10,10-4.49,10-10S16.51,1,11,1Zm0,13.31l-.32-.32h0s-4.68-4.68-4.68-4.68l.62-.62,4.38,4.38,4.38-4.38,.62,.62-5,5Z" style="fill:#000;"></path>
                </svg>
            </div>
        </div>
    </ng-container>

    <div
        class='news-detail__content-wrapper'
        *ngIf='news.type !== NewsType.PRESSFOLDER_1'
    >
        <div class='row'>
            <div class='col-12'>
                <div class='news-detail__header'>
                    <p
                        class='news-detail__date'
                        [innerHTML]='news.date | date'
                    ></p>
                    <div class='news-detail__tag'>
                        <px-a00020-attribute
                            *ngFor='let tag of news?.models'
                            [modifier]="'a'"
                            [text]='tag.title'
                        ></px-a00020-attribute>
                        <px-a00020-attribute
                            *ngFor='let tag of news?.modelYears'
                            [modifier]="'a'"
                            [text]='tag.title'
                        ></px-a00020-attribute>
                        <px-a00020-attribute
                            *ngFor='let tag of news?.tags'
                            [modifier]="'a'"
                            [text]='tag.title'
                        ></px-a00020-attribute>
                    </div>
                </div>
            </div>
        </div>

        <div class='row'>
            <div class='col-12'>
                <h1
                    class='news-detail__headline'
                    [innerHTML]='news.headline'
                ></h1>
            </div>
        </div>

        <!-- dont show in news-detail -->
        <div class='row' *ngIf='false'>
            <div class='col-12'>
                <ul class='news-detail__list'>
                    <li *ngFor='let entry of summary' [innerHTML]='entry'></li>
                </ul>
            </div>
        </div>

        <div class='row'>
            <div class='col-12'>
                <p
                    class='news-detail__content-text'
                    [innerHTML]='news.content'
                ></p>
            </div>
        </div>
    </div>

    <div *ngIf='news.type === NewsType.PRESSFOLDER_1 && related?.length > 0'>
        <px-m0070-newsteaser-tile
            [tile]='related[0]'
        ></px-m0070-newsteaser-tile>
        <div class='row'>
            <div
                class='col-12 col-lg-4'
                *ngFor='let tile of related | slice: 1'
            >
                <px-m0090-newsteaser-tile-big
                    [tile]='tile'
                ></px-m0090-newsteaser-tile-big>
            </div>
        </div>
    </div>

    <div
        class='news-detail__accordeon'
        *ngIf='
            news.type === NewsType.PRESSFOLDER_2 && news.chapters?.length > 0
        '
    >
        <!-- <mat-accordion>
            <mat-expansion-panel *ngFor="let chapter of news.chapters">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ chapter.headline }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHTML]="chapter.content"></div>
            </mat-expansion-panel>
        </mat-accordion> -->

        <px-m0130-accordeon [chapters]='news.chapters'></px-m0130-accordeon>
    </div>

    <ng-container
        *ngIf='news.type === NewsType.PAGE && contentElements?.length > 0'>
        <div *ngFor='let element of contentElements'>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.HEADER'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.TEXT'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
                <div [innerHTML]='element.bodytext'></div>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.BULLETS'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
                <ng-container *ngIf='element.bullets_type === 0'>
                    <ul>
                        <li *ngFor="let bulletpoint of element.bodytext.split('\r\n')">
                            {{bulletpoint}}
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf='element.bullets_type === 1'>
                    <ol>
                        <li *ngFor="let bulletpoint of element.bodytext.split('\r\n')">
                            {{bulletpoint}}
                        </li>
                    </ol>
                </ng-container>
                <ng-container *ngIf='element.bullets_type === 2'>
                    <dl>
                        <dt *ngFor="let bulletpoint of element.bodytext.split('\r\n')">
                            {{bulletpoint}}
                        </dt>
                    </dl>
                </ng-container>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.VIDEOTEASER'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
                <px-m0180-video-teaser
                    [videotype]='element.data.data.videotype'
                    [size]='element.data.data.size'
                    [headline]='element.data.data.headline'
                    [headlineType]='element.data.data.headlineType'
                    [textColor]='element.data.data.textColor'
                    [text]='element.data.data.text'
                    [ctaType]='element.data.data.ctaType'
                    [youtubeid]='element.data.data.youtubeid'
                    [mp4]='element.data.data.mp4'
                    [bgColor]='element.data.data.bgColor'
                    [ctaLink]='element.data.data.ctaLink'
                    [ctaText]='element.data.data.ctaText'
                ></px-m0180-video-teaser>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.AUDIO'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
                <px-m0240-audio-player
                    [headline]='element.data.data.headline'
                    [text]='element.data.data.text'
                    [image]='element.data.data.image'
                    [file]='element.data.data.mp3'
                ></px-m0240-audio-player>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.ANCHOR'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
                <div id='{{element.data.anchor}}'></div>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.HEADLINE'>
                <h1 class='mainHeadline'>
                    {{ element.data.headline }}
                </h1>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.MARGIN'>
                <div class='margin--{{ element.data.margin }}'></div>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.KEYVISUAL'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
                <px-m0010-keyvisual
                    [modifier]='element.data.modifier'
                    [image]='element.data.image'
                    [buttonFirstText]='element.data.buttonFirstText'
                    [buttonFirstCta]='element.data.buttonFirstCta'
                    [buttonSecondText]='element.data.buttonSecondText'
                    [buttonSecondCta]='element.data.buttonSecondCta'
                    [headline]='element.data.headline'
                    [headlineSecond]='element.data.headlineSecond'
                ></px-m0010-keyvisual>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.TEXTIMAGE'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
                <px-m0120-text-image
                    [image]="element.data.image"
                    [text]="element.data.text"
                    [headline]="element.data.headline"
                    [imageright]="element.data.imageright"
                ></px-m0120-text-image>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.TEXTVIDEO'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
                <px-m0230-text-video
                    [videoright]="element.data.data.videoright"
                    [videotype]='element.data.data.videotype'
                    [headline]='element.data.data.headline'
                    [text]='element.data.data.text'
                    [youtubeid]='element.data.data.youtubeid'
                    [mp4]='element.data.data.mp4'
                ></px-m0230-text-video>
            </div>

            <div *ngIf='element.contentType === TYPO3PageContentTypes.SOCIALMEDIA'>
                <a *ngIf='element.header_link ' [href]='element.header_link'>
                    <h2>{{element.header}}</h2>
                </a>
                <h2 *ngIf='!element.header_link'>{{element.header}}</h2>
                <h3>{{element.subheader}}</h3>
                <px-o130-social-media
                    [buttonText]="element.data.data.buttonText"
                    [buttonLink]="element.data.data.buttonLink"
                    [name]="element.data.data.name"
                    [check]="element.data.data.check"
                    [bgColor]="element.data.data.bgColor"
                    [textColor]="element.data.data.textColor"
                    [image1]="element.data.data.image1"
                    [image2]="element.data.data.image2"
                    [image3]="element.data.data.image3"
                >
                </px-o130-social-media>
            </div>
        </div>

    </ng-container>


    <div class='row' id='assets' *ngIf='news.assets.length > 0'>
        <div class='col-12'>
            <div class='news-detail__assets'>
                <px-a00250-text-and-line-divider
                    [text]="'Dazugehöriges Material'"
                ></px-a00250-text-and-line-divider>
            </div>
        </div>
        <div class='col-12'>
            <px-o090-material [assets]='news.assets'></px-o090-material>
        </div>
    </div>

    <div
        class='news-detail__aboutMitsubishi'
        *ngIf='news.type === NewsType.NEWS && aboutMitsubishi'
    >
        <h5 class='news-detail__aboutMitsubishiHeadline'>
            {{ aboutMitsubishi.headline }}
        </h5>
        <div [innerHTML]='aboutMitsubishi.content'></div>
    </div>

    <div class='news-detail__envkv' *ngIf='news.envkv'>
        <p [innerHTML]='news.envkv'></p>
    </div>

    <div
        class='row'
        id='related'
        *ngIf='news.type !== NewsType.PRESSFOLDER_1 && related?.length > 0'
    >
        <div class='col-12'>
            <div class='news-detail__related-headline'>
                <px-a00250-text-and-line-divider
                    [text]="'Ähnliche Artikel'"
                ></px-a00250-text-and-line-divider>
            </div>
        </div>
        <div class='col-12'>
            <div class='row'>
                <div class='col-12 col-lg-4' *ngFor='let tile of related'>
                    <px-m0090-newsteaser-tile-big
                        [tile]='tile'
                    ></px-m0090-newsteaser-tile-big>
                </div>
            </div>
        </div>
    </div>
</section>
