<div class="a00150 a00150--{{ modifier }}">
    <a
        href="mailto:{{ email }}"
        class="mail"
        *ngIf="modifier === A00150ContactLinksType.D"
    >
        <span
            [inlineSVG]="
                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/mail.svg'
            "
        ></span>
    </a>
    <a
        href="tel:{{ phone }}"
        class="phone"
        *ngIf="
            modifier === A00150ContactLinksType.A ||
            modifier === A00150ContactLinksType.B ||
            modifier === A00150ContactLinksType.D
        "
    >
        <span
            [inlineSVG]="
                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/phone.svg'
            "
        ></span>
        {{ phone }}
    </a>
    <a
        href="mailto:{{ email }}"
        class="mail"
        *ngIf="modifier === A00150ContactLinksType.C"
    >
        <span
            [inlineSVG]="
                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/mail.svg'
            "
        ></span>
        {{ email }}
    </a>
</div>
