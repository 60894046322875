import {
    Component,
    OnInit,
    Input,
    ViewChildren,
    QueryList,
    ElementRef,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import {
    M0110MediaTile,
    M0110MediaTileType
} from 'src/app/interfaces/molecules/m0110-media-tile';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
    O090Material,
    O090MaterialTabTitle
} from 'src/app/interfaces/organisms/o090-material';
import { AssetFile, AssetType } from 'src/app/interfaces/AssetFile';
import { A00220MediaCardType } from 'src/app/interfaces/atoms/a00220-media-card';
import { A00100DownloadButtonType } from 'src/app/interfaces/atoms/a00100-download-button';
import { ApiService } from '../../../services/api.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'px-o090-material',
    templateUrl: './o090-material.component.html',
    styleUrls: ['./o090-material.component.scss']
})
export class O090MaterialComponent implements OnInit, AfterViewInit, OnDestroy {
    // @Input() tiles: M0110MediaTile[] = [];
    @Input() assets: AssetFile[];

    @ViewChildren('slide') slides: QueryList<ElementRef>;

    public mobile: boolean;
    public tablet: boolean;
    public desktop: boolean;
    private currentIndex: number;
    private endIndex: number;
    private slideCount: number;
    private transformValue: number;
    private tabsTmp: {
        [type in AssetType]: AssetFile[];
    };
    public tiles: M0110MediaTile[] = [];
    public downloadLink: string;
    private subscriptions: Subscription;
    private files: Array<{ file: number; variant?: number }>;

    sliderStyles = {
        transform: 'translateX(0)'
    };
    sliderStylesInner = {
        transform: 'translateX(0)'
    };
    btnPrevStyle = {
        opacity: '0.2',
        cursor: 'default'
    };
    btnNextStyle = {
        opacity: '0.2',
        cursor: 'default'
    };
    btnPrevStyleInner = {
        opacity: '0.2',
        cursor: 'default'
    };
    btnNextStyleInner = {
        opacity: '1',
        cursor: 'pointer'
    };

    public tabs: O090Material[] = [];

    constructor(
        public breakpointObserver: BreakpointObserver,
        private api: ApiService
    ) {
        this.breakpointObserver
            .observe([
                '(max-width: 767px)',
                '(min-width: 768px)',
                '(min-width: 922px)'
            ])
            .subscribe((state: BreakpointState) => {
                this.mobile = state.breakpoints['(max-width: 767px)'];
                this.tablet = state.breakpoints['(min-width: 768px)'];
                this.desktop = state.breakpoints['(min-width: 922px)'];
                console.log(state);
            });
    }

    ngOnInit(): void {
        console.log('assets', this.assets);
        // Set Variables
        this.currentIndex = 0;
        this.tabs = [];

        // Sort Assets after type
        this.tabsTmp = this.assets.reduce((r, a) => {
            r[a.type] = r[a.type] = r[a.type] || [];
            r[a.type].push(a);
            return r;
        }, Object.create([]));

        for (const type of Object.values(AssetType)) {
            if (typeof this.tabsTmp[type as AssetType] !== 'undefined') {
                const tmp: O090Material = {
                    title: O090MaterialTabTitle[type as AssetType],
                    active: false,
                    assets: this.tabsTmp[type as AssetType],
                    tiles: []
                };
                this.tabs.push(tmp);
            }
        }

        /*// Build data object for front end rendering
        for (const asset in this.tabsTmp) {
            if (this.tabsTmp.hasOwnProperty(asset)) {
                const tmp: O090Material = {
                    title: O090MaterialTabTitle[asset as AssetType],
                    active: false,
                    assets: this.tabsTmp[asset as AssetType],
                    tiles: []
                };
                this.tabs.push(tmp);
            }
        }*/

        // Build tiles
        for (const tab of this.tabs) {
            tab.assets.map((asset) => {
                tab.tiles.push(this.buildTile(asset));
            });
        }

        //  Set first element active
        if (this.tabs.length > 0) {
            this.tabs[0].active = true;
        }

        this.buildDownloadLink();
    }

    ngAfterViewInit() {
        this.btnState();
        this.subscriptions = this.slides.changes.subscribe(() =>
            this.btnState()
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    buildDownloadLink() {
        this.files = [];
        this.assets.map((asset) => {
            const tmp = {
                file: asset.uid
            };
            this.files.push(tmp);
        });

        this.downloadLink = this.api.getZipDownloadLink(this.files);
    }

    buildTile(file: AssetFile): M0110MediaTile {
        const tile: M0110MediaTile = {
            id: file.uid,
            type: M0110MediaTileType.A,
            preview: {
                type: A00220MediaCardType.A,
                image: this.api.getAssetPreview(file.uid, 300)
            },
            download: {
                type: A00100DownloadButtonType.A,
                title: file.title,
                downloads: [],
                file
            }
        };

        let label = 'High Res';

        if (file.type === AssetType.VIDEO) {
            tile.preview.type = A00220MediaCardType.C;
            label = 'Video herunterladen';
        } else if (file.fileExtension === 'pdf') {
            tile.preview.type = A00220MediaCardType.G;
            label = 'PDF herunterladen';
        } else if (file.type === AssetType.OTHER) {
            tile.preview.type = A00220MediaCardType.E;
            label = 'Datei herunterladen';
        }

        if (file.variants) {
            const low = file.variants.find((v) => v.type === 'low');
            if (low) {
                tile.download.downloads.push({
                    label: 'Low Res',
                    active: false,
                    size: low.size,
                    link: this.api.getDownloadLink(file.uid, low.uid),
                    click: false
                });
            }
        }

        tile.download.downloads.push({
            label,
            active: false,
            size: file.size,
            link: this.api.getDownloadLink(file.uid),
            click: false
        });

        return tile;
    }

    setActive(value: string) {
        this.resetCarousel();

        this.tabs.map((tab) => {
            tab.active = tab.title === value;
        });

        setTimeout(() => {
            this.btnState();
        }, 0);
    }

    resetCarousel() {
        this.currentIndex = 0;
        this.transformValue = 0;
        this.sliderStyles = {
            transform: 'translateX(' + this.transformValue + '%)'
        };
    }

    moveLeft() {
        if (this.currentIndex > 0) {
            this.prevSlide();
        }
    }

    moveRight() {
        if (this.mobile) {
            this.slideCount = this.slides.length - 1;
        } else if (this.tablet && !this.desktop) {
            this.slideCount = this.slides.length - 2;
        } else {
            this.slideCount = this.slides.length - 3;
        }

        if (this.currentIndex < this.slideCount) {
            this.nextSlide();
        }
    }

    prevSlide() {
        this.currentIndex = this.currentIndex - 1;
        this.btnState();
        this.slides.forEach(() => {
            if (this.mobile) {
                this.transformValue = this.currentIndex * 90 * -1;
            } else if (this.tablet && !this.desktop) {
                this.transformValue = this.currentIndex * 49.5 * -1;
            } else {
                this.transformValue = this.currentIndex * 33.5 * -1;
            }
            this.sliderStyles = {
                transform: 'translateX(' + this.transformValue + '%)'
            };
        });
    }

    nextSlide() {
        this.currentIndex = this.currentIndex + 1;
        this.btnState();
        this.slides.forEach(() => {
            if (this.mobile) {
                this.transformValue = this.currentIndex * 90 * -1;
            } else if (this.tablet && !this.desktop) {
                this.transformValue = this.currentIndex * 49.5 * -1;
            } else {
                this.transformValue = this.currentIndex * 33.5 * -1;
            }

            this.sliderStyles = {
                transform: 'translateX(' + this.transformValue + '%)'
            };
        });
    }

    btnState() {
        if (this.mobile) {
            this.endIndex = this.slides.length - 1;
        } else if (this.tablet) {
            this.endIndex = this.slides.length - 2;
        } else {
            this.endIndex = this.slides.length - 3;
        }

        if (this.currentIndex === 0) {
            this.btnPrevStyle = {
                opacity: '0.2',
                cursor: 'default'
            };
        } else {
            this.btnPrevStyle = {
                opacity: '1',
                cursor: 'pointer'
            };
        }

        if (this.endIndex <= 0) {
            this.deactivateNextButton();
        } else if (this.currentIndex === this.endIndex) {
            this.deactivateNextButton();
        } else {
            this.activateNextButton();
        }
    }

    deactivateNextButton() {
        this.btnNextStyle = {
            opacity: '0.2',
            cursor: 'default'
        };
    }

    activateNextButton() {
        this.btnNextStyle = {
            opacity: '1',
            cursor: 'pointer'
        };
    }
}
