<div class="m0150 m0150--{{ modifier }}">
    <div class="m0150__container">
        <div class="m0150__imgContainer">
            <img
                [src]="image"
                class="m0150__image"
                [alt]="forename + ' ' + surname"
            />
        </div>
        <div class="m0150__textSocialContainer">
            <div class="m0150__textWrapper">
                <div>
                    <span class="m0150__name">
                        {{ forename }} {{ surname }}
                    </span>
                    <br />
                    <span class="m0150__job">{{ jobTitle }}</span>
                </div>
                <div class="m0150__phones">
                    <px-a00150-contact-links
                        *ngIf="phone"
                        [modifier]="A00150ContactLinksType.A"
                        [phone]="phone"
                    ></px-a00150-contact-links>
                    <px-a00150-contact-links
                        *ngIf="mobile"
                        [modifier]="A00150ContactLinksType.A"
                        [phone]="mobile"
                    ></px-a00150-contact-links>
                </div>
                <px-a00150-contact-links
                    *ngIf="email"
                    [modifier]="A00150ContactLinksType.C"
                    [email]="email"
                ></px-a00150-contact-links>
            </div>
            <div class="m0150__socials">
                <div
                    *ngFor="let social of socials"
                    class="m0150__socialElement"
                >
                    <a
                        [href]="social.link"
                        target="_blank"
                        [title]="social.name"
                    >
                        <img [src]="social.icon" [alt]="social.name" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
