import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-a00090-pagination',
    templateUrl: './a00090-pagination.component.html',
    styleUrls: ['./a00090-pagination.component.scss']
})
export class A00090PaginationComponent implements OnInit {
    @Input() active: boolean;

    constructor() {}

    ngOnInit(): void {}
}
