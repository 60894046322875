import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-text-image',
    templateUrl: './text-image.component.html',
    styleUrls: ['./text-image.component.scss']
})
export class TextImageComponent implements OnInit {
    @Input() imageright: string;
    @Input() image: string;
    @Input() headline: string;
    @Input() text: string;

    constructor() {}

    ngOnInit(): void {}
}
