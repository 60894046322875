<div class="a00280">
    <div class="header"></div>
    <div class="content">
        <div></div>
        <div class="right-close">
            <span
                [inlineSVG]="
                    '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icon_error_cross.svg'
                "
            ></span>
        </div>
    </div>
    <div class="footer"></div>
</div>
