import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NewsDto } from 'src/app/interfaces/News';
import { M0070Tile } from 'src/app/interfaces/molecules/m0070-newsteaser-tile';

@Component({
    selector: 'px-last-news',
    templateUrl: './last-news.component.html',
    styleUrls: ['./last-news.component.scss']
})
export class LastNewsComponent implements OnInit {
    @Input()
    count = 3;

    tiles: M0070Tile[] = [];

    constructor(private api: ApiService) {}

    ngOnInit(): void {
        this.loadNews();
    }

    async loadNews() {
        const data = await this.api.getNews([], [], [], [], 0, false, this.count);
        this.tiles = data.news.map((n) => this.buildTile(n));
    }

    buildTile(news: NewsDto): M0070Tile {
        const assets = [];
        if (news.assetsDownloadCenter) {
            for (let asset of news.assetsDownloadCenter) {
                if (asset ) {
                    assets.push(asset);
                }
            }
        }
        return {
            uid: news.uid,
            date: new Date(news.date * 1000),
            headline: news.headline,
            copy: news.summary,
            img: this.api.getNewsPreview(news.uid, 'teaser'),
            tags: [
                ...news.models.map((t) => {
                    return {
                        modifier: '',
                        text: t.title
                    };
                }),
                ...news.modelYears.map((t) => {
                    return {
                        modifier: '',
                        text: t.title
                    };
                }),
                ...news.tags.map((t) => {
                    return {
                        modifier: '',
                        text: t.title
                    };
                })
            ],
            detailUrl: news.detailUrl,
            assets: assets
        };
    }
}
