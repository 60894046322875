import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'px-a00080-to-top-button',
    templateUrl: './a00080-to-top-button.component.html',
    styleUrls: ['./a00080-to-top-button.component.scss']
})
export class A00080ToTopButtonComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
