<div class="m0100 m0100--{{ modifier }}">
    <div class="m0100__image">
        <img [src]="image" alt="" />
        <p class="m0100__overlayText">{{ imageLabel }}</p>
    </div>
    <div class="m0100__links">
        <div class="m0100__link" *ngFor="let link of links">
            <px-a00060-tertiary-button-full
                [link]="link.link"
                [text]="link.title"
            ></px-a00060-tertiary-button-full>
        </div>
    </div>
</div>
