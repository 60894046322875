import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00320IconButtonComponent } from './a00320-icon-button.component';



@NgModule({
  declarations: [A00320IconButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [A00320IconButtonComponent]
})
export class A00320IconButtonModule { }
