import { Component, Inject, OnInit } from '@angular/core';
import {
    AssetFile,
    AssetFileVariant,
    AssetType,
    UpdateFiles,
    UploadFiles
} from '../../../../../interfaces/AssetFile';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Category } from '../../../../../interfaces/Category';
import { ApiService } from '../../../../../services/api.service';
import { LoadingOverlayService } from '../../../../../services/loading-overlay.service';
import {
    CropperPosition,
    Dimensions,
    ImageCroppedEvent,
    LoadedImage
} from 'ngx-image-cropper';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

interface AssetEditDataBase {
    models: Category[];
    modelYears: Category[];
    tags: Category[];
}

interface AssetEditDataSingle extends AssetEditDataBase {
    type: 'single';
    file: AssetFile;
}

interface AssetEditDataMultiple extends AssetEditDataBase {
    type: 'multiple';
    files: AssetFile[];
}

interface AssetEditDataUpload extends AssetEditDataBase {
    type: 'upload';
    uploadFiles: File[];
}

export type AssetEditData =
    | AssetEditDataSingle
    | AssetEditDataMultiple
    | AssetEditDataUpload;

@Component({
    selector: 'px-asset-edit',
    templateUrl: './asset-edit.component.html',
    styleUrls: ['./asset-edit.component.scss']
})
export class AssetEditComponent implements OnInit {
    AssetType = AssetType;

    form: FormGroup;

    models: Category[] = [];
    selectedModels: Category[] = [];

    modelYears: Category[] = [];
    selectedModelYears: Category[] = [];

    tags: Category[] = [];
    filteredTags: Category[] = [];
    selectedTags: Category[] = [];
    tagSearch = '';

    imageUrl = '';
    cropperLoadedImage = new BehaviorSubject<LoadedImage>(null);
    showCropper = false;
    scaledImageSize = new BehaviorSubject<Dimensions>(null);
    tmpCropperPosition: CropperPosition;
    cropperPosition = new BehaviorSubject<CropperPosition>(null);
    relativeCropperPosition = combineLatest([
        this.scaledImageSize,
        this.cropperLoadedImage,
        this.cropperPosition
    ]).pipe(
        map(([scaled, loadedImage, position]) => {
            if (!scaled || !loadedImage || !position) {
                return this.tmpCropperPosition || {};
            }
            const ratio = scaled.width / loadedImage.original.size.width;
            return {
                x1: position.x1 * ratio,
                y1: position.y1 * ratio,
                x2: position.x2 * ratio,
                y2: position.y2 * ratio
            };
        })
    );
    isVideo = false;
    tmpImageThumb: SafeUrl = '';
    tmpImage: File;
    removeVideoThumb = false;

    tstamp = Date.now();

    constructor(
        public dialogRef: MatDialogRef<AssetEditComponent>,
        private readonly api: ApiService,
        private readonly loading: LoadingOverlayService,
        private readonly sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: AssetEditData
    ) {
        this.form = new FormGroup({
            title: new FormControl('', [Validators.maxLength(55)]),
            description: new FormControl('', [Validators.maxLength(750)]),
            source: new FormControl(''),
            date: new FormControl(null)
        });

        if (data.type === 'single') {
            this.form.get('title').setValue(data.file.title);
            this.form.get('description').setValue(data.file.description);
            this.form.get('date').setValue(new Date(data.file.date));
            this.form.get('source').setValue(data.file.source);
            if (data.file.type === AssetType.IMAGE) {
                this.imageUrl =
                    '/api/asset/' +
                    data.file.uid +
                    '/show?t=' +
                    Date.now() +
                    '&noCrop=1';
                if (data.file.crop) {
                    this.cropperPosition.next({ ...data.file.crop });
                }
            }
            this.isVideo = data.file.type === AssetType.VIDEO;
        }

        if (data.type === 'upload' && data.uploadFiles.length === 1) {
            this.form.get('title').setValue(data.uploadFiles[0].name);
            this.isVideo = data.uploadFiles[0].type.indexOf('video/') === 0;
            if (data.uploadFiles[0].type.indexOf('image/') === 0) {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    this.imageUrl = evt.target.result.toString();
                };
                reader.readAsDataURL(data.uploadFiles[0]);
            }
        }

        this.models = [...data.models];
        this.models.sort((a, b) => a.title.localeCompare(b.title));

        this.modelYears = [...data.modelYears];
        this.modelYears.sort((a, b) => a.title.localeCompare(b.title));

        this.tags = [...data.tags];
        this.tags.sort((a, b) => a.title.localeCompare(b.title));
        this.filterTags();

        if (data.type === 'single') {
            for (const m of data.file.models) {
                this.toggleModel(m);
            }
            for (const my of data.file.modelYears) {
                this.toggleModelYear(my);
            }
            for (const t of data.file.tags) {
                this.toggleTag(t);
            }
        }
    }

    ngOnInit(): void {}

    imageLoaded(imgData: LoadedImage) {
        this.cropperLoadedImage.next(imgData);
    }

    cropperLoaded(dimensions: Dimensions) {
        this.scaledImageSize.next(dimensions);
    }

    imageCropped(evt: ImageCroppedEvent) {
        this.tmpCropperPosition = evt.imagePosition;
    }

    saveCropping() {
        this.showCropper = false;
        this.scaledImageSize.next(null);
        this.cropperLoadedImage.next(null);
        this.cropperPosition.next({ ...this.tmpCropperPosition });
    }

    getCustomVideoThumb(): AssetFileVariant | undefined {
        if (
            this.data.type !== 'single' ||
            this.data.file.type !== AssetType.VIDEO ||
            this.removeVideoThumb ||
            this.tmpImage
        ) {
            return undefined;
        }
        return this.data.file.variants.find((v) => v.type === 'thumbnail');
    }

    onVideoThumbUpload(event: Event) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        if (target.files && target.files.length === 1) {
            this.removeVideoThumb = false;
            this.tmpImage = target.files[0];
            this.tmpImageThumb = this.sanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(target.files[0])
            );
        }
    }

    removeVideoThumbFile(event: Event) {
        event.preventDefault();
        if (this.tmpImageThumb) {
            this.tmpImageThumb = '';
            this.tmpImage = null;
        }
        this.removeVideoThumb = true;
    }

    filterTags() {
        if (!this.tagSearch) {
            this.filteredTags = this.tags;
            return;
        }

        const tags = [];
        for (const tag of this.tags) {
            if (
                tag.title
                    .toLowerCase()
                    .indexOf(this.tagSearch.toLowerCase()) !== -1
            ) {
                tags.push(tag);
            }
        }
        this.filteredTags = tags;
    }

    toggleModel(model: Category) {
        const mIdx = this.models.findIndex((m) => m.uid === model.uid);
        const smIdx = this.selectedModels.findIndex(
            (sm) => sm.uid === model.uid
        );

        if (mIdx !== -1) {
            this.selectedModels.push(model);
            this.models.splice(mIdx, 1);
        } else if (smIdx !== -1) {
            this.models.push(model);
            this.models.sort((a, b) => a.title.localeCompare(b.title));
            this.selectedModels.splice(smIdx, 1);
        }
    }

    toggleModelYear(modelYear: Category) {
        const mIdx = this.modelYears.findIndex((m) => m.uid === modelYear.uid);
        const smIdx = this.selectedModelYears.findIndex(
            (sm) => sm.uid === modelYear.uid
        );

        if (mIdx !== -1) {
            this.selectedModelYears.push(modelYear);
            this.modelYears.splice(mIdx, 1);
        } else if (smIdx !== -1) {
            this.modelYears.push(modelYear);
            this.modelYears.sort((a, b) => a.title.localeCompare(b.title));
            this.selectedModelYears.splice(smIdx, 1);
        }
    }

    toggleTag(tag: Category) {
        const tIdx = this.tags.findIndex((t) => t.uid === tag.uid);
        const stIdx = this.selectedTags.findIndex((st) => st.uid === tag.uid);

        if (tIdx !== -1) {
            this.selectedTags.push(tag);
            this.tags.splice(tIdx, 1);
        } else if (stIdx !== -1) {
            this.tags.push(tag);
            this.tags.sort((a, b) => a.title.localeCompare(b.title));
            this.selectedTags.splice(stIdx, 1);
        }
        this.filterTags();
    }

    async save() {
        if (this.form.invalid) {
            return;
        }

        let overlayText = 'Speichere Daten';
        if (this.data.type === 'upload') {
            overlayText = 'Lade Daten hoch';
        }

        const overlayId = this.loading.openOverlay(overlayText);

        const date = Math.round(
            this.form.get('date').value
                ? this.form.get('date').value.getTime() / 1000
                : 0
        );

        if (
            (this.data.type === 'multiple' && this.data.files.length > 0) ||
            this.data.type === 'single'
        ) {
            const update: UpdateFiles = {
                uids:
                    this.data.type === 'single'
                        ? [this.data.file.uid]
                        : this.data.files.map((f) => f.uid),
                title: this.form.get('title').value,
                description: this.form.get('description').value,
                source: this.form.get('source').value,
                date,
                models: this.selectedModels.map((c) => c.uid),
                modelYears: this.selectedModelYears.map((c) => c.uid),
                tags: this.selectedTags.map((c) => c.uid),
                removeVideoThumb: this.removeVideoThumb,
                crop: this.cropperPosition.getValue()
            };

            if (!(await this.api.backendUpdateFiles(update, this.tmpImage))) {
                alert('failed to save');
                this.loading.closeOverlay(overlayId);
                return;
            }
        } else if (this.data.type === 'upload') {
            const data: UploadFiles = {
                title: this.form.get('title').value,
                description: this.form.get('description').value,
                source: this.form.get('source').value,
                date,
                models: this.selectedModels.map((c) => c.uid),
                tags: this.selectedTags.map((c) => c.uid),
                modelYears: this.selectedModelYears.map((c) => c.uid),
                crop: this.cropperPosition.getValue()
            };
            try {
                if (
                    !(await this.api.backendUploadFiles(
                        this.data.uploadFiles,
                        data,
                        this.tmpImage
                    ))
                ) {
                    alert('failed to upload');
                    this.loading.closeOverlay(overlayId);
                    return;
                }
            } catch (e) {
                alert('konnte nicht hochgeladen werden. Dateien zu groß?');
                this.loading.closeOverlay(overlayId);
                return;
            }
        }

        this.dialogRef.close(true);
        this.loading.closeOverlay(overlayId);
    }
}
