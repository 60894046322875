<h1 class="shareOverlay__headline">
    Link teilen
</h1>
<button
    class="shareOverlay__close"
    (click)="dialogRef.close()"
    [inlineSVG]="
        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/close.svg'
    "
></button>
<div class="content">
    <p>
        Einfach unten stehenden Link in die Zwischenablage kopieren und versenden.<br>
        Der Link ist so lange gültig, wie alle Assets verfügbar sind.
    </p>
</div>
<div class="shareOverlay__linkWrapper">
    <div class="shareOverlay__linkPreview">
        <span class="shareOverlay__linkIcon"
              [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/link.svg'"></span> {{data.shareLink}}
    </div>
    <px-a00050-secondary-button
        (clicked)="data.copyToClipBoard"
        text="Kopieren"
        [iconPath]="'none'"
    ></px-a00050-secondary-button>
</div>
