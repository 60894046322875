<div class="a00180">
    <textarea
        name="{{ inputName }}"
        id="{{ inputName }}"
        rows="8"
        placeholder=" "
    ></textarea>
    <label for="{{ inputName }}">
        {{ placeholder }}
        <span *ngIf="required">*</span>
    </label>
</div>
<div class="a00180__error" *ngIf="error">{{ error }}</div>
