import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00070TertiaryButtonSmallModule } from '../../atoms/a00070-tertiary-button-small/a00070-tertiary-button-small.module';
import { A00260TextLineIconDividerModule } from '../../atoms/a00260-text-line-icon-divider/a00260-text-line-icon-divider.module';
import { M0070NewsteaserTileModule } from '../../molecules/m0070-newsteaser-tile/m0070-newsteaser-tile.module';
import { M0080NewsteaserListModule } from '../../molecules/m0080-newsteaser-list/m0080-newsteaser-list.module';
import { M0090NewsteaserTileBigModule } from '../../molecules/m0090-newsteaser-tile-big/m0090-newsteaser-tile-big.module';
import { O060TileListViewComponent } from './o060-tile-list-view.component';

@NgModule({
    declarations: [O060TileListViewComponent],
    imports: [
        CommonModule,
        M0070NewsteaserTileModule,
        M0090NewsteaserTileBigModule,
        A00260TextLineIconDividerModule,
        A00070TertiaryButtonSmallModule,
        M0080NewsteaserListModule
    ],
    exports: [O060TileListViewComponent]
})
export class O060TileListViewModule {}
