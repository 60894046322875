import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactBigComponent } from './contact-big.component';
import { O110ContactBigModule } from '../../organisms/o110-contact-big/o110-contact-big.module';

@NgModule({
    declarations: [ContactBigComponent],
    exports: [
        ContactBigComponent
    ],
    imports: [CommonModule, O110ContactBigModule]
})
export class ContactBigModule {}
