<div class="list-item" fxLayout="row" fxLayoutAlign="space-between">
    <div class="col-md-5">
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div class="col-md-1">
                <mat-checkbox
                    [(ngModel)]="selected"
                    (ngModelChange)="selectedChange.emit($event)"
                ></mat-checkbox>
            </div>
            <div
                class="col-md-4"
                *ngIf="
                    file.type !== AssetType.OTHER ||
                    file.fileExtension === 'pdf'
                "
            >
                <img
                    class="list-item__thumb thumb"
                    [src]="
                        '/api/asset/' + file.uid + '/show?height=80&=' + tstamp
                    "
                    [alt]="file.title"
                />
            </div>
            <div class="col-md-7">
                <div>
                    <strong
                        class="list-item__description"
                        [title]="file.description"
                    >
                        {{ file.title | shorten: 60:'...' }}
                    </strong>
                </div>
                <span
                    class="list-item__subDescription"
                    [title]="file.description"
                >
                    {{ file.description | shorten: 30:'...' }}
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <div *ngIf="file.models && file.models.length > 0">
            <mat-chip-list>
                <mat-chip class="modelChip" *ngFor="let model of file.models">
                    {{ model.title }}
                </mat-chip>
            </mat-chip-list>
        </div>
        <div *ngIf="file.modelYears && file.modelYears.length > 0">
            <mat-chip-list>
                <mat-chip class="modelYearChip" *ngFor="let modelYear of file.modelYears">
                    {{ modelYear.title }}
                </mat-chip>
            </mat-chip-list>
        </div>
        <div *ngIf="file.tags && file.tags.length > 0">
            <mat-chip-list>
                <mat-chip class="tagChip" *ngFor="let tag of file.tags">
                    {{ tag.title }}
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div class="col-md-1">
        <span class="fileUploadText">{{ file.size | bytes: 2 }}</span>
    </div>
    <div class="col-md-1">
        <span class="fileUploadText">{{ file.date | date: 'dd.MM.yyyy' }}</span>
    </div>
</div>
