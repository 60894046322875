import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-m0190-content-teaser',
    templateUrl: './m0190-content-teaser.component.html',
    styleUrls: ['./m0190-content-teaser.component.scss']
})
export class M0190ContentTeaserComponent implements OnInit {
    @Input() image: string;
    @Input() responsiveImages: {
        image?: string;
        imageTablet?: string;
        imageMobile?: string;
    };
    @Input() headline: string;
    @Input() headlineType: string;
    @Input() text: string;
    @Input() ctaText: string;
    @Input() ctaLink: string;
    @Input() ctaSuperscript: string;
    @Input() textColor: string;
    @Input() bgColor: string;
    @Input() modifier: string;
    @Input() position: string;

    constructor() {}

    ngOnInit(): void {}
}
