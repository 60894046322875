<div class="o060 container">
    <px-m0070-newsteaser-tile
        [tile]="newsteaserTiles[0]"
    ></px-m0070-newsteaser-tile>

    <px-a00260-text-line-icon-divider
        *ngIf="newsteaserTiles.length > 1 && !isBlog"
        (toggleView)="toggleA00260TextLineIconDividerType()"
        [modifier]="a00260TextLineIconDividerType"
        [text]="'Weitere News'"
    ></px-a00260-text-line-icon-divider>

    <px-a00260-text-line-icon-divider
        *ngIf="newsteaserTiles.length > 1 && isBlog"
        (toggleView)="toggleA00260TextLineIconDividerType()"
        [modifier]="a00260TextLineIconDividerType"
        [text]="'Weitere Blogbeiträge'"
    ></px-a00260-text-line-icon-divider>

    <div class="row o060__row" *ngIf="newsteaserTiles.length > 1">
        <ng-container *ngIf="a00260TextLineIconDividerType === 'a'">
            <div
                class="col-12 col-lg-4"
                *ngFor="let newsItem of newsteaserTiles | slice: 1"
            >
                <px-m0090-newsteaser-tile-big
                    [tile]="newsItem"
                ></px-m0090-newsteaser-tile-big>
            </div>
        </ng-container>

        <ng-container *ngIf="a00260TextLineIconDividerType === 'b'">
            <div
                class="col-12"
                *ngFor="let newsItem of newsteaserTiles | slice: 1"
            >
                <px-m0080-newsteaser-list
                    [tile]="newsItem"
                ></px-m0080-newsteaser-list>
            </div>
        </ng-container>
    </div>

    <div class="row" *ngIf="hasMore">
        <div class="col-12">
            <div class="o060__more-button-wrapper">
                <px-a00070-tertiary-button-small
                    [modifier]="a00070TertiaryButtonSmallType"
                    [text]="a00070TertiaryButtonSmallText"
                    (clickA00070TertiaryButtonSmall)="loadMore.emit()"
                ></px-a00070-tertiary-button-small>
            </div>
        </div>
    </div>
</div>
