import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0080NewsteaserListComponent } from './m0080-newsteaser-list.component';
import { A00020AttributeModule } from 'src/app/components/atoms/a00020-attribute/a00020-attribute.module';
import { A00110ForwardBackLinkModule } from 'src/app/components/atoms/a00110-forward-back-link/a00110-forward-back-link.module';
import { NgMathPipesModule, NgStringPipesModule } from 'ngx-pipes';
import {InlineSVGModule} from "ng-inline-svg";
import {A00300TooltipModule} from "../../atoms/a00300-tooltip/a00300-tooltip.module";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [M0080NewsteaserListComponent],
    imports: [
        CommonModule,
        A00110ForwardBackLinkModule,
        A00020AttributeModule,
        NgMathPipesModule,
        NgStringPipesModule,
        InlineSVGModule,
        A00300TooltipModule,
        MatTooltipModule
    ],
    exports: [M0080NewsteaserListComponent]
})
export class M0080NewsteaserListModule {}
