import { AssetFile } from 'src/app/interfaces/AssetFile';
import { M0110MediaTile } from '../molecules/m0110-media-tile';

export enum O090MaterialTabTitle {
    image = 'Bilder',
    video = 'Videos',
    other = 'Dokumente'
}

export interface O090Material {
    title: O090MaterialTabTitle;
    active: boolean;
    assets: AssetFile[];
    tiles?: M0110MediaTile[];
}
