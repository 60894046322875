<div class="m0180 row">
    <div class="col-lg-8">
        <img class="m0180__image" [src]="image" [alt]="'podcast image'" />
    </div>
    <div class="col-lg-4 m0180__buttonCol">
        <ng-container *ngFor="let link of links">
            <px-a00320-icon-button class="m0180__button" [button]="link"></px-a00320-icon-button>
        </ng-container>
    </div>
</div>
