import { Component, Input, OnInit } from '@angular/core';
import {
    M0100PressfolderLink,
    M0100PressfolderType
} from '../../../interfaces/molecules/m0100-pressfolder';

@Component({
    selector: 'px-m0100-pressfolder',
    templateUrl: './m0100-pressfolder.component.html',
    styleUrls: ['./m0100-pressfolder.component.scss']
})
export class M0100PressfolderComponent implements OnInit {
    @Input()
    modifier: M0100PressfolderType = M0100PressfolderType.A;

    @Input()
    image = '';

    @Input()
    links: M0100PressfolderLink[] = [];

    @Input()
    imageLabel = '';

    constructor() {}

    ngOnInit(): void {}
}
