import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0100PressfolderComponent } from './m0100-pressfolder.component';
import { A00060TertiaryButtonFullModule } from '../../atoms/a00060-tertiary-button-full/a00060-tertiary-button-full.module';

@NgModule({
    declarations: [M0100PressfolderComponent],
    exports: [M0100PressfolderComponent],
    imports: [CommonModule, A00060TertiaryButtonFullModule]
})
export class M0100PressfolderModule {}
