import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { O010MainNavType } from '../../../interfaces/organisms/o010-main-nav';
import { MenuEntry } from '../../../interfaces/MenuEntry';
import { DownloadCenterService } from "../../../services/download-center.service";

@Component({
    selector: 'px-o010-main-nav',
    templateUrl: './o010-main-nav.component.html',
    styleUrls: ['./o010-main-nav.component.scss']
})
export class O010MainNavComponent implements OnInit, OnChanges {
    @Input() modifier: O010MainNavType = O010MainNavType.A;
    @Input() menu: MenuEntry[] = [];
    @Input() metaMenu: MenuEntry[] = [];
    @Input() downloadCenter = '';
    @Input() mobileMenu: MenuEntry[] = [];
    @Input() specialMobileMenu: MenuEntry[] = [];

    openMobileNav = false;

    constructor(public downloadCenterService: DownloadCenterService) {}

    async ngOnInit(): Promise<void> {
        await this.downloadCenterService.cleanupDownLoadJson();
    }

    toggleNav() {
        this.openMobileNav = !this.openMobileNav;
    }

    ngOnChanges(): void {
        this.mobileMenu = [
            ...this.menu.filter((m) => !m.mobileSpecial),
            ...this.metaMenu.filter((m) => !m.mobileSpecial)
        ];

        this.specialMobileMenu = [
            ...this.menu.filter((m) => m.mobileSpecial),
            ...this.metaMenu.filter((m) => m.mobileSpecial)
        ];
    }
}
