<div class="t10">
    <div class="t10__filter">
        <px-o030-filter
            [filters]="filterGroups"
            (selectedFilters)="selectedFilters.emit($event)"
        ></px-o030-filter>
    </div>
    <px-o060-tile-list-view
        (loadMore)="loadMore.emit()"
        [hasMore]="hasMore"
        [newsteaserTiles]="newsteaserTiles"
        [isBlog]="isBlog"
    ></px-o060-tile-list-view>
    <px-o100-contact-footer
        *ngIf="contactFooter"
        [teaserHeadline]="contactFooter.teaserHeadline"
        [teaserDescription]="contactFooter.teaserDescription"
        [teaserLink]="contactFooter.teaserLink"
        [teaserLinkName]="contactFooter.teaserLinkName"
        [employees]="contactFooter.employees"
    ></px-o100-contact-footer>
</div>
