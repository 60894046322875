import { Component, Input } from '@angular/core';

export interface AccordionTeaser {
    image?: string;
    imageTablet?: string;
    imageMobile?: string;
    headline?: string;
    text?: string;
    active?: boolean;
}
@Component({
    selector: 'px-accordion-teaser',
    templateUrl: './accordion-teaser.component.html',
    styleUrls: ['./accordion-teaser.component.scss']
})
export class AccordionTeaserComponent {
    content: AccordionTeaser[];

    @Input() set data(data: string) {
        const tmpContent = JSON.parse(data);

        const tmpArray = [];
        for (const key in tmpContent) {
            const element = tmpContent[key];
            tmpArray.push(element);
        }
        this.content = tmpArray;
    }
}
