import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00260TextLineIconDividerComponent } from './a00260-text-line-icon-divider.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [A00260TextLineIconDividerComponent],
    imports: [CommonModule, InlineSVGModule.forRoot()],
    exports: [A00260TextLineIconDividerComponent]
})
export class A00260TextLineIconDividerModule {}
