import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyvisualComponent } from './keyvisual.component';
import { M0010KeyvisualModule } from '../../molecules/m0010-keyvisual/m0010-keyvisual.module';

@NgModule({
    declarations: [KeyvisualComponent],
    imports: [CommonModule, M0010KeyvisualModule]
})
export class KeyvisualModule {}
