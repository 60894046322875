import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O100ContactFooterComponent } from './o100-contact-footer.component';
import { A00250TextAndLineDividerModule } from '../../atoms/a00250-text-and-line-divider/a00250-text-and-line-divider.module';
import { M0140ContactCardSmallModule } from '../../molecules/m0140-contact-card-small/m0140-contact-card-small.module';
import { A00040PrimaryButtonIconModule } from '../../atoms/a00040-primary-button-icon/a00040-primary-button-icon.module';

@NgModule({
    declarations: [O100ContactFooterComponent],
    imports: [
        CommonModule,
        A00250TextAndLineDividerModule,
        M0140ContactCardSmallModule,
        A00040PrimaryButtonIconModule
    ],
    exports: [O100ContactFooterComponent]
})
export class O100ContactFooterModule {}
