import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00180InputTextComponent } from './a00180-input-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [A00180InputTextComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [A00180InputTextComponent]
})
export class A00180InputTextModule {}
