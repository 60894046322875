<div
    class="a00100__close"
    [inlineSVG]="
        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/close.svg'
    "
    (click)="open = false"
    *ngIf="open"
></div>
<div class="a00100">
    <div class="name">
        {{ title | shorten: 48:'...':false }}
    </div>
    <div class="links">
        <div class="links-download">
            <a matTooltip="Herunterladen"
               matTooltipPosition="above"
               [matTooltipClass]="['tooltip-a00300']"
               matTooltipTouchGestures="off"
               matTooltipHideDelay="1"

               class="link" (click)="openCtas()">
                <div
                    class="icon"
                    [inlineSVG]="
                        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/download/black.svg'
                    "
                ></div>
                <!--            <div class="text">Herunterladen</div>-->
            </a>
            <div class="link link-download">
                <a [matTooltip]="inDownloadCenter ? 'Vom Downloadcenter entfernen' : 'Zum Downloadcenter hinzufügen'"
                   matTooltipPosition="above"
                   [matTooltipClass]="['tooltip-a00300']"
                   matTooltipTouchGestures="off"
                   matTooltipHideDelay="1"
                   (click)="toggleDownloadCenter()">
                    <div
                        class="icon icon--bookmark"
                        [inlineSVG]="
                    inDownloadCenter ?
                     '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_black.svg' : '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_border_black.svg'
                    "
                    ></div>
                </a>
                <!--            <div class="text">save</div>-->
            </div>
        </div>
        <div class="link link-detail">
            <a [matTooltip]="'Detailansicht'"
               matTooltipPosition="above"
               [matTooltipClass]="['tooltip-a00300', 'tooltip-a00300__big']"
               matTooltipTouchGestures="off"
               matTooltipHideDelay="1"
               (click)="openFileDetail()"
               class="icon"
               [inlineSVG]="
            '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/details.svg'
            ">
            </a>
            <!--            <div class="text">Details</div>-->
        </div>
    </div>
    <div [class.open]="open" class="ctasWrapper" >
        <div class="ctas">
            <a
                *ngFor="let download of downloads"
                [href]="download.link"
                class="ctas__link"
                (click)="download.click = true"
                (mousedown)="download.active = true"
                (mouseup)="download.active = false"
                (mouseleave)="download.active = false"
                [class.clicked]="download.active"
            >
                <div class="ctas__text">{{ download.label }}</div>
                <div class="ctas__size">{{ download.size | bytes: 2 }}</div>
                <div
                    *ngIf="!download.click"
                    class="ctas__icon"
                    [inlineSVG]="
                        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/download/black.svg'
                    "
                ></div>
                <div
                    class="ctas__icon active"
                    *ngIf="download.click"
                    [inlineSVG]="
                        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/hook/red.svg'
                    "
                ></div>
            </a>
        </div>
    </div>
</div>
