import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'px-a00010-header-logo',
    templateUrl: './a00010-header-logo.component.html',
    styleUrls: ['./a00010-header-logo.component.scss']
})
export class A00010HeaderLogoComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
