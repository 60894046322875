import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'px-a00260-text-line-icon-divider',
    templateUrl: './a00260-text-line-icon-divider.component.html',
    styleUrls: ['./a00260-text-line-icon-divider.component.scss']
})
export class A00260TextLineIconDividerComponent implements OnInit {
    @Input() modifier: string;
    @Input() text: string;

    @Output() toggleView: EventEmitter<void> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    onClick() {
        this.toggleView.emit();
    }
}
