export enum M0100PressfolderType {
    A = 'a'
}

export interface M0100PressfolderLink {
    title: string;
    link: string;
}

export interface M0100Pressfolder {
    type: M0100PressfolderType;
    image: string;
    links: M0100PressfolderLink[];
    imageLabel: string;
}
