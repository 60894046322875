import { Injectable, Injector } from '@angular/core';
import {
    LOADING_OVERLAY_DIALOG_DATA,
    LoadingOverlayComponent
} from '../components/helpers/loading-overlay/loading-overlay.component';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

@Injectable({
    providedIn: 'root'
})
export class LoadingOverlayService {
    protected static id = 0;

    protected overlayRef: OverlayRef;

    overlays: Array<{ id: number; text: string }> = [];

    constructor(
        private readonly overlay: Overlay,
        private readonly injector: Injector
    ) {}

    openOverlay(text: string = ''): number {
        const id = ++LoadingOverlayService.id;

        this.overlays.push({
            id,
            text
        });

        if (!this.overlayRef) {
            this.createOverlay();

            const injector = this.createInjector(text);

            const loadingOverlay = new ComponentPortal(
                LoadingOverlayComponent,
                null,
                injector
            );
            this.overlayRef.attach(loadingOverlay);
        }

        return id;
    }

    protected createOverlay() {
        if (!this.overlayRef) {
            const positionStrategy = this.overlay
                .position()
                .global()
                .centerHorizontally()
                .centerVertically();

            const overlayConfig = new OverlayConfig({
                hasBackdrop: true,
                positionStrategy,
                scrollStrategy: this.overlay.scrollStrategies.block()
            });

            this.overlayRef = this.overlay.create(overlayConfig);
        }
    }

    protected createInjector(text: string) {
        const injectionTokens = new WeakMap();

        injectionTokens.set(LOADING_OVERLAY_DIALOG_DATA, text);

        return new PortalInjector(this.injector, injectionTokens);
    }

    closeOverlay(id: number) {
        const idx = this.overlays.findIndex((o) => o.id === id);

        if (this.overlays.length === 1) {
            this.overlays = [];
            this.overlayRef?.dispose();
            this.overlayRef = null;
        } else {
            this.overlays.splice(idx, 1);
            this.overlayRef.detach();

            const injector = this.createInjector(this.overlays[0].text);

            const loadingOverlay = new ComponentPortal(
                LoadingOverlayComponent,
                null,
                injector
            );
            this.overlayRef.attach(loadingOverlay);
        }
    }
}
