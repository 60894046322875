import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-a00130-underline-link',
    templateUrl: './a00130-underline-link.component.html',
    styleUrls: ['./a00130-underline-link.component.scss']
})
export class A00130UnderlineLinkComponent implements OnInit {
    @Input() href: string;
    @Input() target: string;
    @Input() text: string;
    @Input() active: boolean;

    constructor() {}

    ngOnInit(): void {}
}
