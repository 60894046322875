import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface GalOverlayImage {
    image?: string;
    imageTablet?: string;
    imageMobile?: string;
}

@Component({
    selector: 'px-gallery-overlay',
    templateUrl: './gallery-overlay.component.html',
    styleUrls: ['./gallery-overlay.component.scss']
})
export class GalleryOverlayComponent implements AfterViewInit {
    @ViewChild('slider') slider: ElementRef;

    images: GalOverlayImage[];
    sliderInit = false;
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
    animating = false;
    transitionOn = false;
    currentIndex = 1;
    endIndex = 1;
    transformValue = 0;
    sliderWidth = 0;
    trackStyle = {
        transform: 'translateX(0)'
    };

    @HostListener('window:resize', ['$event'])
    onScroll() {
        if (this.sliderInit) {
            this.setWidth();
            setTimeout(() => {
                this.changeTrackStyle(false);
            }, 0);
        }
    }

    constructor(
        private dialogRef: MatDialogRef<GalleryOverlayComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: { images: GalOverlayImage[]; clickedIndex: number },
        private cdr: ChangeDetectorRef
    ) {
        if (this.data.images) {
            this.images = this.data.images;
            this.endIndex = this.data.images.length;
        }
        if (this.data.clickedIndex) {
            this.currentIndex = this.data.clickedIndex + 1;
        }
    }

    close() {
        this.dialogRef.close(true);
    }

    ngAfterViewInit(): void {
        this.setWidth();
        this.changeTrackStyle(false);
        this.sliderInit = true;
        this.cdr.detectChanges();
    }

    setWidth() {
        this.sliderWidth = this.slider.nativeElement.offsetWidth;
    }

    changeTrackStyle(transition: boolean): void {
        if (transition) {
            this.transitionOn = true;
            this.animating = true;
        } else {
            this.transitionOn = false;
        }
        this.transformValue = this.currentIndex * this.sliderWidth;
        this.trackStyle.transform =
            'translateX(' + -this.transformValue + 'px)';
        if (transition) {
            setTimeout(() => {
                this.animating = false;
            }, 500);
        }
    }

    prevSlide() {
        if (!this.animating) {
            setTimeout(() => {
                if (this.currentIndex === 0) {
                    this.currentIndex = this.endIndex;
                    this.changeTrackStyle(false);
                }
            }, 0);

            setTimeout(() => {
                this.currentIndex = this.currentIndex - 1;
                this.changeTrackStyle(true);
            }, 100);
        }
    }

    nextSlide() {
        if (!this.animating) {
            setTimeout(() => {
                if (this.currentIndex === this.endIndex + 1) {
                    this.currentIndex = 1;
                    this.changeTrackStyle(false);
                }
            }, 0);
            setTimeout(() => {
                this.currentIndex = this.currentIndex + 1;
                this.changeTrackStyle(true);
            }, 100);
        }
    }
}
