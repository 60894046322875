import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00010HeaderLogoComponent } from './a00010-header-logo.component';

@NgModule({
    declarations: [A00010HeaderLogoComponent],
    imports: [CommonModule],
    exports: [A00010HeaderLogoComponent]
})
export class A00010HeaderLogoModule {}
