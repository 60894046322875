import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00140TextLinkComponent } from './a00140-text-link.component';

@NgModule({
    declarations: [A00140TextLinkComponent],
    imports: [CommonModule],
    exports: [A00140TextLinkComponent]
})
export class A00140TextLinkModule {}
