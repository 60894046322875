<div class="m0060">
    <div
        #scrollWrapper
        id="m0060__scrollWrapper"
        class="wrapper"
        (scroll)="m0060Scroll()"
        [class.closed]="isCollapsed"
        [class.sticky]="sticky"
    >
        <button
            [class.sticky]="sticky"
            [class.closed]="isCollapsed"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            [attr.aria-controls]="'filter'"
            class="m0060__button"
        >
            <span class="text">Filter</span>
            <span class="textDesktop">Filter</span>
            <span
                class="arrow"
                [inlineSVG]="
                    '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/black.svg'
                "
            ></span>
        </button>
        <div class="tagList" [class.sticky]="sticky">
            <px-a00020-attribute
                *ngFor="let tag of selectedTags"
                modifier="b"
                [text]="tag.tag.label"
                (delete)="
                    removeSelectedTag.emit({
                        filterId: tag.filterId,
                        tagId: tag.tag.id
                    })
                "
            ></px-a00020-attribute>
        </div>
    </div>
    <div class="collapse" id="filter" [ngbCollapse]="isCollapsed">
        <ng-content></ng-content>
    </div>
</div>
