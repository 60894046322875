<div class="o90">
    <div class="o90__tabs">
        <div class="m0020 m0020--c">
            <div class="m0020__links">
                <div class="a00130" *ngFor="let tab of tabs">
                    <a
                        class="a00130__link"
                        [class.a00130__link--active]="tab.active"
                        (click)="setActive(tab.title)"
                    >
                        {{ tab.title }}
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="o90__slider">
        <button
            (click)="moveLeft()"
            [ngStyle]="btnPrevStyle"
            class="o90__slider-control o90__slider-control--prev"
        >
            <span
                [inlineSVG]="
                    '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/black.svg'
                "
            ></span>
        </button>

        <div
            class="o90__slider-inner-wrapper"
            (swipeleft)="moveRight()"
            (swiperight)="moveLeft()"
        >
            <div class="o90__slider-inner" [ngStyle]="sliderStyles">
                <ng-container *ngFor="let tab of tabs">
                    <ng-container *ngIf="tab.active">
                        <px-m0110-media-tile
                            *ngFor="let tile of tab.tiles"
                            [modifier]="tile.type"
                            [preview]="tile.preview"
                            [download]="tile.download"
                            #slide
                        ></px-m0110-media-tile>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <button
            (click)="moveRight()"
            [ngStyle]="btnNextStyle"
            class="o90__slider-control o90__slider-control--next"
        >
            <span
                [inlineSVG]="
                    '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/black.svg'
                "
            ></span>
        </button>
    </div>

    <div class="row">
        <div class="col-12 text-center">
            <px-a00050-secondary-button
                *ngIf="downloadLink"
                class="o90__download"
                [link]="downloadLink"
                text="Alles herunterladen"
                [iconPath]="
                    '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/download/white.svg'
                "
            ></px-a00050-secondary-button>
        </div>
    </div>
</div>
