import { A00220MediaCard } from '../atoms/a00220-media-card';
import { A00100DownloadButton } from '../atoms/a00100-download-button';

export enum M0110MediaTileType {
    A = 'a'
}

export interface M0110MediaTile {
    type: M0110MediaTileType;
    id: string | number;
    preview: A00220MediaCard;
    download: A00100DownloadButton;
}
