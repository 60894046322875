import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    transform<T extends object>(
        arr: T[],
        prop: keyof T,
        order: 'asc' | 'desc' = 'asc'
    ): unknown {
        const ord = order === 'asc' ? 1 : -1;
        arr.sort((a, b) =>
            a.hasOwnProperty(prop) &&
            b.hasOwnProperty(prop) &&
            a[prop] < b[prop]
                ? 1 * ord
                : -1 * ord
        );
        return arr;
    }
}
