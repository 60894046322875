import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfUseOverlayComponent } from './terms-of-use-overlay.component';
import { A00040PrimaryButtonIconModule } from '../../atoms/a00040-primary-button-icon/a00040-primary-button-icon.module';
import { A00210InputCheckboxModule } from '../../atoms/a00210-input-checkbox/a00210-input-checkbox.module';
import { MatDialogModule } from '@angular/material/dialog';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [TermsOfUseOverlayComponent],
    imports: [
        CommonModule,
        A00040PrimaryButtonIconModule,
        A00210InputCheckboxModule,
        MatDialogModule,
        InlineSVGModule.forRoot()
    ],
    exports: [TermsOfUseOverlayComponent]
})
export class TermsOfUseOverlayModule {}
