import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetFile } from '../../../../interfaces/AssetFile';

@Component({
    selector: 'px-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
    @Input()
    selectedFiles: number[] = [];

    @Input()
    files: AssetFile[] = [];

    @Output()
    selectedFilesChange: EventEmitter<number[]> = new EventEmitter<number[]>();

    constructor() {}

    ngOnInit(): void {}

    onSelect(uid: number, selected: boolean) {
        const idx = this.selectedFiles.indexOf(uid);
        if (idx !== -1) {
            this.selectedFiles.splice(idx, 1);
        }
        if (selected) {
            this.selectedFiles.push(uid);
        }
        this.selectedFilesChange.emit(this.selectedFiles);
    }
}
