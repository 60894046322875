<div class="m-0190 m-0190--{{modifier}}"
     [ngClass]="{'m-0190--right': position === 'right'}">
    <div class="m-0190__image">
        <img class="img-fluid mobile-only"
            alt="{{headline}}"
            title="{{headline}}"
            src="{{responsiveImages.imageMobile}}"
            *ngIf="responsiveImages.imageMobile">

        <img class="img-fluid "
            alt="{{headline}}"
            title="{{headline}}"
            src="{{responsiveImages.imageTablet}}"
            *ngIf="responsiveImages.imageTablet"
            [ngClass]="{'tablet': responsiveImages.imageMobile, 'mobile': responsiveImages.imageMobile}">

        <img class="img-fluid"
            alt="{{headline}}"
            title="{{headline}}"
            src="{{responsiveImages.image}}"
            [ngClass]="{'desktop': responsiveImages.imageTablet , 'from-tablet': !responsiveImages.imageTablet && responsiveImages.imageMobile}">
    </div>
    <div class="m-0190__body">
        <ng-container *ngIf="headlineType === 'h1'">
            <h1 class="headline js-m0020-headline" [ngClass]="{'headline--white': textColor === 'white'}" [innerHTML]="headline"></h1>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h2' || !headlineType">
            <h2 class="headline js-m0020-headline" [ngClass]="{'headline--white': textColor === 'white'}" [innerHTML]="headline"></h2>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h3'">
            <h3 class="headline js-m0020-headline" [ngClass]="{'headline--white': textColor === 'white'}" [innerHTML]="headline"></h3>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h4'">
            <h4 class="headline js-m0020-headline" [ngClass]="{'headline--white': textColor === 'white'}" [innerHTML]="headline"></h4>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h5'">
            <h5 class="headline js-m0020-headline" [ngClass]="{'headline--white': textColor === 'white'}" [innerHTML]="headline"></h5>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h6'">
            <h6 class="headline js-m0020-headline" [ngClass]="{'headline--white': textColor === 'white'}" [innerHTML]="headline"></h6>
        </ng-container>

        <div class="copy" [ngClass]="{'copy--white': textColor === 'white'}" [innerHTML]="text"></div>

        <ng-container *ngIf="modifier === 'a'">
            <div class="ctaBtn">
                <!-- <f:render partial="Atoms/a-00200-primary-button"
                            arguments="{modifier: 'b', data: {text: data.ctaText, link: data.ctaLink, typolink: 'true', noTargetKeyvisual: data.noTargetKeyvisual}}"/> -->
                <ng-container *ngIf="ctaLink">
                    <a class="a-00200 a-00200--b" [href]="ctaLink">
                        <div class="easing"></div>
                        <span>{{ctaText}}</span>
                        <div class="icon" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/pfeil_gross_schwarz.svg'">
                        </div>
                    </a>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="modifier === 'c' || modifier === 'd'">
                <div class="ctaLink" [ngClass]="{'ctaLink--white': textColor === 'white'}" >
                    <!-- <f:render partial="Atoms/a-00210-secondary-button"
                                arguments="{modifier: 'c', data: {text: data.ctaText, link: data.ctaLink}}"/> -->
                </div>
        </ng-container>
    </div>
</div>
