import { Component, OnInit, Input } from '@angular/core';
import { M0020LinkLists } from '../../../interfaces/molecules/m0020-link-list';

@Component({
    selector: 'px-m0020-link-lists',
    templateUrl: './m0020-link-lists.component.html',
    styleUrls: ['./m0020-link-lists.component.scss']
})
export class M0020LinkListsComponent implements OnInit {
    @Input() data: M0020LinkLists;

    constructor() {}

    ngOnInit(): void {
        // console.log('M0020LinkLists', this.data);
    }
}
