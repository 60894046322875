<button
    class="newsDownloadOverlay__close"
    (click)="dialogRef.close()"
    [inlineSVG]="
        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/close.svg'
    "
></button>
<h1 class="newsDownloadOverlay__headline">Dokument herunterladen </h1>
<p class="newsDownloadOverlay__copy">Wählen Sie den Dokumenttyp zum Herunterladen aus.</p>

<div class="newsDownloadOverlay__btns">
    <px-a00050-secondary-button [text]="'Docx'" [link]="'/api/news/' + data.news.uid + '/word'" [iconPath]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/Document DOCX.svg'" [target]="'_blank'"></px-a00050-secondary-button>
    <px-a00050-secondary-button [text]="'TXT'" [link]="'/api/news/' + data.news.uid + '/txt'" [iconPath]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/Document TXT.svg'" [target]="'_blank'"></px-a00050-secondary-button>
    <px-a00050-secondary-button [text]="'PDF'" [link]="'/api/news/' + data.news.uid + '/pdf'" [iconPath]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/Document PDF.svg'" [target]="'_blank'"></px-a00050-secondary-button>
</div>
