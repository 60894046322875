import {
    Component,
    OnInit,
    Input,
    AfterViewInit,
    ViewChild,
    ElementRef,
    HostListener,
    Output,
    EventEmitter
} from '@angular/core';
import { O020SubnavigationTextLink } from '../../../interfaces/organisms/o020-subnavigation-text-link';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { NewsDownloadOverlayComponent } from '../../helpers/news-download-overlay/news-download-overlay.component';
import {News} from "../../../interfaces/News";
import { DownloadCenterService } from '../../../services/download-center.service';

// tslint:disable-next-line:no-any
declare let Shariff: any;

@Component({
    selector: 'px-o020-subnavigation-text-link',
    templateUrl: './o020-subnavigation-text-link.component.html',
    styleUrls: ['./o020-subnavigation-text-link.component.scss']
})
export class O020SubnavigationTextLinkComponent
    implements OnInit, AfterViewInit
{
    @Input() data: O020SubnavigationTextLink;
    @Input() news: News;
    @Input() notSticky = true;

    @Output()
    isOpen: EventEmitter<{
        collapse: ElementRef;
        collapseButton: ElementRef;
        open: boolean;
    }> = new EventEmitter();

    @ViewChild('shareButtons', { static: false }) shareButtons: ElementRef;
    @ViewChild('o020') o020: ElementRef;
    shariffInst: object;
    public sticky = false;
    public o020position = 0;
    public isMobile: boolean;

    constructor(
        public breakpointObserver: BreakpointObserver,
        public hostElement: ElementRef,
        private readonly dialog: MatDialog,
        private downloadCenterService: DownloadCenterService
    ) {}

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if (!this.notSticky) {
            this.stickyOnScroll(this.o020position);

            this.data.m0020LinkListsContent.links.forEach((link) => {
                const linkId = link.href.replace('#', '');
                // tslint:disable-next-line: no-restricted-globals
                const linkContainer = document.getElementById(linkId);
                const linkPosition = linkContainer.offsetTop;
                const linkBottomPosition =
                    linkContainer.offsetHeight + linkPosition;
                if (
                    // tslint:disable-next-line: no-restricted-globals
                    window.pageYOffset >= linkPosition &&
                    // tslint:disable-next-line: no-restricted-globals
                    window.pageYOffset <= linkBottomPosition
                ) {
                    link.active = true;
                } else {
                    link.active = false;
                }
                console.log(link.active);
            });
        }
    }

    ngOnInit(): void {
        // console.log(
        //     'px-o020-subnavigation-text-link data',
        //     this.data.m0020LinkListsContent
        // );
    }

    ngAfterViewInit() {
        this.news.assetsDownloadCenter = [];
        for (let assetin = 0; assetin < this.news.assets.length; assetin++) {
            this.news.assetsDownloadCenter.push(this.news.assets[assetin].uid);
        }

        if (typeof Shariff !== 'undefined') {
            this.shariffInst = new Shariff(this.shareButtons.nativeElement);
        }
        if (!this.notSticky) {
            this.o020position = this.o020.nativeElement.offsetTop;
            this.stickyOnScroll(this.o020position);
            this.breakpointObserver
                .observe(['(min-width: 922px)'])
                .subscribe((state: BreakpointState) => {
                    this.isMobile = !state.matches;

                    if (this.isMobile) {
                        this.data.m0020LinkListsContent.links.forEach(
                            (link) => {
                                const linkId = link.href.replace('#', '');

                                const linkContainer =
                                    // tslint:disable-next-line: no-restricted-globals
                                    document.getElementById(linkId);
                                linkContainer.style.top =
                                    '-' +
                                    this.o020.nativeElement.offsetHeight +
                                    'px';
                                this.hostElement.nativeElement.style.minHeight =
                                    this.o020.nativeElement.offsetHeight + 'px';
                            }
                        );
                    } else {
                        this.data.m0020LinkListsContent.links.forEach(
                            (link) => {
                                const linkId = link.href.replace('#', '');
                                const linkContainer =
                                    // tslint:disable-next-line: no-restricted-globals
                                    document.getElementById(linkId);
                                linkContainer.style.top = '';
                                this.hostElement.nativeElement.style.minHeight =
                                    '';
                            }
                        );
                    }
                });
        }
    }

    public stickyOnScroll(o020position: number) {
        // console.log(o020position);
        // tslint:disable-next-line: no-restricted-globals
        if (window.pageYOffset >= o020position) {
            this.sticky = true;
        } else {
            this.sticky = false;
        }
    }

    expanderIsOpen(value: {
        collapse: ElementRef;
        collapseButton: ElementRef;
        open: boolean;
    }) {
        this.isOpen.emit(value);
    }

    openDownload() {
        this.dialog.open(NewsDownloadOverlayComponent, {
            width: '810px',
            data: {
                news: this.news
            }
        });
    }

    addNewsToDownlodCenter(news: number[]) {
        this.downloadCenterService.addNewsToDownlodCenter(news);
    }

    checkAllAssetsInDownloadCenter(news: number[]): boolean {
        return this.downloadCenterService.checkAssetsArray(news);
    }
}
