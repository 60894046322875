import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00270ScrollbarComponent } from './a00270-scrollbar.component';

@NgModule({
    declarations: [A00270ScrollbarComponent],
    imports: [CommonModule],
    exports: [A00270ScrollbarComponent]
})
export class A00270ScrollbarModule {}
