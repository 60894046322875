import { Component, Input } from '@angular/core';
import {A00320IconButton} from '../../../interfaces/atoms/a00320-icon-button';

@Component({
  selector: 'px-a00320-icon-button',
  templateUrl: './a00320-icon-button.component.html',
  styleUrls: ['./a00320-icon-button.component.scss']
})
export class A00320IconButtonComponent {
    @Input() button: A00320IconButton;

}
