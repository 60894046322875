import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { T10NewsTileListComponent } from './t10-news-tile-list.component';
import { O030FilterModule } from '../../organisms/o030-filter/o030-filter.module';
import { O060TileListViewModule } from '../../organisms/o060-tile-list-view/o060-tile-list-view.module';
import { O100ContactFooterModule } from '../../organisms/o100-contact-footer/o100-contact-footer.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
    declarations: [T10NewsTileListComponent],
    imports: [
        CommonModule,
        O030FilterModule,
        O060TileListViewModule,
        O100ContactFooterModule,
        PipesModule
    ],
    exports: [T10NewsTileListComponent]
})
export class T10NewsTileListModule {}
