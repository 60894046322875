<div class="gallery-overlay">
    <div class="gallery-overlay__close" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_close_layer_desk.svg'" (click)="close()"></div>
    <div class="gallery-overlay__close-mobile" (click)="close()">
        Schließen <span class="gallery-overlay__close-mobile-icon" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_close_layer_desk.svg'"></span>
    </div>




    <div class="gallery-overlay" data-require="gallery-overlay-gallery">
        <div #slider class="gallery-overlay__content gallery-overlay__content--desktop"
        (swipeleft)="nextSlide()"
        (swiperight)="prevSlide()">
            <div class="gallery-overlay__slider" [ngStyle]="trackStyle" [ngClass]="{'gallery-overlay__slider--transition': transitionOn}">
                <div class="gallery-overlay__slide" [ngStyle]="{'minWidth.px': sliderWidth}" [class.active]="currentIndex === 0">
                    <div class="gallery-overlay__slideInner" >
                        <div class="a-00040">
                            <img class="img-fluid mobile-only"
                            src="{{images[images.length-1].imageMobile}}"
                            *ngIf="images[images.length-1].imageMobile">

                            <img class="img-fluid "
                            src="{{images[images.length-1].imageTablet}}"
                            *ngIf="images[images.length-1].imageTablet"
                            [ngClass]="{'tablet': images[images.length-1].imageMobile, 'mobile': !images[images.length-1].imageMobile}">

                            <img class="img-fluid"
                            src="{{images[images.length-1].image}}"
                            [ngClass]="{'desktop': images[images.length-1].imageTablet , 'from-tablet': !images[images.length-1].imageTablet && images[images.length-1].imageMobile}">
                        </div>
                        <div class="gallery-overlay__copy">
                            {{images.length}} von {{images.length}}
                        </div>
                    </div>
                </div>

                <div class="gallery-overlay__slide" *ngFor="let image of images; let index = index" [ngStyle]="{'minWidth.px': sliderWidth}" [class.active]="currentIndex === index + 1">
                    <div class="gallery-overlay__slideInner" >
                        <div class="a-00040">
                            <img class="img-fluid mobile-only"
                            src="{{image.imageMobile}}"
                            *ngIf="image.imageMobile">

                            <img class="img-fluid "
                            src="{{image.imageTablet}}"
                            *ngIf="image.imageTablet"
                            [ngClass]="{'tablet': image.imageMobile, 'mobile': !image.imageMobile}">

                            <img class="img-fluid"
                            src="{{image.image}}"
                            [ngClass]="{'desktop': image.imageTablet , 'from-tablet': !image.imageTablet && image.imageMobile}">
                        </div>
                        <div class="gallery-overlay__copy">
                            {{index + 1}} von {{images.length}}
                        </div>
                    </div>
                </div>


                <div class="gallery-overlay__slide" [ngStyle]="{'minWidth.px': sliderWidth}" [class.active]="currentIndex === images.length + 1">
                    <div class="gallery-overlay__slideInner" >
                        <div class="a-00040">
                            <img class="img-fluid mobile-only"
                            src="{{images[0].imageMobile}}"
                            *ngIf="images[0].imageMobile">

                            <img class="img-fluid "
                            src="{{images[0].imageTablet}}"
                            *ngIf="images[0].imageTablet"
                            [ngClass]="{'tablet': images[0].imageMobile, 'mobile': !images[0].imageMobile}">

                            <img class="img-fluid"
                            src="{{images[0].image}}"
                            [ngClass]="{'desktop': images[0].imageTablet , 'from-tablet': !images[0].imageTablet && images[0].imageMobile}">
                        </div>
                        <div class="gallery-overlay__copy">
                            1 von {{images.length}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="gallery-overlay__sliderBtn gallery-overlay__sliderBtn--prev" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_full.svg'" (click)="prevSlide()"></div>
            <div class="gallery-overlay__sliderBtn gallery-overlay__sliderBtn--next"
            [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_full.svg'" (click)="nextSlide()"></div>
        </div>
    </div>

</div>
