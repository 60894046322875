import { Component, Input, OnInit } from '@angular/core';

export interface Image {
    mode: string;
    image?: string;
    imageTablet?: string;
    imageMobile?: string;
    ctaText?: string;
    ctaLink?: string;
    videotype?: string;
    mp4?: string;
    ctaLinkOverlay?: string;
    youtubeid?: string;
}

export interface FixedImageGrid {
    bgColor: string;
    textColor: string;
    image1: Image;
    image2: Image;
    image3: Image;
}

@Component({
    selector: 'px-fixed-image-grid',
    templateUrl: './fixed-image-grid.component.html',
    styleUrls: ['./fixed-image-grid.component.scss']
})
export class FixedImageGridComponent implements OnInit {
    content: FixedImageGrid;

    @Input()
    set data(data: string) {
        this.content = JSON.parse(data);
    }
    images: Image[] = [];

    constructor() {}

    ngOnInit(): void {
        if (this.content.image1) {
            this.images.push(this.content.image1);
        }
        if (this.content.image2) {
            this.images.push(this.content.image2);
        }
        if (this.content.image3) {
            this.images.push(this.content.image3);
        }
    }
}
