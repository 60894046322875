<div class="a00110 a00110--{{ modifier }}">
    <span
        *ngIf="modifier == 'a'"
        class="arrow"
        [inlineSVG]="
            '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/red.svg'
        "
    ></span>
    <span class="text">{{ text }}</span>
    <span
        *ngIf="modifier == 'b'"
        class="arrow"
        [inlineSVG]="
            '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/red.svg'
        "
    ></span>
</div>
