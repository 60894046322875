import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'px-a00020-attribute',
    templateUrl: './a00020-attribute.component.html',
    styleUrls: ['./a00020-attribute.component.scss']
})
export class A00020AttributeComponent implements OnInit {
    @Input() modifier: string;
    @Input() text: string;

    @Output()
    delete = new EventEmitter<void>();

    constructor() {}

    ngOnInit(): void {}
}
