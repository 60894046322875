<div class="a00020 a00020--{{ modifier }}">
    <span class="text">{{ text }}</span>
    <span
        (click)="delete.emit()"
        *ngIf="modifier == 'b'"
        class="close"
        [inlineSVG]="
            '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/close.svg'
        "
    ></span>
</div>
