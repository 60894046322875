import { AssetFile } from '../AssetFile';

export enum A00100DownloadButtonType {
    A = 'a'
}

export interface A00100DownloadButtonLink {
    label: string;
    size: number;
    link: string;
    active: boolean;
    click: boolean;
}

export interface A00100DownloadButton {
    title: string;
    type: A00100DownloadButtonType;
    downloads: A00100DownloadButtonLink[];
    file: AssetFile;
}
