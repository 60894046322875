import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00210InputCheckboxComponent } from './a00210-input-checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [A00210InputCheckboxComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [A00210InputCheckboxComponent]
})
export class A00210InputCheckboxModule {}
