
<div class="m-0220 m-0220--mobile">
    <ng-container *ngFor="let teaser of teasers; let ti = index">
        <div class="m-0220__item panel">
            <div class="m-0220__item-header">
                <a class="m-0220__item-header-btn" (click)="closeOther(ti)"  [ngClass]="{'collapsed': !teaser.active}">
                    {{teaser.headline}}
                    <img src="/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_dropdown.svg" width="23" height="14" alt="Dropdown">
                </a>
            </div>
            <div class="m-0220__item-body " [ngClass]="{'active': teaser.active, 'notActive': !teaser.active}" [ngStyle]="{'maxHeight.px': contentHeights[ti]}">
                <div #content>
                    <div class="a-00010" >
                        <img class="img-fluid mobile-only"
                            alt="{{teaser.headline}}"
                            title="{{teaser.headline}}"
                            src="{{teaser.imageMobile}}"
                            *ngIf="teaser.imageMobile">

                        <img class="img-fluid "
                            alt="{{teaser.headline}}"
                            title="{{teaser.headline}}"
                            src="{{teaser.imageTablet}}"
                            *ngIf="teaser.imageTablet"
                            [ngClass]="{'tablet': teaser.imageMobile, 'mobile': teaser.imageMobile}">

                        <img class="img-fluid"
                            alt="{{teaser.headline}}"
                            title="{{teaser.headline}}"
                            src="{{teaser.image}}"
                            [ngClass]="{'desktop': teaser.imageTablet , 'from-tablet': !teaser.imageTablet && teaser.imageMobile}">
                    </div>
                    <span [innerHTML]="teaser.text"></span>
                </div>
            </div>
        </div>

      </ng-container>
</div>
<div class="m-0220 m-0220--desktop">
    <div class="m-0220__titles">
        <ng-container *ngFor="let teaser of teasers; let ti = index">
            <div class="m-0220__title js-m0070-title" (click)="closeOther(ti)" [ngClass]="{'active': teaser.active}">
                    <img src="/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_list_arrow.svg" width="20" height="24" alt="Dropdown">
                <div class="m-0220__headline">{{teaser.headline}}</div>
            </div>
        </ng-container>
    </div>
    <div class="m-0220__body">
        <ng-container *ngFor="let teaser of teasers; let ti = index">
            <div class="m-0220__content js-m0070-content" [ngClass]="{'collapse in active': teaser.active}">
                <div class="a-00010">
                    <img class="img-fluid mobile-only"
                        alt="{{teaser.headline}}"
                        title="{{teaser.headline}}"
                        src="{{teaser.imageMobile}}"
                        *ngIf="teaser.imageMobile">

                    <img class="img-fluid "
                        alt="{{teaser.headline}}"
                        title="{{teaser.headline}}"
                        src="{{teaser.imageTablet}}"
                        *ngIf="teaser.imageTablet"
                        [ngClass]="{'tablet': teaser.imageMobile, 'mobile': teaser.imageMobile}">

                    <img class="img-fluid"
                        alt="{{teaser.headline}}"
                        title="{{teaser.headline}}"
                        src="{{teaser.image}}"
                        [ngClass]="{'desktop': teaser.imageTablet , 'from-tablet': !teaser.imageTablet && teaser.imageMobile}">
                </div>
                <span [innerHTML]="teaser.text"></span>
            </div>
        </ng-container>
    </div>
</div>
