import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DownloadCenterComponent} from './download-center.component';
import {FormsModule} from '@angular/forms';
import { A00050SecondaryButtonModule } from '../../atoms/a00050-secondary-button/a00050-secondary-button.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { MatDialogModule } from '@angular/material/dialog';
import {AtomsModule} from '../../atoms/atoms.module';
import {NgMathPipesModule} from 'ngx-pipes';

@NgModule({
  declarations: [DownloadCenterComponent],
    imports: [
        CommonModule,
        FormsModule,
        A00050SecondaryButtonModule,
        InlineSVGModule.forRoot(),
        AtomsModule,
        MatDialogModule,
        NgMathPipesModule
    ],
    exports: [DownloadCenterComponent]
})
export class DownloadCenterModule { }
