import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00280OverlayLightboxComponent } from './a00280-overlay-lightbox.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [A00280OverlayLightboxComponent],
    imports: [CommonModule, HttpClientModule, InlineSVGModule.forRoot()],
    exports: [A00280OverlayLightboxComponent]
})
export class A00280OverlayLightboxModule {}
