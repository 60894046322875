<px-m0010-keyvisual
    [modifier]="modifier"
    [image]="image"
    [buttonFirstText]="buttonFirstText"
    [buttonFirstCta]="buttonFirstCta"
    [buttonSecondText]="buttonSecondText"
    [buttonSecondCta]="buttonSecondCta"
    [headline]="headline"
    [headlineSecond]="headlineSecond"
></px-m0010-keyvisual>
