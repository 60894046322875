<div class="a00120">
    <span
        class="arrow"
        [inlineSVG]="
            '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/black.svg'
        "
    ></span>
    <span class="headline">{{ headline }}</span>
    <span class="line"></span>
</div>
