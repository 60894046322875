import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'px-a00210-input-checkbox',
    templateUrl: './a00210-input-checkbox.component.html',
    styleUrls: ['./a00210-input-checkbox.component.scss']
})
export class A00210InputCheckboxComponent implements OnInit {
    @Input() checkboxId = '';
    @Input() inputName: string;
    @Input() label: string;
    @Input() modifier: string;
    @Input() required = false;
    @Input() disabled = false;
    @Input() isTop = false;

    @Input() inputControl: FormControl;

    constructor() {}

    ngOnInit(): void {}
}
