import { Component, Input, OnInit } from '@angular/core';
import { M0150ContactCardBigType } from '../../../interfaces/molecules/m0150-contact-card-big';
import { SocialMedia } from '../../../interfaces/Employee';
import { A00150ContactLinksType } from 'src/app/interfaces/atoms/a00150-contact-links';

@Component({
    selector: 'px-m0150-contact-card-big',
    templateUrl: './m0150-contact-card-big.component.html',
    styleUrls: ['./m0150-contact-card-big.component.scss']
})
export class M0150ContactCardBigComponent implements OnInit {
    @Input() modifier: M0150ContactCardBigType = M0150ContactCardBigType.A;
    @Input() image: string;
    @Input() forename: string;
    @Input() surname: string;
    @Input() jobTitle: string;
    @Input() email: string;
    @Input() phone: string;
    @Input() mobile: string;
    @Input() socials: SocialMedia[] = [];

    A00150ContactLinksType = A00150ContactLinksType;

    constructor() {}

    ngOnInit(): void {}
}
