<div class="img-overlay-wrap">
    <ng-content></ng-content>
    <svg
        *ngIf="!disabled"
        width="100%"
        height="100%"
        [attr.viewBox]="'0 0 ' + width + ' ' + height"
    >
        <path [attr.fill-opacity]="opacity" [attr.fill]="color" [attr.d]="d" />
    </svg>
</div>
