<div class="fuelData">
    <div class="row">
        <div class="col-lg-12">
            <p class="legalTexts bigger">
                <strong>Verbrauchs- und Effizienzwerte</strong>
            </p>
            <p class="legalTexts">
                <strong>Space Star</strong> Energieverbrauch 4,9-5,5 l/100 km Benzin; CO<sub>2</sub>-Emission 112-125 g/km; CO<sub>2</sub>-Klasse C-D;
                <br><strong>COLT 1.0</strong> Energieverbrauch 5,2-5,4 l/100 km Benzin; CO<sub>2</sub>-Emission 118-121 g/km; CO<sub>2</sub>-Klasse D;
                <br><strong>COLT Hybrid</strong> Energieverbrauch 4,2-4,3 l/100 km Benzin; CO<sub>2</sub>-Emission 96-97 g/km; CO<sub>2</sub>-Klasse C;
                <br><strong>ASX 1.0 Turbo</strong> Energieverbrauch 6,0 l/100 km Benzin; CO<sub>2</sub>-Emission 135 g/km; CO<sub>2</sub>-Klasse D;
                <br><strong>ASX Mildhybrid 1.3 Turbo</strong> Energieverbrauch 6,0 l/100 km Benzin; CO<sub>2</sub>-Emission 135 g/km; CO<sub>2</sub>-Klasse D;
                <br><strong>ASX Hybrid</strong>  Energieverbrauch 4,7 l/100 km Benzin; CO<sub>2</sub>-Emission 107 g/km; CO<sub>2</sub>-Klasse C; kombinierte Werte.**
                <!-- <br><strong>ASX Plug-in Hybrid</strong> Energieverbrauch 13,3 kWh/100 km Strom & 1,3-1,4 l/100 km Benzin; CO<sub>2</sub>-Emission 30-31 g/km; CO<sub>2</sub>-Klasse B; gewichtet kombinierte Werte. Bei entladener Batterie: Energieverbrauch 5,3 l/100 km Benzin; CO<sub>2</sub>-Klasse D; -->
                <br><strong>Eclipse Cross Plug-in Hybrid 4WD 2.4</strong> Energieverbrauch 17,5 kWh/100 km Strom & 2,0 l/100 km Benzin; CO<sub>2</sub>-Emission 46 g/km; CO<sub>2</sub>-Klasse B; gewichtet kombinierte Werte. Bei entladener Batterie: Energieverbrauch 7,3 l/100 km Benzin; CO<sub>2</sub>-Klasse F; kombinierte Werte.**
            </p>
            <p class="legalTexts">
                <sup class="footer__footnote">**</sup>Die nach PKW-EnVKV angegebenen offiziellen Werte zu Verbrauch und CO<sub>2</sub>-Emission sowie ggf. Angaben zur Reichweite wurden nach dem vorgeschriebenen Messverfahren WLTP ermittelt. Die tatsächlichen Werte zum Verbrauch elektrischer Energie / Kraftstoff, ggf. zur Reichweite und den CO<sub>2</sub>-Emissionen hängen ab von individueller Fahrweise, Straßen-, Verkehrs- und Wetterbedingungen, Klimaanlageneinsatz etc. <strong>Dies und zusätzliche Ausstattungen sowie Zubehör können zu höheren als den angegebenen Verbrauchs- sowie CO<sub>2</sub>-Werten sowie ggf. einer geringeren als der angegebenen elektrischen Reichweite führen.</strong> Gewichtete Werte sind Mittelwerte für Kraftstoff- und Stromverbrauch von extern aufladbaren Hybridelektrofahrzeugen bei durchschnittlichem Nutzungsprofil und täglichem Laden der Batterie.
            </p>
            <p class="legalTexts">
                Preis- und Ausstattungsänderungen vorbehalten.
                <br>Durch den europaweiten Vertrieb unserer Fahrzeuge können sich Ausstattungsdetails länderspezifisch unterscheiden. Die Abbildungen auf dieser Website zeigen teilweise Ausstattungsmerkmale/-kombinationen oder Zubehörteile, die in Deutschland nicht verfügbar oder nur gegen Aufpreis lieferbar sind.
            </p>
        </div>
    </div>
</div>
<div class="copyright">
    <p class="text-center">© <strong>MMD Automobile GmbH</strong> 2023. Alle Rechte vorbehalten.</p>
</div>
