import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PressMailerComponent } from './press-mailer.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { AtomsModule } from '../../atoms/atoms.module';

@NgModule({
    declarations: [PressMailerComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatCheckboxModule,
        MatInputModule,
        AtomsModule
    ],
    exports: [PressMailerComponent]
})
export class PressMailerModule {}
