import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-m0200-bullet-list-teaser',
    templateUrl: './m0200-bullet-list-teaser.component.html',
    styleUrls: ['./m0200-bullet-list-teaser.component.scss']
})
export class M0200BulletListTeaserComponent implements OnInit {
    @Input() image?: string;
    @Input() imageTablet?: string;
    @Input() imageMobile?: string;
    @Input() headline: string;
    @Input() headlineType: string;
    @Input() bulletpointsChunks: {
        bullet: {
            headline: string;
        };
    }[][];

    constructor() {}

    ngOnInit(): void {}
}
