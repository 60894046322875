import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PodcastComponent } from './podcast.component';
import { M0180PodcastModule } from '../../molecules/m0180-podcast/m0180-podcast.module';



@NgModule({
  declarations: [PodcastComponent],
  imports: [CommonModule, M0180PodcastModule]
})
export class PodcastModule { }
