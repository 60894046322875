import {
    Component,
    OnInit,
    Input,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import { M0070Tile } from '../../../interfaces/molecules/m0070-newsteaser-tile';
import { DownloadCenterService } from '../../../services/download-center.service';

@Component({
    selector: 'px-m0070-newsteaser-tile',
    templateUrl: './m0070-newsteaser-tile.component.html',
    styleUrls: ['./m0070-newsteaser-tile.component.scss']
})
export class M0070NewsteaserTileComponent implements OnInit, OnChanges {
    @Input()
    tile: M0070Tile;

    image = '';

    constructor(private downloadCenterService: DownloadCenterService) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tile && this.tile) {
            this.image = this.tile.img.toUrl(0, 570);
        }
    }

    addNewsToDownlodCenter(news: number[]) {
        this.downloadCenterService.addNewsToDownlodCenter(news);
    }

    checkAllAssetsInDownloadCenter(news: number[]): boolean {
        return this.downloadCenterService.checkAssetsArray(news);
    }
}
