import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0130AccordeonComponent } from './m0130-accordeon.component';
import { A00120AccordionModule } from '../../atoms/a00120-accordion/a00120-accordion.module';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [M0130AccordeonComponent],
    imports: [CommonModule, A00120AccordionModule, NgbAccordionModule],
    exports: [M0130AccordeonComponent]
})
export class M0130AccordeonModule {}
