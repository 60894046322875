import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    M0050FilterCardTag,
    M0050FilterCardType
} from '../../../interfaces/molecules/m0050-filter-card';
import { Subscription } from 'rxjs';

@Component({
    selector: 'px-m0050-filter-card',
    templateUrl: './m0050-filter-card.component.html',
    styleUrls: ['./m0050-filter-card.component.scss']
})
export class M0050FilterCardComponent implements OnInit, OnChanges, OnDestroy {
    @Input() modifier: M0050FilterCardType = M0050FilterCardType.A;
    @Input() label: string;
    @Input() tags: M0050FilterCardTag[];
    @Input() filterId = '';

    _selectedTags: Array<number | string> = [];

    @Input()
    set selectedTags(tags: Array<number | string>) {
        this._selectedTags = tags;
        this.applySelected();
    }

    @Output()
    selectedTagsChange = new EventEmitter<Array<number | string>>();
    @Output()
    isOpen: EventEmitter<{
        collapse: ElementRef;
        collapseButton: ElementRef;
        open: boolean;
    }> = new EventEmitter();

    filteredTags: M0050FilterCardTag[] = [];

    M0050FilterCardType = M0050FilterCardType;

    searchControl = new FormControl('');

    formGroup: FormGroup;

    protected sub = new Subscription();

    getCheckboxControl(id: number | string): FormControl {
        if (this.formGroup) {
            return this.formGroup.get(id + '') as FormControl;
        }
        return null;
    }

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tags) {
            const group = new FormGroup({});

            for (const tag of this.tags) {
                const control = new FormControl(false);
                group.addControl(tag.id + '', control);
            }

            this.formGroup = group;

            this.sub.add(
                this.formGroup.valueChanges.subscribe((value) => {
                    const selected = [];
                    for (const k in value) {
                        if (value.hasOwnProperty(k)) {
                            if (value[k]) {
                                selected.push(k);
                            }
                        }
                    }
                    this.selectedTagsChange.emit(selected);
                })
            );
        }
    }

    ngOnInit(): void {
        this.sub.add(
            this.searchControl.valueChanges.subscribe((value) => {
                this.filterTags(value);
            })
        );
        this.filterTags('');
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    filterTags(search: string) {
        const filteredTags = [];
        for (const tag of this.tags) {
            if (tag.label.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                filteredTags.push(tag);
            }
        }
        this.filteredTags = filteredTags;
    }

    applySelected() {
        if (this.formGroup && this.tags?.length > 0) {
            for (const tag of this.tags) {
                const fc = this.formGroup.get(tag.id + '');
                if (fc) {
                    fc.setValue(
                        this._selectedTags.indexOf(tag.id + '') !== -1,
                        {
                            emitEvent: false
                        }
                    );
                } else {
                    console.log('cant find', tag, 'in', this.formGroup);
                }
            }
        }
    }

    expanderIsOpen(value: {
        collapse: ElementRef;
        collapseButton: ElementRef;
        open: boolean;
    }) {
        this.isOpen.emit(value);
    }
}
