import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0020LinkListsComponent } from './m0020-link-lists.component';
import { A00130UnderlineLinkModule } from '../../atoms/a00130-underline-link/a00130-underline-link.module';
import { A00160ExpanderModule } from '../../atoms/a00160-expander/a00160-expander.module';

@NgModule({
    declarations: [M0020LinkListsComponent],
    imports: [CommonModule, A00130UnderlineLinkModule, A00160ExpanderModule],
    exports: [M0020LinkListsComponent]
})
export class M0020LinkListsModule {}
