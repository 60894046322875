import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioPlayerComponent } from './audio-player.component';
import { M0240AudioPlayerModule } from '../../molecules/m0240-audio-player/m0240-audio-player.module';

@NgModule({
    declarations: [AudioPlayerComponent],
    imports: [CommonModule, M0240AudioPlayerModule],
    exports: [AudioPlayerComponent]
})
export class AudioPlayerModule {}
