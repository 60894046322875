import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { M0110MediaTile } from '../../../interfaces/molecules/m0110-media-tile';

@Component({
    selector: 'px-o080-material-mediacenter',
    templateUrl: './o080-material-mediacenter.component.html',
    styleUrls: ['./o080-material-mediacenter.component.scss']
})
export class O080MaterialMediacenterComponent implements OnInit {
    @Input()
    tiles: M0110MediaTile[] = [];

    @Input()
    hasMore = true;

    @Output()
    loadMore = new EventEmitter<void>();

    constructor() {}

    ngOnInit(): void {}

    tilesTrackBy(_: number, tile: M0110MediaTile) {
        return tile.id;
    }
}
