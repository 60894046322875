<div #o030 class="container o030" [class.sticky]="sticky">
    <px-m0060-tag-slide
        [selectedTags]="selectedTagsForRendering"
        (removeSelectedTag)="removeSelectedTag($event.filterId, $event.tagId)"
    >
        <px-m0050-filter-card
            *ngFor="let filter of filters"
            [modifier]="filter.filterCard.modifier"
            [filterId]="filter.id"
            [label]="filter.label"
            [tags]="filter.filterCard.tags"
            [selectedTags]="selectedTags[filter.id]"
            (selectedTagsChange)="onSelected(filter.id, $event)"
            (isOpen)="expanderIsOpen($event)"
            [class.o030__filterHistoricalModels]=" filter.label != 'Veröffentlichungsdatum'  &&
            filter.label != 'Medium'"
            [class.o030__mediaCard]="filter.label === 'Medium'"
        ></px-m0050-filter-card>
    </px-m0060-tag-slide>
</div>
