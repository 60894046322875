import { Component, OnInit, Input } from '@angular/core';

export interface Audio {
    headline: string;
    text: string;
    image: string;
    mp3: string;
}

@Component({
    selector: 'px-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {
    content: Audio;

    @Input()
    set data(data: string) {
        this.content = JSON.parse(data);
    }

    constructor() {}

    ngOnInit(): void {}
}
