<div class='m-0230 mobile'>
    <div class="col-12">
        <div class="row">
            <ng-container *ngIf="videotype === 'youtube'">
                <ng-container [ngTemplateOutlet]="youtubeVideo"></ng-container>
            </ng-container>
            <ng-container *ngIf="videotype === 'mp4'">
                <ng-container [ngTemplateOutlet]="mp4Video"></ng-container>
            </ng-container>
        </div>
    </div>
    <h3 class='m-0230__headline'>
        {{ headline }}
    </h3>
    <p class='m-0230__text' [innerHTML]='text'></p>
</div>

<div class='m-0230 desktop'>
    <div class='content'>
        <div *ngIf="videoright === '1'" class='row m-0230__desktopContent'>
            <div class='col-md-6 m-0230__innerVideo'>
                <div class='m-0230__videoWrapper'>
                    <ng-container *ngIf="videotype === 'youtube'">
                        <ng-container [ngTemplateOutlet]='youtubeVideo'></ng-container>
                    </ng-container>
                    <ng-container *ngIf="videotype === 'mp4'">
                        <ng-container [ngTemplateOutlet]='mp4Video'></ng-container>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-6 m-0230__innerContent__right'>
                <h3 class='m-0230__headline'>
                    {{ headline }}
                </h3>
                <p class='m-0230__text' [innerHTML]='text'></p>
            </div>
        </div>

        <div *ngIf="videoright === '0'" class='row m-0230__desktopContent'>
            <div class='col-md-6 m-0230__innerContent__left'>
                <h3 class='m-0230__headline'>
                    {{ headline }}
                </h3>
                <p class='m-0230__text' [innerHTML]='text'></p>
            </div>
            <div class='col-md-6 m-0230__innerVideo'>
                <div class='m-0230__videoWrapper'>
                    <ng-container *ngIf="videotype === 'youtube'">
                        <ng-container [ngTemplateOutlet]='youtubeVideo'></ng-container>
                    </ng-container>
                    <ng-container *ngIf="videotype === 'mp4'">
                        <ng-container [ngTemplateOutlet]='mp4Video'></ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #youtubeVideo>
    <div class='embed-responsive embed-responsive-16by9' *ngIf='youtube'>
        <iframe class='embed-responsive-item m-0060__youtubeIframe' [src]='youtube | safeUrl'
                title='YouTube video player' frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope' allowfullscreen></iframe>
    </div>
</ng-template>

<ng-template #mp4Video>
    <div class='embed-responsive embed-responsive-16by9' *ngIf='mp4'>
        <video class='embed-responsive-item' controls>
            <source muted src='{{mp4}}' type='video/mp4'>
        </video>
    </div>
</ng-template>
