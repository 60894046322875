import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0140ContactCardSmallComponent } from './m0140-contact-card-small.component';
import { A00150ContactLinksModule } from '../../atoms/a00150-contact-links/a00150-contact-links.module';

@NgModule({
    declarations: [M0140ContactCardSmallComponent],
    imports: [CommonModule, A00150ContactLinksModule],
    exports: [M0140ContactCardSmallComponent]
})
export class M0140ContactCardSmallModule {}
