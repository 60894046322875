import { Component, Input, OnInit } from '@angular/core';
import { O070PressfolderType } from '../../../interfaces/organisms/o070-pressfolder';
import { M0100Pressfolder } from '../../../interfaces/molecules/m0100-pressfolder';

@Component({
    selector: 'px-o070-pressfolder',
    templateUrl: './o070-pressfolder.component.html',
    styleUrls: ['./o070-pressfolder.component.scss']
})
export class O070PressfolderComponent implements OnInit {
    @Input()
    modifier: O070PressfolderType = O070PressfolderType.A;

    @Input()
    tiles: M0100Pressfolder[] = [];

    constructor() {}

    ngOnInit(): void {}
}
