<div class="video-overlay">
    <div class="video-overlay__close" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_close_layer_desk.svg'" (click)="close()"></div>
    <div class="video-overlay__close-mobile" (click)="close()">
        Schließen <span class="video-overlay__close-mobile-icon" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_close_layer_desk.svg'"></span>
    </div>

    <div class="embed-responsive embed-responsive-16by9" *ngIf="youtube">
        <iframe class="embed-responsive-item m-0060__youtubeIframe" [src]=" youtube | safeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope" allowfullscreen></iframe>
    </div>
    <div *ngIf="data.mp4" class='video-overlay__mp4-wrapper'>
        <video class="js-m-0060-video video-overlay__video" controls>
            <source src="{{data.mp4}}" type="video/mp4">
        </video>
    </div>
</div>
