import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0240AudioPlayerComponent } from './m0240-audio-player.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [M0240AudioPlayerComponent],
    imports: [
        CommonModule,
        MatCardModule
    ],
  exports: [M0240AudioPlayerComponent]
})
export class M0240AudioPlayerModule { }
