import { Component, Input, OnInit } from '@angular/core';
import { A00100DownloadButton } from '../../../interfaces/atoms/a00100-download-button';
import { A00220MediaCard } from '../../../interfaces/atoms/a00220-media-card';
import { M0110MediaTileType } from '../../../interfaces/molecules/m0110-media-tile';

@Component({
    selector: 'px-m0110-media-tile',
    templateUrl: './m0110-media-tile.component.html',
    styleUrls: ['./m0110-media-tile.component.scss']
})
export class M0110MediaTileComponent implements OnInit {
    @Input() modifier: M0110MediaTileType = M0110MediaTileType.A;
    @Input() preview: A00220MediaCard;
    @Input() download: A00100DownloadButton;

    constructor() {}

    ngOnInit(): void {}
}
