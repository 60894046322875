import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GalleryOverlayComponent } from 'src/app/components/helpers/gallery-overlay/gallery-overlay.component';

export interface M0220Image {
    image?: string;
    imagePopup?: string;
    imageTablet?: string;
    imagePopupTablet?: string;
    imageMobile?: string;
    imagePopupMobile?: string;
    index?: number;
}

@Component({
    selector: 'px-m0220-image-gallery',
    templateUrl: './m0220-image-gallery.component.html',
    styleUrls: ['./m0220-image-gallery.component.scss']
})
export class M0220ImageGalleryComponent implements OnInit, AfterViewInit {
    @Input() images: M0220Image[];
    @ViewChild('slider') slider: ElementRef;

    sliderInit = false;
    imagesChunks?: M0220Image[][] = [];
    overlayImages: M0220Image[] = [];
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
    currentIndex = 0;
    endIndex = 0;
    transformValue = 0;
    sliderWidth = 0;
    slideWidth = 0;
    trackStyle = {
        transform: 'translateX(0)'
    };
    btnPrevStyle = {
        opacity: '0.2',
        cursor: 'default'
    };
    btnNextStyle = {
        opacity: '0.2',
        cursor: 'default'
    };

    constructor(
        private readonly dialog: MatDialog,
        public breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver
            .observe([
                '(max-width: 767px)',
                '(min-width: 768px)',
                '(min-width: 922px)'
            ])
            .subscribe((state: BreakpointState) => {
                this.mobile = state.breakpoints['(max-width: 767px)'];
                this.tablet = state.breakpoints['(min-width: 768px)'];
                this.desktop = state.breakpoints['(min-width: 922px)'];
                if (this.sliderInit) {
                    this.btnState();
                    this.setWidth();
                    this.changeTrackStyle();
                    if (this.currentIndex > this.endIndex) {
                        this.currentIndex = this.endIndex;
                    }
                }
            });
    }
    ngAfterViewInit(): void {
        this.setWidth();
    }

    ngOnInit(): void {
        for (let index = 0; index < this.images.length; index++) {
            const element = this.images[index];
            this.images[index].index = index;
            this.overlayImages.push({
                image: element.imagePopup,
                imageTablet: element.imagePopupTablet,
                imageMobile: element.imagePopupMobile
            });
        }

        const tmpSplit = [];
        const chunkSize = 2;
        for (let i = 0; i < this.images.length; i += chunkSize) {
            const chunk = this.images.slice(i, i + chunkSize);
            tmpSplit.push(chunk);
        }
        this.imagesChunks = [];
        this.imagesChunks = tmpSplit;
        this.sliderInit = true;
        this.btnState();
    }

    openDialog(clickedIndex: number): void {
        this.dialog.open(GalleryOverlayComponent, {
            width: '1280px',
            maxWidth: '100vw',
            data: {
                images: this.overlayImages,
                clickedIndex
            },
            panelClass: 'gallery-overlay-panel'
        });
    }

    prevSlide() {
        if (this.currentIndex === 0) {
            return;
        }
        this.currentIndex = this.currentIndex - 1;
        this.btnState();
        this.changeTrackStyle();
    }

    nextSlide() {
        if (this.currentIndex === this.endIndex) {
            return;
        }
        this.currentIndex = this.currentIndex + 1;
        this.btnState();
        this.changeTrackStyle();
    }

    changeTrackStyle(): void {
        this.transformValue = this.currentIndex * this.slideWidth;
        this.trackStyle = {
            transform: 'translateX(' + -this.transformValue + 'px)'
        };
    }

    setWidth() {
        this.sliderWidth = this.slider.nativeElement.offsetWidth;

        if (this.mobile) {
            this.slideWidth = this.sliderWidth / 2;
        } else {
            this.slideWidth = this.sliderWidth / 3;
        }
    }

    btnState() {
        if (this.mobile) {
            this.endIndex = Math.ceil(this.images.length / 4);
        } else {
            this.endIndex = Math.ceil(this.images.length / 2 - 3);
        }
        if (this.currentIndex === 0) {
            this.deactivatePrevButton();
        } else {
            this.activatePrevButton();
        }
        if (this.endIndex <= 0) {
            this.deactivateNextButton();
        } else if (this.currentIndex === this.endIndex) {
            this.deactivateNextButton();
        } else {
            this.activateNextButton();
        }
    }
    deactivatePrevButton() {
        this.btnPrevStyle = {
            opacity: '0.2',
            cursor: 'default'
        };
    }

    activatePrevButton() {
        this.btnPrevStyle = {
            opacity: '1',
            cursor: 'pointer'
        };
    }

    deactivateNextButton() {
        this.btnNextStyle = {
            opacity: '0.2',
            cursor: 'default'
        };
    }

    activateNextButton() {
        this.btnNextStyle = {
            opacity: '1',
            cursor: 'pointer'
        };
    }
}
