import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00020AttributeComponent } from './a00020-attribute.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [A00020AttributeComponent],
    imports: [CommonModule, HttpClientModule, InlineSVGModule.forRoot()],
    exports: [A00020AttributeComponent]
})
export class A00020AttributeModule {}
