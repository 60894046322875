import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { A00040PrimaryButtonIconType } from '../../../interfaces/atoms/a00040-primary-button-icon';

@Component({
    selector: 'px-terms-of-use-overlay',
    templateUrl: './terms-of-use-overlay.component.html',
    styleUrls: ['./terms-of-use-overlay.component.scss']
})
export class TermsOfUseOverlayComponent implements OnInit {
    A00040PrimaryButtonIconType = A00040PrimaryButtonIconType;

    constructor(public dialogRef: MatDialogRef<TermsOfUseOverlayComponent>) {}

    checkbox = new FormControl(false, [Validators.requiredTrue]);

    ngOnInit(): void {}

    accept() {
        if (this.checkbox.invalid) {
            return;
        }

        this.dialogRef.close(true);
    }
}
