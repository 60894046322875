import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
    selector: 'px-a00190-input-textarea',
    templateUrl: './a00190-input-textarea.component.html',
    styleUrls: ['./a00190-input-textarea.component.scss']
})
export class A00190InputTextareaComponent implements OnInit {
    @Input() inputName: string;
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() error: string;

    @Input() inputControl: AbstractControl;

    constructor(public control: ControlContainer) {}

    ngOnInit(): void {}
}
