import { Component, Input, OnInit } from '@angular/core';
import { M0140ContactCardSmall } from '../../../interfaces/molecules/m0140-contact-card-small';
import { O100ContactFooterType } from '../../../interfaces/organisms/o100-contact-footer';
import { A00040PrimaryButtonIconType } from '../../../interfaces/atoms/a00040-primary-button-icon';

@Component({
    selector: 'px-o100-contact-footer',
    templateUrl: './o100-contact-footer.component.html',
    styleUrls: ['./o100-contact-footer.component.scss']
})
export class O100ContactFooterComponent implements OnInit {
    @Input() type: O100ContactFooterType = O100ContactFooterType.A;
    @Input() teaserHeadline: string;
    @Input() teaserDescription: string;
    @Input() teaserLink: string;
    @Input() teaserLinkName: string;
    @Input() employees: M0140ContactCardSmall[];

    A00040PrimaryButtonIconType = A00040PrimaryButtonIconType;

    constructor() {}

    ngOnInit(): void {}
}
