import { Component, OnInit, ViewChild } from '@angular/core';
import { AssetFile } from '../../../interfaces/AssetFile';
import { Category } from '../../../interfaces/Category';
import { MatDialog } from '@angular/material/dialog';
import {
    AssetEditComponent,
    AssetEditData
} from './dialogs/asset-edit/asset-edit.component';
import {
    ApiService,
    AssetFilter,
    FileDataSource
} from '../../../services/api.service';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
    selector: 'px-asset-manager',
    templateUrl: './asset-manager.component.html',
    styleUrls: ['./asset-manager.component.scss']
})
export class AssetManagerComponent implements OnInit {
    models: Category[] = [];
    modelYears: Category[] = [];
    tags: Category[] = [];
    filteredTags: Category[] = [];

    selectedFiles: number[] = [];

    files: AssetFile[] = [];

    filter: AssetFilter = {
        model: 0,
        modelYear: 0,
        tag: 0,
        dateFrom: null,
        dateTo: null
    };

    filesDataSource: FileDataSource;

    @ViewChild(CdkVirtualScrollViewport, { static: false })
    viewport: CdkVirtualScrollViewport;

    constructor(
        private readonly dialog: MatDialog,
        private readonly api: ApiService
    ) {
        (async () => {
            const categories = await this.api.backendGetAllAssetCategories();

            this.models = categories.models;
            this.modelYears = categories.modelYears;
            this.tags = categories.others;

            this.filterTags('');
        })();
        this.filterTags('');

        this.filesDataSource = new FileDataSource(this.api);
        this.filesDataSource.dataStream.subscribe(
            (files) => (this.files = files)
        );
        this.applyFilter();
    }

    applyFilter() {
        this.filesDataSource.setFilter(this.filter);
        this.resetDataSource();
    }

    async resetDataSource() {
        await this.filesDataSource.reset();
        this.viewport.setRenderedRange({
            start: 0,
            end: this.viewport.getRenderedRange().end + 1
        });
        this.viewport.checkViewportSize();
        this.selectedFiles = [];
    }

    ngOnInit(): void {}

    filterTags(search: string) {
        if (!search) {
            this.filteredTags = this.tags;
            return;
        }

        const tags = [];
        for (const tag of this.tags) {
            if (tag.title.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                tags.push(tag);
            }
        }
        this.filteredTags = tags;
    }

    editSelected() {
        const files = this.files.filter(
            (f) => this.selectedFiles.indexOf(f.uid) !== -1
        );
        if (files.length === 0) {
            return;
        }
        let data: AssetEditData;

        if (files.length === 1) {
            data = {
                type: 'single',
                models: [...this.models],
                modelYears: [...this.modelYears],
                tags: [...this.tags],
                file: files[0]
            };
        } else {
            data = {
                type: 'multiple',
                models: [...this.models],
                modelYears: [...this.modelYears],
                tags: [...this.tags],
                files
            };
        }

        this.openEditDialog(data);
    }

    downloadSelected() {
        const files = this.files.filter(
            (f) => this.selectedFiles.indexOf(f.uid) !== -1
        );
        if (files.length === 0) {
            return;
        }

        let url = '';
        if (files.length === 1) {
            url = this.api.getDownloadLink(files[0].uid);
        } else {
            url = this.api.getZipDownloadLink(
                files.map((f) => ({ file: f.uid }))
            );
        }

        if (url) {
            // tslint:disable-next-line
            window.location.href = url;
        }
    }

    removeCategoriesFromSelected() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            width: '980px',
            data: 'Wollen Sie wirklich die Kategorien von den ausgewählten Dateien löschen?'
        });

        dialogRef.afterClosed().subscribe(async (res) => {
            if (res) {
                await this.api.removeAllCategories(this.selectedFiles);
                await this.resetDataSource();
            }
        });
    }

    deleteFiles() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            width: '980px',
            data: 'Wollen Sie wirklich die ausgewählten Dateien löschen?'
        });

        dialogRef.afterClosed().subscribe(async (res) => {
            if (res) {
                await this.api.backendDeleteFiles(this.selectedFiles);
                await this.resetDataSource();
            }
        });
    }

    openEditDialog(data: AssetEditData) {
        const dialogRef = this.dialog.open(AssetEditComponent, {
            width: '980px',
            data
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                await this.resetDataSource();
            }
        });
    }

    onFileUpload(event: Event) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
            if (target.files.length > 20) {
                alert('Es werden maximal 20 Dateien gleichzeitig unterstützt');
                target.value = '';
                return;
            }

            let totalSize = 0;
            for (let i = 0; i < target.files.length; i++) {
                totalSize += target.files.item(i).size;
            }

            if (totalSize > 1050000000) {
                alert('Maximale Gesamt Dateigröße ist 1GB');
                target.value = '';
                return;
            }

            const data: AssetEditData = {
                type: 'upload',
                models: [...this.models],
                modelYears: [...this.modelYears],
                tags: [...this.tags],
                uploadFiles: Array.from<File>(target.files)
            };

            this.openEditDialog(data);
        }
        target.value = '';
    }

    onSelect(uid: number, selected: boolean) {
        const idx = this.selectedFiles.indexOf(uid);
        if (idx !== -1) {
            this.selectedFiles.splice(idx, 1);
        }
        if (selected) {
            this.selectedFiles.push(uid);
        }
    }
}
