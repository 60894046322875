<div class="m-0200">
    <div class="m-0200__image">
        <img class="img-fluid mobile-only"
            alt="{{headline}}"
            title="{{headline}}"
            src="{{imageMobile}}"
            *ngIf="imageMobile">

        <img class="img-fluid "
            alt="{{headline}}"
            title="{{headline}}"
            src="{{imageTablet}}"
            *ngIf="imageTablet"
            [ngClass]="{'tablet': imageMobile, 'mobile': imageMobile}">

        <img class="img-fluid"
            alt="{{headline}}"
            title="{{headline}}"
            src="{{image}}"
            [ngClass]="{'desktop': imageTablet , 'from-tablet': !imageTablet && imageMobile}">
    </div>
    <div class="m-0200__content">
        <ng-container *ngIf="headlineType === 'h1'">
            <h1 class="m-0200__headline" [innerHTML]="headline"></h1>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h2' || !headlineType">
            <h2 class="m-0200__headline" [innerHTML]="headline"></h2>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h3'">
            <h3 class="m-0200__headline" [innerHTML]="headline"></h3>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h4'">
            <h4 class="m-0200__headline" [innerHTML]="headline"></h4>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h5'">
            <h5 class="m-0200__headline" [innerHTML]="headline"></h5>
        </ng-container>
        <ng-container *ngIf="headlineType === 'h6'">
            <h6 class="m-0200__headline" [innerHTML]="headline"></h6>
        </ng-container>
        <div class="m-0200__lists">
            <ul *ngFor="let chunk of bulletpointsChunks">
                <ng-container *ngFor="let elem of chunk">
                    <li [innerHTML]="elem.bullet.headline"></li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
