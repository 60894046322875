import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00170InputTitleComponent } from './a00170-input-title.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [A00170InputTitleComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [A00170InputTitleComponent]
})
export class A00170InputTitleModule {}
