<div class="a00220 a00220--{{ modifier }}">
    <div class="mediaCard">
        <img
            *ngIf="
                modifier !== A00220MediaCardType.E &&
                modifier !== A00220MediaCardType.F
            "
            class="img-fluid"
            [src]="src"
            alt=""
        />
        <div
            *ngIf="
                modifier === A00220MediaCardType.C ||
                modifier === A00220MediaCardType.D
            "
            class="videoCamera"
            [inlineSVG]="
                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/video.svg'
            "
        ></div>
        <div
            *ngIf="
                modifier === A00220MediaCardType.E ||
                modifier === A00220MediaCardType.F
            "
            class="textIcon"
            [inlineSVG]="
                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/download/text.svg'
            "
        ></div>
    </div>
</div>
