import { Component, Inject, InjectionToken, OnInit } from '@angular/core';

export const LOADING_OVERLAY_DIALOG_DATA = new InjectionToken<string>(
    'LOADING_OVERLAY_DIALOG_DATA'
);

@Component({
    selector: 'px-loading-overlay',
    templateUrl: './loading-overlay.component.html',
    styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements OnInit {
    constructor(@Inject(LOADING_OVERLAY_DIALOG_DATA) public text: string) {}

    ngOnInit(): void {}
}
