<div class="a00010">
    <div class="container">
        <div id="linkWrapper">
            <a class="link" href="/">
                <img
                    class="backgroundImg"
                    srcset="
                        https://www.mitsubishi-motors.de/typo3conf/ext/main_configuration/Resources/Public/IMG/mitsubishi-logo-mobile.png 104w,
                        https://www.mitsubishi-motors.de/typo3conf/ext/main_configuration/Resources/Public/IMG/mitsubishi-logo.png        148w
                    "
                    sizes="(max-width: 991px) 104w,
                            148px"
                    src="https://www.mitsubishi-motors.de/typo3conf/ext/main_configuration/Resources/Public/IMG/mitsubishi-logo.png"
                    alt="Homelink Background"
                />
                <img
                    class="logo"
                    title="Mitsubishi"
                    alt="Mitsubishi Logo"
                    src="https://www.mitsubishi-motors.de/typo3conf/ext/main_configuration/Resources/Public/IMG/mitsubishi-logo.svg"
                    width="55"
                    height="60"
                />
            </a>
        </div>
    </div>
</div>
