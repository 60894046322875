import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'px-share-download-overlay',
  templateUrl: './share-download-overlay.component.html',
  styleUrls: ['./share-download-overlay.component.scss']
})
export class ShareDownloadOverlayComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<ShareDownloadOverlayComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {copyToClipBoard: void, shareLink: string}
  ) { }

  ngOnInit(): void {
  }

}
