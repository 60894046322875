import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { A00040PrimaryButtonIconType } from '../../../interfaces/atoms/a00040-primary-button-icon';

@Component({
    selector: 'px-a00040-primary-button-icon',
    templateUrl: './a00040-primary-button-icon.component.html',
    styleUrls: ['./a00040-primary-button-icon.component.scss']
})
export class A00040PrimaryButtonIconComponent implements OnInit {
    @Input() text: string;
    @Input() link: string;
    @Input() modifier: A00040PrimaryButtonIconType;
    @Input() disabled: boolean;
    @Input() type: string;
    @Output() clicked = new EventEmitter<void>();

    constructor() {}

    ngOnInit(): void {}
}
