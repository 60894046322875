import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00200SearchfieldComponent } from './a00200-searchfield.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [A00200SearchfieldComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        InlineSVGModule.forRoot(),
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [A00200SearchfieldComponent]
})
export class A00200SearchfieldModule {}
