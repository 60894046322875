import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'px-show-for-dev',
    templateUrl: './show-for-dev.component.html',
    styleUrls: ['./show-for-dev.component.scss']
})
export class ShowForDevComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
