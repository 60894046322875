import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';

@Pipe({
    name: 'formArrayCast',
    pure: true
})
export class FormArrayCastPipe implements PipeTransform {
    transform(value: AbstractControl): FormArray {
        return value as FormArray;
    }
}
