import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvkvValuesComponent } from './envkv-values.component';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
    declarations: [EnvkvValuesComponent],
    imports: [
        CommonModule,
        PipesModule
    ],
    exports: [
        EnvkvValuesComponent
    ]
})
export class EnvkvValuesModule {}
