<div class='row full-width' [ngStyle]="{'background-color': bgColor}">
    <div class='container o-130__container o-130__container--e o-130__textColor--{{textColor}}'
         (swipeleft)='nextSlide()'
         (swiperight)='prevSlide()'>
        <div class='o-130 o-130--e'>
            <div class='o-130__track' [ngStyle]='trackStyle'>
                <ng-container *ngFor='let slide of images'>
                    <div class='o-130__slide o-130--e__slide'>
                        <div class="m-0010 m-0010--{{slide.mode === 'video' ? 'c' : 'a'}}">
                            <div class='m-0010__image'>
                                <ng-container *ngIf="slide.mode === 'image'">
                                    <ng-container [ngTemplateOutlet]='image'
                                                  [ngTemplateOutletContext]='{ slide: slide }'></ng-container>
                                </ng-container>
                                <ng-container *ngIf="slide.mode === 'video'">
                                    <div class='a-00030 a-00030--c'>
                                        <ng-container *ngIf='slide.externalurl'>
                                            <a [href]='slide.externalurl'>
                                                <ng-container [ngTemplateOutlet]='prevImg'
                                                              [ngTemplateOutletContext]='{ slide: slide }'></ng-container>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf='!slide.externalurl'>
                                            <a class='cursor-pointer' (click)='openDialog(slide.youtubeid, slide.mp4)'>
                                                <ng-container [ngTemplateOutlet]='prevImg'
                                                              [ngTemplateOutletContext]='{ slide: slide }'></ng-container>
                                            </a>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]='prevArrow'></ng-container>
        <ng-container [ngTemplateOutlet]='nextArrow'></ng-container>
        <div class='o-130--s'>
            <div>
                <div class='socialMediaNameWrapper' *ngIf='name.length > 0'>
                    <div class='socialMediaName'>
                        <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' class='verifiedIcon'
                             viewBox='0 0 122.88 116.87' *ngIf='check === "1"'>
                            <polygon class='verifiedIconBackground'
                                     points='61.37 8.24 80.43 0 90.88 17.79 111.15 22.32 109.15 42.85 122.88 58.43 109.2
                                 73.87 111.15 94.55 91 99 80.43 116.87 61.51 108.62 42.45 116.87 32 99.08 11.73 94.55
                                  13.73 74.01 0 58.43 13.68 42.99 11.73 22.32 31.88 17.87 42.45 0 61.37 8.24 61.37 8.24' />
                            <path class='verifiedIconCheck'
                                  d='M37.92,65c-6.07-6.53,3.25-16.26,10-10.1,2.38,2.17,5.84,5.34,8.24,7.49L74.66,39.66C81.1,
                              33,91.27,42.78,84.91,49.48L61.67,77.2a7.13,7.13,0,0,1-9.9.44C47.83,73.89,42.05,68.5,37.92,65Z' />
                        </svg>
                        <span class='socialMediaNameText'>{{name}}</span>
                    </div>
                </div>
            </div>
            <div class='socialMediaButton'>
                <px-a00040-primary-button-icon
                    modifier='c'
                    [text]='buttonText'
                    [link]='buttonLink'
                ></px-a00040-primary-button-icon>
            </div>
        </div>
    </div>
</div>

<ng-template #prevImg let-slide='slide'>
    <ng-container [ngTemplateOutlet]='image' [ngTemplateOutletContext]='{ slide: slide }'></ng-container>
    <div class='icon'
         [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_player.svg'"></div>
</ng-template>

<ng-template #image let-slide='slide'>
    <img class='img-fluid mobile-only'
         src='{{slide.imageMobile}}'
         *ngIf='slide.imageMobile' alt=''>

    <img class='img-fluid '
         src='{{slide.imageTablet}}'
         *ngIf='slide.imageTablet'
         [ngClass]="{'tablet': slide.imageMobile, 'mobile': slide.imageMobile}" alt=''>

    <img class='img-fluid'
         src='{{slide.image}}'
         [ngClass]="{'desktop': slide.imageTablet , 'from-tablet': !slide.imageTablet && slide.imageMobile}" alt=''>
</ng-template>

<ng-template #prevArrow>
    <div class='o-130__sliderBtn o-130__sliderBtn--mobile o-130__sliderBtn--prev o-130__sliderBtn--prev-e'
         [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_half.svg'"
         (click)='prevSlide()'>
    </div>
    <div class='o-130__sliderBtn o-130__sliderBtn--prev o-130__sliderBtn--prev-e'
         [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_full.svg'"
         (click)='prevSlide()'>
    </div>
</ng-template>

<ng-template #nextArrow>
    <div class='o-130__sliderBtn o-130__sliderBtn--mobile o-130__sliderBtn--next o-130__sliderBtn--next-e'
         [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_half.svg'"
         (click)='nextSlide()'>
    </div>
    <div class='o-130__sliderBtn o-130__sliderBtn--next o-130__sliderBtn--next-e'
         [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_full.svg'"
         (click)='nextSlide()'>
    </div>
</ng-template>
