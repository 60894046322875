import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0180VideoTeaserComponent } from './m0180-video-teaser.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [M0180VideoTeaserComponent],
    imports: [CommonModule, PipesModule, InlineSVGModule.forRoot()],
    exports: [M0180VideoTeaserComponent]
})
export class M0180VideoTeaserModule {}
