import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O090MaterialComponent } from './o090-material.component';
import { M0110MediaTileModule } from '../../molecules/m0110-media-tile/m0110-media-tile.module';
import { M0020LinkListsModule } from '../../molecules/m0020-link-lists/m0020-link-lists.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { MatTabsModule } from '@angular/material/tabs';
import { A00050SecondaryButtonModule } from '../../atoms/a00050-secondary-button/a00050-secondary-button.module';

@NgModule({
    declarations: [O090MaterialComponent],
    imports: [
        CommonModule,
        M0110MediaTileModule,
        InlineSVGModule.forRoot(),
        MatTabsModule,
        M0020LinkListsModule,
        A00050SecondaryButtonModule
    ],
    exports: [O090MaterialComponent]
})
export class O090MaterialModule {}
