import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaCenterComponent } from './media-center.component';
import { T40MediacenterModule } from '../../templates/t40-mediacenter/t40-mediacenter.module';

@NgModule({
    declarations: [MediaCenterComponent],
    imports: [CommonModule, T40MediacenterModule]
})
export class MediaCenterModule {}
