import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { T40MediacenterComponent } from './t40-mediacenter.component';
import { O030FilterModule } from '../../organisms/o030-filter/o030-filter.module';
import { O080MaterialMediacenterModule } from '../../organisms/o080-material-mediacenter/o080-material-mediacenter.module';
import { O100ContactFooterModule } from '../../organisms/o100-contact-footer/o100-contact-footer.module';

@NgModule({
    declarations: [T40MediacenterComponent],
    imports: [
        CommonModule,
        O030FilterModule,
        O080MaterialMediacenterModule,
        O100ContactFooterModule
    ],
    exports: [T40MediacenterComponent]
})
export class T40MediacenterModule {}
