import { Component, OnInit, Input } from '@angular/core';

export interface VideoTeaser {
    videotype: string;
    size: string;
    headline?: string;
    headlineType?: string;
    ctaText?: string;
    ctaLink?: string;
    textColor?: string;
    mp4?: string;
    text?: string;
    youtubeid?: string;
    ctaType?: string;
    bgColor?: string;
}

@Component({
    selector: 'px-video-teaser',
    templateUrl: './video-teaser.component.html',
    styleUrls: ['./video-teaser.component.scss']
})
export class VideoTeaserComponent implements OnInit {
    content: VideoTeaser;

    @Input()
    set data(data: string) {
        this.content = JSON.parse(data);
    }

    constructor() {}

    ngOnInit(): void {}
}
