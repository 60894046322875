import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { addDays } from 'date-fns';
import { TermsOfUseOverlayComponent } from '../components/helpers/terms-of-use-overlay/terms-of-use-overlay.component';
import { MatDialog } from '@angular/material/dialog';
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TermsOfUseService {
    protected tosCookieName = 'tos-accepted';
    public tosOverlayClosed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private readonly dialog: MatDialog,
        private readonly cookie: CookieService
    ) {}

    isAccepted(): boolean {
        return this.cookie.check(this.tosCookieName);
    }

    accept() {
        this.cookie.set(this.tosCookieName, '1', addDays(new Date(), 1));
    }

    checkTos() {
        if (this.isAccepted()) {
            this.tosOverlayClosed.next(true);
            return;
        }

        const dialogRef = this.dialog.open(TermsOfUseOverlayComponent, {
            width: '760px'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.tosOverlayClosed.next(true);
                this.accept();
            }
            window.location.replace(
                window.location.pathname +
                    window.location.search +
                    window.location.hash
            );
        });
    }
}
