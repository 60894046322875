import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00080ToTopButtonComponent } from './a00080-to-top-button.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [A00080ToTopButtonComponent],
    imports: [CommonModule, HttpClientModule, InlineSVGModule.forRoot()],
    exports: [A00080ToTopButtonComponent]
})
export class A00080ToTopButtonModule {}
