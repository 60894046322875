import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'px-a00050-secondary-button',
    templateUrl: './a00050-secondary-button.component.html',
    styleUrls: ['./a00050-secondary-button.component.scss']
})
export class A00050SecondaryButtonComponent implements OnInit {
    @Input()
    text: string;

    @Input()
    target: string;

    @Input()
    link = '';

    @Input()
    iconPath =
        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/black.svg';

    @Output()
    clicked = new EventEmitter<void>();

    constructor() {}

    ngOnInit(): void {}

    linkClicked(): void {
        if (!this.link) {
            this.clicked.emit();
        }
    }
}
