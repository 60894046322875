import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-a00120-accordion',
    templateUrl: './a00120-accordion.component.html',
    styleUrls: ['./a00120-accordion.component.scss']
})
export class A00120AccordionComponent implements OnInit {
    @Input() headline: string;
    @Input() text: string;

    constructor() {}

    ngOnInit(): void {}
}
