<div class="m0010 m0010--{{ modifier }}">
    <div class="m0010__imgContainer">
        <img [src]="image" [alt]="altTitle" />
        <div
            *ngIf="modifier == 'a' || modifier == 'b'"
            class="m0010__headlineContainer"
        >
            <div class="m0010__headlines">
                <div *ngIf="headline" class="m0010__headline">
                    <h2 class="m0010__headlineFirst">{{ headline }}</h2>
                </div>
                <div class="clearfix"></div>
                <div
                    *ngIf="headlineSecond"
                    class="m0010__headline m0010__headlineSecond"
                >
                    <h2>{{ headlineSecond }}</h2>
                </div>
            </div>
            <div *ngIf="modifier == 'a'" class="m0010__ctas">
                <a class="m0010__link" href="{{ buttonFirstCta }}">
                    <px-a00040-primary-button-icon
                        modifier="c"
                        text="{{ buttonFirstText }}"
                    ></px-a00040-primary-button-icon>
                </a>
                <a class="m0010__link" href="{{ buttonSecondCta }}">
                    <px-a00040-primary-button-icon
                        modifier="c"
                        text="{{ buttonSecondText }}"
                    ></px-a00040-primary-button-icon>
                </a>
            </div>
        </div>
    </div>
</div>
