export enum A00040PrimaryButtonIconType {
    A = 'a',
    B = 'b',
    C = 'c'
}

export class A00040PrimaryButtonIcon {
    type: A00040PrimaryButtonIconType;
    text: string;
    link: string;
    disabled: boolean;
}
