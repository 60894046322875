import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00290FooterNavComponent } from './a00290-footer-nav.component';

@NgModule({
    declarations: [A00290FooterNavComponent],
    imports: [CommonModule],
    exports: [A00290FooterNavComponent]
})
export class A00290FooterNavModule {}
