<div class="container-fluid assetManager">
    <div class="filter row">
        <div class="col-12 uploadRow">
            <input
                #fileUploadE
                type="file"
                style="display: none"
                multiple
                (change)="onFileUpload($event)"
            />
            <button mat-raised-button (click)="fileUploadE.click()">
                Datei hochladen
            </button>
        </div>
        <div class="col-2">
            <mat-form-field class="dropDown">
                <mat-select [(ngModel)]="filter.model">
                    <mat-option [value]="0">Auswählen</mat-option>
                    <mat-option
                        *ngFor="let model of models"
                        [value]="model.uid"
                    >
                        {{ model.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field class="dropDown">
                <mat-select
                    [(value)]="filter.tag"
                    (openedChange)="tagFilterInput.value = ''; filterTags('')"
                >
                    <mat-form-field>
                        <input
                            #tagFilterInput
                            matInput
                            (keyup)="filterTags(tagFilterInput.value)"
                        />
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <mat-option [value]="0">Auswählen</mat-option>
                    <mat-option
                        *ngFor="let tag of filteredTags"
                        [value]="tag.uid"
                    >
                        {{ tag.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-2">
            <span class="datePick">Datum von</span>
            <mat-form-field class="datePicker">
                <mat-label>TT.MM.JJJJ</mat-label>
                <input
                    [(ngModel)]="filter.dateFrom"
                    matInput
                    [matDatepicker]="dateFrom"
                />
                <div matSuffix *ngIf="filter.dateFrom">
                    <mat-icon (click)="filter.dateFrom = null">clear</mat-icon>
                </div>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="dateFrom"
                ></mat-datepicker-toggle>
                <mat-datepicker #dateFrom></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-2">
            <span class="datePick">Datum bis</span>
            <mat-form-field class="datePicker">
                <mat-label>TT.MM.JJJJ</mat-label>
                <input
                    [(ngModel)]="filter.dateTo"
                    matInput
                    [matDatepicker]="dateTo"
                />
                <div matSuffix *ngIf="filter.dateTo">
                    <mat-icon (click)="filter.dateTo = null">clear</mat-icon>
                </div>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="dateTo"
                ></mat-datepicker-toggle>
                <mat-datepicker #dateTo></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-2 offset-2">
            <button mat-raised-button (click)="applyFilter()">
                Übernehmen
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div
                class="button-bar"
                *ngIf="selectedFiles.length > 0"
                fxLayout="row"
            >
                <div fxFlex="25">
                    <button
                        mat-icon-button
                        (click)="removeCategoriesFromSelected()"
                    >
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
                <div fxFlex="25">
                    <button mat-icon-button (click)="downloadSelected()">
                        <mat-icon>cloud_download</mat-icon>
                    </button>
                </div>
                <div fxFlex="25">
                    <button mat-icon-button (click)="editSelected()">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div fxFlex="25">
                    <button mat-icon-button (click)="deleteFiles()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
            <div>
                <div
                    class="file-list-header"
                    fxLayout="row"
                    fxLayoutAlign="space-between"
                >
                    <div class="col-md-5">Name</div>
                    <div class="col-md-5">Modell</div>
                    <div class="col-md-1">Größe</div>
                    <div class="col-md-1">Datum</div>
                </div>
                <div class="file-list">
                    <cdk-virtual-scroll-viewport
                        itemSize="80"
                        style="height: 600px"
                    >
                        <div
                            class="file"
                            *cdkVirtualFor="let file of filesDataSource"
                        >
                            <px-list-item
                                [file]="file"
                                [selected]="
                                    selectedFiles.indexOf(file.uid) !== -1
                                "
                                (selectedChange)="onSelect(file.uid, $event)"
                            ></px-list-item>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
        </div>
    </div>
</div>
