import { MenuEntry } from '../MenuEntry';

export enum O010MainNavType {
    A = 'a'
}

export interface O010MainNav {
    type: O010MainNavType;
    menu: MenuEntry[];
    metaMenu: MenuEntry[];
}
