import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0010KeyvisualComponent } from './m0010-keyvisual.component';
import { A00040PrimaryButtonIconModule } from '../../atoms/a00040-primary-button-icon/a00040-primary-button-icon.module';

@NgModule({
    declarations: [M0010KeyvisualComponent],
    imports: [CommonModule, A00040PrimaryButtonIconModule],
    exports: [M0010KeyvisualComponent]
})
export class M0010KeyvisualModule {}
