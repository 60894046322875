import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00220MediaCardComponent } from './a00220-media-card.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [A00220MediaCardComponent],
    imports: [CommonModule, InlineSVGModule.forRoot()],
    exports: [A00220MediaCardComponent]
})
export class A00220MediaCardModule {}
