import { Category } from './Category';
import {Image} from "../services/api.service";

export enum AssetType {
    IMAGE = 'image',
    VIDEO = 'video',
    OTHER = 'other'
}

export interface AssetFileVariant {
    uid: number;
    type: 'low' | 'thumbnail';
    size: number;
    width?: number;
    height?: number;
}

export interface AssetFile {
    uid: number;
    title: string;
    description: string;
    path?: string;
    date: number;
    size: number;
    type: AssetType;
    models: Category[];
    modelYears: Category[];
    tags: Category[];
    source: string;
    fileExtension: string;
    width?: number;
    height?: number;
    downloadCenterPreview?: Image;
    downloadCenterVariant?: number;
    downloadCenterLink?: string;
    downloadCenterLowResId?: number;
    variants?: AssetFileVariant[];
    crop?: {
        x1: number;
        y1: number;
        x2: number;
        y2: number;
    };
}

export interface UpdateFiles
    extends Omit<
        AssetFile,
        | 'width'
        | 'height'
        | 'variants'
        | 'uid'
        | 'path'
        | 'size'
        | 'type'
        | 'tags'
        | 'models'
        | 'modelYears'
        | 'date'
        | 'fileExtension'
    > {
    uids: number[];
    tags: number[];
    models: number[];
    modelYears: number[];
    date: number;
    removeVideoThumb: boolean;
}

export interface UploadFiles
    extends Omit<
        AssetFile,
        | 'width'
        | 'height'
        | 'variants'
        | 'uid'
        | 'path'
        | 'size'
        | 'type'
        | 'tags'
        | 'models'
        | 'modelYears'
        | 'date'
        | 'fileExtension'
    > {
    tags: number[];
    models: number[];
    modelYears: number[];
    date: number;
}
