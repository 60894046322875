<px-o130-social-media
    [buttonText]="content.buttonText"
    [buttonLink]="content.buttonLink"
    [name]="content.name"
    [check]="content.check"
    [bgColor]="content.bgColor"
    [textColor]="content.textColor"
    [images]="images"

>
