import { M0140ContactCardSmall } from '../molecules/m0140-contact-card-small';

export enum O100ContactFooterType {
    A = 'a'
}

export interface O100ContactFooter {
    type: O100ContactFooterType;
    teaserHeadline: string;
    teaserDescription: string;
    teaserLink: string;
    teaserLinkName: string;
    employees: M0140ContactCardSmall[];
}
