import { Component, Input } from '@angular/core';
import { A00320IconButton } from 'src/app/interfaces/atoms/a00320-icon-button';

@Component({
  selector: 'px-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.scss']
})
export class PodcastComponent {
    _links: A00320IconButton[] = []

    @Input() image: string;

    @Input()
    set links(str: string) {
        const data = JSON.parse(str);
        if (data) {
            for (const d of data) {
                this._links.push(this.buildLink(d));
            }
        }
    }

    buildLink(obj: {
        link: string;
        icon: string;
    }): A00320IconButton {
        return {
            link: obj.link,
            icon: obj.icon
        };
    }
}

