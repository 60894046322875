<div class="container" *ngIf="!complete">
    <div class="col-12">
        <h1 class="press-mailer__headline">
            Ihre Anmeldung
            <br />
            zum Presseverteiler
        </h1>
        <p class="press-mailer__subheadline">
            Neues zu Marke, Modellen und Angeboten – wir halten Sie mit unseren
            Pressemeldungen regelmäßig auf dem Laufenden.
        </p>
    </div>
</div>

<div class="container m0160">
    <div class="row">
        <ng-container *ngIf="complete">
            <div class="col-md-8 offset-md-2 m0160__wrapper">
                <h2 class="press-mailer__headline">Bitte noch ein Klick</h2>
                <p class="press-mailer__subheadline">
                    Vielen Dank für Ihr Interesse an unserem Presseverteiler,
                    Sie erhalten nun eine Bestätigungsmail.
                    <br />
                    <br />
                    Bitte klicken Sie auf den darin enthaltenen Link, um Ihr
                    Abonnement freizuschalten.
                    <br />
                    <br />
                    Bitte schauen Sie auch in Ihren Spam-Ordner, falls unsere
                    Bestätigungsmail nicht in Ihrem Posteingang ist.
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="!complete">
            <div class="col-md-8 offset-md-2 m0160__wrapper">
                <form id="pressmailer" [formGroup]="mailingListForm">
                    <div class="row">
                        <div class="col-md-6">
                            <px-a00170-input-title
                                [isSubmitted]="isSubmitted"
                                [inputControl]="salutation"
                                [titles]="(formEntries$ | async)?.salutations"
                                inputName="salutation"
                                error="Bitte wählen Sie eine Anrede."
                            ></px-a00170-input-title>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <px-a00180-input-text
                                [isSubmitted]="isSubmitted"
                                [inputControl]="firstName"
                                inputName="firstName"
                                [required]="true"
                                [placeholder]="'Vorname'"
                                error="Bitte überprüfen Sie den angebenen Namen."
                            ></px-a00180-input-text>
                        </div>
                        <div class="col-md-6">
                            <px-a00180-input-text
                                [isSubmitted]="isSubmitted"
                                [inputControl]="lastName"
                                inputName="lastName"
                                [required]="true"
                                [placeholder]="'Nachname'"
                                error="Bitte überprüfen Sie den angebenen Namen."
                            ></px-a00180-input-text>
                        </div>
                        <div class="col-md-6">
                            <px-a00180-input-text
                                [isSubmitted]="isSubmitted"
                                [inputControl]="medium"
                                inputName="medium"
                                [required]="true"
                                [placeholder]="'Verlag / Redaktion'"
                                error="Bitte überprüfen Sie das angegebene Medium."
                            ></px-a00180-input-text>
                        </div>
                    </div>
                    <div class="m0160__groupHeadline">
                        Kontaktdetails Verlag / Redaktion
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <px-a00180-input-text
                                [isSubmitted]="isSubmitted"
                                [inputControl]="street"
                                inputName="street"
                                [placeholder]="'Straße Hausnummer'"
                                error="Bitte überprüfen Sie die angegebene Straße."
                            ></px-a00180-input-text>
                        </div>
                        <div class="col-md-6">
                            <px-a00180-input-text
                                [isSubmitted]="isSubmitted"
                                [inputControl]="zip"
                                inputName="zip"
                                [placeholder]="'PLZ'"
                                error="Bitte überprüfen Sie die angegebene PLZ."
                            ></px-a00180-input-text>
                        </div>
                        <div class="col-md-6">
                            <px-a00180-input-text
                                [isSubmitted]="isSubmitted"
                                [inputControl]="city"
                                inputName="city"
                                [placeholder]="'Ort'"
                                error="Bitte überprüfen Sie den angegebenen Ort."
                            ></px-a00180-input-text>
                        </div>
                        <div class="col-md-6">
                            <px-a00180-input-text
                                [isSubmitted]="isSubmitted"
                                [inputControl]="email"
                                inputName="email"
                                [required]="true"
                                [placeholder]="'E-Mail'"
                                [error]="mailErrorText"
                            ></px-a00180-input-text>
                        </div>
                        <div class="col-md-6">
                            <px-a00180-input-text
                                [isSubmitted]="isSubmitted"
                                [inputControl]="phone"
                                inputName="phone"
                                [placeholder]="'Telefon'"
                                error="Bitte überprüfen Sie die eingegebene Telefonnummer."
                            ></px-a00180-input-text>
                        </div>
                    </div>
                    <p class="m0160__checkboxHeadline">
                        Verteilerthemen (Mehrfachauswahl möglich)
                    </p>
                    <div
                        class="m0160__checkboxList"
                        [formGroup]="contactGroups"
                        *ngIf="
                            (formEntries$ | async)
                                ?.contactGroups as contactGroupsEntry
                        "
                    >
                        <ng-container *ngFor="let group of contactGroupsEntry">
                            <px-a00210-input-checkbox
                                [inputControl]="
                                    contactGroups.get(group.id + '')
                                "
                                modifier="b"
                                [inputName]="group.id + ''"
                                [label]="group.text"
                            ></px-a00210-input-checkbox>
                        </ng-container>
                    </div>
                    <div
                        class="a00210__error"
                        *ngIf="
                            contactGroups.invalid &&
                            (contactGroups.dirty ||
                                contactGroups.touched ||
                                isSubmitted)
                        "
                    >
                        Bitte wählen Sie mindestens ein Thema aus.
                    </div>
                    <p class="m0160__disclaimerFirst">
                        Ihre personenbezogenen Daten (E-Mail Adresse, Name)
                        werden dazu zum Zwecke der Bearbeitung an die folgenden
                        Dienstleister übermittelt:
                    </p>
                    <p>
                        Wamtek Information Systems GmbH, Bahnstrasse 17a, 61381
                        Friedrichsdorf
                    </p>
                    <p>
                        Sie haben als Betroffener ein Recht auf unentgeltliche
                        Auskunft über Ihre gespeicherten Daten sowie ein Recht
                        auf Berichtigung, Sperrung oder Löschung dieser Daten.
                        Sie können die Einwilligung jederzeit gegenüber der
                        <strong>MMD Automobile GmbH</strong>
                        , Emil-Frey-Str. 2, 61169 Friedberg unter den im
                        Impressum angegebenen Kontaktadressen oder per E-Mail an
                        <a href="mailto:datenschutz@mitsubishi-motors.de">
                            datenschutz@mitsubishi-motors.de
                        </a>
                        ändern oder ganz oder teilweise widerrufen und der
                        Verarbeitung oder Nutzung der Daten für Zwecke der
                        Werbung oder der Markt- oder Meinungsforschung
                        widersprechen. Wenden Sie sich bei Fragen zum
                        Datenschutz bei
                        <strong>MMD Automobile GmbH</strong>
                        bitte an unseren Datenschutzbeauftragten. Weitere Infos
                        finden Sie
                        <a href="/datenschutz">hier</a>
                        .
                    </p>
                    <p>
                        Ich bin damit einverstanden, dass meine hier angegebenen
                        personenbezogenen Daten zum Zwecke der Versendung von
                        Pressemitteilungen gemäß Art. 6 Abs. 1a und Art. 13
                        DSGVO durch die
                        <strong>MMD Automobile GmbH</strong>
                        , 61169 Friedberg bis auf Widerruf gespeichert,
                        verarbeitet und genutzt werden.
                    </p>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="float-right m0160__requiredText">
                                (*) Bitte füllen Sie diese gekennzeichneten
                                Felder unbedingt aus.
                            </p>
                        </div>
                    </div>
                    <div class="float-right">
                        <div class="col-md-12">
                            <px-a00040-primary-button-icon
                                modifier="c"
                                text="Absenden"
                                (clicked)="onSubmit()"
                            ></px-a00040-primary-button-icon>
                        </div>
                    </div>
                    <p *ngIf="error">
                        {{ error }}
                    </p>
                </form>
            </div>
        </ng-container>
    </div>
</div>
