import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavComponent } from './main-nav.component';
import { O010MainNavModule } from '../../organisms/o010-main-nav/o010-main-nav.module';

@NgModule({
    declarations: [MainNavComponent],
    imports: [CommonModule, O010MainNavModule]
})
export class MainNavModule {}
