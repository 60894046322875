import { Component, OnInit, ViewChild } from '@angular/core';
import {
    ApiService,
    AssetFilter,
    NewsDataSource
} from '../../../services/api.service';
import { News } from '../../../interfaces/News';
import { MatDialog } from '@angular/material/dialog';
import { NewsEditComponent } from './news-edit/news-edit.component';
import { LoadingOverlayService } from '../../../services/loading-overlay.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ConfirmDialogComponent } from '../../helpers/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'px-news-manager',
    templateUrl: './news-manager.component.html',
    styleUrls: ['./news-manager.component.scss']
})
export class NewsManagerComponent implements OnInit {
    news: News[] = [];

    filter: AssetFilter = {
        model: 0,
        modelYear: 0,
        tag: 0,
        dateFrom: null,
        dateTo: null
    };

    newsDataSource: NewsDataSource;

    @ViewChild(CdkVirtualScrollViewport, { static: false })
    viewport: CdkVirtualScrollViewport;

    tstamp = Date.now();

    constructor(
        private readonly api: ApiService,
        private readonly dialog: MatDialog,
        private readonly overlay: LoadingOverlayService
    ) {
        (async () => {
            const id = this.overlay.openOverlay('Lade News Liste');
            this.newsDataSource = new NewsDataSource(this.api);
            this.applyNewsFilter();
            await this.resetNewsDataSource();
            this.overlay.closeOverlay(id);
        })();
    }

    ngOnInit(): void {}

    async resetNewsDataSource() {
        this.tstamp = Date.now();
        await this.newsDataSource.reset();
        this.viewport.setRenderedRange({
            start: 0,
            end: this.viewport.getRenderedRange().end + 1
        });
        this.viewport.checkViewportSize();
    }

    applyNewsFilter() {
        this.newsDataSource.setFilter(this.filter);
        this.resetNewsDataSource();
    }

    async addNewNews() {
        await this.openEditDialog(0);
    }

    async openEditDialog(uid: number) {
        const dialogRef = this.dialog.open(NewsEditComponent, {
            width: '980px',
            data: {
                uid,
                news: this.news
            }
        });

        dialogRef.afterClosed().subscribe(async (res) => {
            if (res) {
                await this.resetNewsDataSource();
            }
        });
    }

    async deleteNews(uid: number, headline: string) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '980px',
            data: `Wollen Sie wirklich die News "${headline}" löschen?`
        });

        dialogRef.afterClosed().subscribe(async (res) => {
            if (res) {
                try {
                    await this.api.removeNews(uid);
                    await this.resetNewsDataSource();
                } catch (e) {
                    alert('Beim löschen ist ein Fehler aufgetreten.');
                }
            }
        });
    }
}
