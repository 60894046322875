import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O030FilterComponent } from './o030-filter.component';
import { M0060TagSlideModule } from '../../molecules/m0060-tag-slide/m0060-tag-slide.module';
import { M0050FilterCardModule } from '../../molecules/m0050-filter-card/m0050-filter-card.module';

@NgModule({
    declarations: [O030FilterComponent],
    imports: [CommonModule, M0060TagSlideModule, M0050FilterCardModule],
    exports: [O030FilterComponent]
})
export class O030FilterModule {}
