import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {News} from "../../../interfaces/News";


@Component({
  selector: 'px-news-download-overlay',
  templateUrl: './news-download-overlay.component.html',
  styleUrls: ['./news-download-overlay.component.scss']
})
export class NewsDownloadOverlayComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NewsDownloadOverlayComponent>, @Inject(MAT_DIALOG_DATA) public data: {news: News}) {

  }

  ngOnInit(): void {
  }

}
