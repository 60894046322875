import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { AssetFile, AssetType } from '../../../../../interfaces/AssetFile';

@Component({
    selector: 'px-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit, OnChanges {
    @Input()
    selected = false;

    @Input()
    file: AssetFile;

    @Output()
    selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    AssetType = AssetType;

    tstamp = Date.now();

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges() {
        this.tstamp = Date.now();
    }
}
