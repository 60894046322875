import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00050SecondaryButtonComponent } from './a00050-secondary-button.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [A00050SecondaryButtonComponent],
    imports: [CommonModule, HttpClientModule, InlineSVGModule.forRoot()],
    exports: [A00050SecondaryButtonComponent]
})
export class A00050SecondaryButtonModule {}
