import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O070PressfolderComponent } from './o070-pressfolder.component';
import { M0100PressfolderModule } from '../../molecules/m0100-pressfolder/m0100-pressfolder.module';

@NgModule({
    declarations: [O070PressfolderComponent],
    exports: [O070PressfolderComponent],
    imports: [CommonModule, M0100PressfolderModule]
})
export class O070PressfolderModule {}
