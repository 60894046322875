<div class="m0070" *ngIf="tile">
    <div class="row">
        <div class="col-12 col-lg-9">
            <a [href]="tile.detailUrl" class="m0070__image-container">
                <img class="img-fluid" [src]="image" alt="" />
            </a>
        </div>
        <div class="col-12 col-lg-3">
            <span class="date">{{ tile.date | date: 'dd.MM.yyyy' }}</span>
            <!-- <div class="tags">
                <px-a00020-attribute
                    *ngFor="let tag of tile.tags"
                    [modifier]="tag.modifier ? tag.modifier : 'a'"
                    [text]="tag.text"
                ></px-a00020-attribute>
            </div> -->
            <a [href]="tile.detailUrl">
                <h2 class="m0070__headline">
                    {{ tile.headline | shorten: 136:'...':false }}
                </h2>
            </a>
            <p>{{ tile.copy }}</p>
            <a class="m0070__downloadAssets" *ngIf="tile.assets && tile.assets.length > 0"
               [matTooltip]="checkAllAssetsInDownloadCenter(tile.assets) ? 'Vom Downloadcenter entfernen' : 'Zum Downloadcenter hinzufügen'"
               matTooltipPosition="above"
               [matTooltipClass]="['tooltip-a00300', 'tooltip-a00300__big']"
               matTooltipTouchGestures="off"
               matTooltipHideDelay="500"
               (click)="addNewsToDownlodCenter(tile.assets)">
                <div [inlineSVG]="checkAllAssetsInDownloadCenter(tile.assets) ? '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_black.svg' : '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_border_black.svg'"></div>
            </a>
            <a [href]="tile.detailUrl">
                <px-a00110-forward-back-link
                    [modifier]="'b'"
                    [text]="'Weiterlesen'"
                ></px-a00110-forward-back-link>
            </a>
        </div>
    </div>
</div>
