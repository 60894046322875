<div class="file-list-header" fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex="30">Name</div>
    <div fxFlex="50">Modell</div>
    <div fxFlex="10">Größe</div>
    <div fxFlex="10">Datum</div>
</div>
<div class="file-list">
    <div class="file" *ngFor="let file of files">
        <px-list-item
            [file]="file"
            [selected]="selectedFiles.indexOf(file.uid) !== -1"
            (selectedChange)="onSelect(file.uid, $event)"
        ></px-list-item>
    </div>
</div>
