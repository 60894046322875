import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Pipe({
    name: 'formGroupCast',
    pure: true
})
export class FormGroupCastPipe implements PipeTransform {
    transform(value: AbstractControl): FormGroup {
        return value as FormGroup;
    }
}
