import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0120TextImageComponent } from './m0120-text-image.component';

@NgModule({
    declarations: [M0120TextImageComponent],
    imports: [CommonModule],
    exports: [M0120TextImageComponent]
})
export class M0120TextImageModule {}
