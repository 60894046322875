import { M0070Tile } from './../../../interfaces/molecules/m0070-newsteaser-tile';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { A00260TextLineIconDividerType } from 'src/app/interfaces/atoms/a00260-text-line-icon-divider';
import { A00070TertiaryButtonSmallType } from 'src/app/interfaces/atoms/a00070-tertiary-button-small';

@Component({
    selector: 'px-o060-tile-list-view',
    templateUrl: './o060-tile-list-view.component.html',
    styleUrls: ['./o060-tile-list-view.component.scss']
})
export class O060TileListViewComponent implements OnInit {
    @Input()
    newsteaserTiles: M0070Tile[];

    @Output()
    loadMore = new EventEmitter<void>();

    @Input()
    hasMore = true;

    @Input()
    isBlog = false;

    a00260TextLineIconDividerType: A00260TextLineIconDividerType;
    a00070TertiaryButtonSmallText: string;

    otherNewsMoreButtonTextOpen: string;
    otherNewsMoreButtonTextClose: string;
    a00070TertiaryButtonSmallType: A00070TertiaryButtonSmallType;

    constructor() {}

    ngOnInit(): void {
        this.otherNewsMoreButtonTextOpen = 'Mehr laden';
        this.otherNewsMoreButtonTextClose = 'Weniger anzeigen';
        this.a00070TertiaryButtonSmallText = this.otherNewsMoreButtonTextOpen;
        this.a00070TertiaryButtonSmallType = A00070TertiaryButtonSmallType.A;

        this.a00260TextLineIconDividerType = A00260TextLineIconDividerType.A;
    }

    toggleA00260TextLineIconDividerType() {
        this.a00260TextLineIconDividerType =
            this.a00260TextLineIconDividerType ===
            A00260TextLineIconDividerType.A
                ? A00260TextLineIconDividerType.B
                : A00260TextLineIconDividerType.A;
    }
}
