<div class="a00200">
    <input
        type="text"
        [name]="inputName"
        [id]="inputName"
        [placeholder]="placeholder"
        [formControl]="inputControl"
    />
    <label
        for="{{ inputName }}"
        [inlineSVG]="
            '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/details.svg'
        "
    ></label>
</div>
