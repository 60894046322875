<div class="m0020" [class]="'m0020--' + data.modifier">
    <div class="m0020__links">
        <px-a00130-underline-link
            *ngFor="let link of data.links"
            [href]="link.href"
            [target]="link.target"
            [active]="link.active"
        >
            {{ link.text }}
        </px-a00130-underline-link>
    </div>
</div>
