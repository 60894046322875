import { Component, Input, OnInit } from '@angular/core';
import { M0150ContactCardBig } from '../../../interfaces/molecules/m0150-contact-card-big';

@Component({
    selector: 'px-contact-big',
    templateUrl: './contact-big.component.html',
    styleUrls: ['./contact-big.component.scss']
})
export class ContactBigComponent implements OnInit {
    _employees: M0150ContactCardBig[] = [];

    @Input()
    set employees(employees: string | M0150ContactCardBig[]) {
        if (typeof employees === 'string') {
            this._employees = JSON.parse(employees);
        } else {
            this._employees = employees;
        }
    }

    get employees() {
        return this._employees;
    }
    constructor() {}

    ngOnInit(): void {}
}
