import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoOverlayComponent } from './video-overlay.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
    declarations: [VideoOverlayComponent],
    imports: [CommonModule, InlineSVGModule.forRoot(), PipesModule],
    exports: [VideoOverlayComponent]
})
export class VideoOverlayModule {}
