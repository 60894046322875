<div
    class="m0170__closing"
    (click)="dialogRef.close()"
    [inlineSVG]="
        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/close.svg'
    "
></div>
<div class="m0170">
    <ng-container *ngIf="data.type === M0170OverlayType.A">
        <div class="content">
            <div class="imgWrapper" *ngIf="!isMobile">
                <img [src]="image" [alt]="data.file.title" class="img-fluid" />
            </div>
            <div class="textContent">
                <p class="headline">
                    <strong>{{ data.file.title }}</strong>
                </p>
                <p>{{ data.file.description }}</p>
                <div *ngIf="downloadLink && !isMobile" class='btnWrapper'>
                    <px-a00050-secondary-button
                        (clicked)="toggleDownloadCenter()"
                        [text]="inDownloadCenter ? 'Gespeichert' : 'Speichern'"
                        [iconPath]="
                    inDownloadCenter ?
                     '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_black.svg' : '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_border_black.svg'
                    "
                    ></px-a00050-secondary-button>
                    <px-a00050-secondary-button
                        [link]="downloadLink"
                        [iconPath]="
                        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/download/white.svg'
                    "
                        text="Herunterladen"
                    ></px-a00050-secondary-button>
                </div>
            </div>
            <div class="imgWrapper" *ngIf="isMobile">
                <img [src]="image" [alt]="data.file.title" class="img-fluid" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p *ngIf="data.file.source" class="imgSource">
                    <strong>Fotograf / Quelle:</strong>
                    {{ data.file.source }}
                </p>
                <div *ngIf="downloadLink && isMobile" class='btnWrapper'>
                    <px-a00050-secondary-button
                        (clicked)="toggleDownloadCenter()"
                        [text]="inDownloadCenter ? 'Gespeichert' : 'Speichern'"
                        [iconPath]="
                        inDownloadCenter ?
                         '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_black.svg' : '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_border_black.svg'
                        "
                    ></px-a00050-secondary-button>
                    <px-a00050-secondary-button
                        [link]="downloadLink"
                        text="Herunterladen"
                        [iconPath]="
                            '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/download/white.svg'
                        "
                    ></px-a00050-secondary-button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
