import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0090NewsteaserTileBigComponent } from './m0090-newsteaser-tile-big.component';
import { A00020AttributeModule } from 'src/app/components/atoms/a00020-attribute/a00020-attribute.module';
import { A00110ForwardBackLinkModule } from 'src/app/components/atoms/a00110-forward-back-link/a00110-forward-back-link.module';
import { NgMathPipesModule, NgStringPipesModule } from 'ngx-pipes';
import { InlineSVGModule } from 'ng-inline-svg';
import {A00300TooltipModule} from "../../atoms/a00300-tooltip/a00300-tooltip.module";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [M0090NewsteaserTileBigComponent],
    imports: [
        CommonModule,
        A00020AttributeModule,
        NgMathPipesModule,
        A00110ForwardBackLinkModule,
        NgStringPipesModule,
        InlineSVGModule.forRoot(),
        A00300TooltipModule,
        MatTooltipModule
    ],
    exports: [M0090NewsteaserTileBigComponent]
})
export class M0090NewsteaserTileBigModule {}
