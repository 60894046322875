<div *ngIf="files.length > 0" class="row download-center__header">
    <div class="download-center__btnWrapper--clearAll download-center__desktop" *ngIf="shared === '0' && !isMobile">
        <a class="download-center__clearAllLink" (click)="clearAll()"><span class="download-center__clearAllIcon"
                                                                            [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/delete.svg'"></span>Alle
            entfernen</a>
    </div>

    <div class="download-center__btnWrapper--share" *ngIf="shared === '0'">
        <px-a00050-secondary-button
            (clicked)="openShareDialog()"
            text="Link Teilen"
            [iconPath]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/link.svg'"
        ></px-a00050-secondary-button>
    </div>

    <div class="download-center__btnWrapper--all">
        <px-a00050-secondary-button
            [link]="downloadAllLink"
            text="Alle herunterladen ( {{ filesTotalSize | async | bytes: 2 }} )"
            [iconPath]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/download/white.svg'"
        ></px-a00050-secondary-button>
    </div>

    <div class="download-center__btnWrapper--clearAll download-center__mobile" *ngIf="shared === '0' && isMobile">
        <a class="download-center__clearAllLink" (click)="clearAll()">
            <span class="download-center__clearAllIcon"
                  [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/delete.svg'"></span>
            Alle entfernen
        </a>
    </div>
</div>
<hr class="download-center__hr">

<div class="download-center__body">
    <div class="download-center__card" *ngFor="let file of files">
        <div class="download-center__card__image__mobile">
            <div class="download-center__card__image">
                <img src="{{ file.downloadCenterPreview.toString()}}" alt="{{ file.title }}" loading="lazy"/>
                <div class="download-center__card__image__icon" *ngIf="shared === '0'" (click)="removeItem(file.uid)"><span
                    [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/delete.svg'"></span>
                </div>
            </div>

            <div *ngIf="isMobile">
                <h2 class="download-center__card__description__headline">{{ file.title }}</h2>
                <p class="download-center__card__description__text">{{ getFileType(file.type) }}</p>
            </div>
        </div>
        <div class="download-center__card__description">
            <h2 class="download-center__card__description__headline" *ngIf="!isMobile">{{ file.title }}</h2>
            <p class="download-center__card__description__text" *ngIf="!isMobile">{{ getFileType(file.type) }}</p>
            <div class="download-center__card__description__tags">
                <ng-container *ngFor="let tag of file.tags">
                    <div>
                        <px-a00020-attribute [text]="tag.title" [modifier]="'a'"></px-a00020-attribute>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="download-center__card__download">
            <div class="download-center__card__download__select__container" *ngIf="file.downloadCenterLowResId">
                <div class="download-center__card__download__select">
                    <select [(ngModel)]="file.downloadCenterVariant"
                            (ngModelChange)="variantChange({file: file.uid, variant: file.downloadCenterVariant})">
                        <option value="0">High Res ({{ file.size | bytes: 2 }})</option>
                        <option [value]="file.downloadCenterLowResId">Low Res ({{ file.variants[0].size | bytes: 2 }})
                        </option>
                    </select>
                    <span
                        class="icon"
                        [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/black.svg'"
                    ></span>
                </div>
            </div>
            <div class="download-center__card__download__text" *ngIf="!file.downloadCenterLowResId">
                <p>{{ file.size | bytes: 2 }}</p>
            </div>
            <a *ngIf="file.downloadCenterLink" [href]="file.downloadCenterLink"
               class="download-center__card__download__link">
                <span
                    [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/download/white.svg'"></span>
            </a>
        </div>
    </div>
</div>
<div *ngIf="files.length === 0" class="download-center__body">
    <p>Sie haben aktuell keine Medien gespeichert</p>
</div>
