<div class="container last-news">
    <div class="row">
        <div class="col-12">
            <px-a00250-text-and-line-divider
                text="Letzte Meldungen"
            ></px-a00250-text-and-line-divider>
        </div>
        <div class="col-12 col-lg-4" *ngFor="let newsItem of tiles">
            <px-m0090-newsteaser-tile-big
                [tile]="newsItem"
            ></px-m0090-newsteaser-tile-big>
        </div>
    </div>
</div>
