import { Component, OnInit, Input } from '@angular/core';
import { MenuEntry } from '../../../interfaces/MenuEntry';

@Component({
    selector: 'px-m0030-navigation-button-list',
    templateUrl: './m0030-navigation-button-list.component.html',
    styleUrls: ['./m0030-navigation-button-list.component.scss']
})
export class M0030NavigationButtonListComponent implements OnInit {
    @Input() menuItems: MenuEntry[];

    constructor() {}

    ngOnInit(): void {}
}
