import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O120SliderComponent } from './o120-slider.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InlineSVGModule } from 'ng-inline-svg';
import { VideoOverlayModule } from '../../helpers/video-overlay/video-overlay.module';

@NgModule({
    declarations: [O120SliderComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        InlineSVGModule.forRoot(),
        VideoOverlayModule
    ],
    exports: [O120SliderComponent]
})
export class O120SliderModule {}
