import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0170OverlayComponent } from './m0170-overlay.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { A00050SecondaryButtonModule } from '../../atoms/a00050-secondary-button/a00050-secondary-button.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
    declarations: [M0170OverlayComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        InlineSVGModule.forRoot(),
        FlexLayoutModule,
        A00050SecondaryButtonModule,
        LayoutModule
    ],
    exports: [M0170OverlayComponent]
})
export class M0170OverlayModule {}
