import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { M0070Tile } from 'src/app/interfaces/molecules/m0070-newsteaser-tile';
import {DownloadCenterService} from "../../../services/download-center.service";

@Component({
    selector: 'px-m0090-newsteaser-tile-big',
    templateUrl: './m0090-newsteaser-tile-big.component.html',
    styleUrls: ['./m0090-newsteaser-tile-big.component.scss']
})
export class M0090NewsteaserTileBigComponent implements OnInit, OnChanges {
    @Input()
    tile: M0070Tile;

    image = '';

    constructor(private downloadCenterService: DownloadCenterService) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tile && this.tile) {
            this.image = this.tile.img.toUrl(0, 420);
        }
    }

    addNewsToDownlodCenter(news: number[]) {
        this.downloadCenterService.addNewsToDownlodCenter(news);
    }

    checkAllAssetsInDownloadCenter(news: number[]): boolean {
        return this.downloadCenterService.checkAssetsArray(news);
    }
}
