import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O020SubnavigationTextLinkComponent } from './o020-subnavigation-text-link.component';
import { M0020LinkListsModule } from '../../molecules/m0020-link-lists/m0020-link-lists.module';
import { A00160ExpanderModule } from '../../atoms/a00160-expander/a00160-expander.module';
import { InlineSVGModule } from 'ng-inline-svg';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [O020SubnavigationTextLinkComponent],
    imports: [CommonModule, M0020LinkListsModule, A00160ExpanderModule, InlineSVGModule, MatTooltipModule],
    exports: [O020SubnavigationTextLinkComponent]
})
export class O020SubnavigationTextLinkModule {}
