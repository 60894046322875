<div class="o110 o110--{{ modifier }}">
    <px-m0150-contact-card-big
        *ngFor="let employee of employees"
        [forename]="employee.forename"
        [surname]="employee.surname"
        [jobTitle]="employee.jobTitle"
        [phone]="employee.phone"
        [mobile]="employee.mobile"
        [email]="employee.email"
        [image]="employee.image"
        [socials]="employee.socials"
    ></px-m0150-contact-card-big>
</div>
