import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00160ExpanderComponent } from './a00160-expander.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [A00160ExpanderComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        InlineSVGModule.forRoot(),
        NgbCollapseModule
    ],
    exports: [A00160ExpanderComponent]
})
export class A00160ExpanderModule {}
