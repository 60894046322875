import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowForDevComponent } from './show-for-dev.component';
import { T10NewsTileListModule } from '../../templates/t10-news-tile-list/t10-news-tile-list.module';

@NgModule({
    providers: [],
    declarations: [ShowForDevComponent],
    imports: [CommonModule, T10NewsTileListModule],
    exports: [ShowForDevComponent]
})
export class ShowForDevModule {}
