import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef
} from '@angular/core';

@Component({
    selector: 'px-a00160-expander',
    templateUrl: './a00160-expander.component.html',
    styleUrls: ['./a00160-expander.component.scss']
})
export class A00160ExpanderComponent implements OnInit {
    @Input() modifier: string;
    @Input() text: string;

    @Output()
    isOpen: EventEmitter<{
        collapse: ElementRef;
        collapseButton: ElementRef;
        open: boolean;
    }> = new EventEmitter();

    @ViewChild('collapse') collapse: ElementRef;
    @ViewChild('collapseButton') collapseButton: ElementRef;

    public isCollapsed = true;
    public expanderId: string = this.text + '-a00160-' + this.modifier;

    constructor() {}

    ngOnInit(): void {}

    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
        if (!this.isCollapsed) {
            const elementObject = {
                collapse: this.collapse,
                collapseButton: this.collapseButton,
                open: true
            };
            this.isOpen.emit(elementObject);
        } else {
            const elementObject = {
                collapse: this.collapse,
                collapseButton: this.collapseButton,
                open: false
            };
            this.isOpen.emit(elementObject);
        }
    }
}
