import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0190ContentTeaserComponent } from './m0190-content-teaser.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [M0190ContentTeaserComponent],
    imports: [CommonModule, InlineSVGModule.forRoot()],
    exports: [M0190ContentTeaserComponent]
})
export class M0190ContentTeaserModule {}
