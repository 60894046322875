<h1 mat-dialog-title>Bestätigen</h1>
<div mat-dialog-content>
    <p>{{ data }}</p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="dialogRef.close(true)">
        Ja
    </button>
    <button mat-raised-button (click)="dialogRef.close(false)">Nein</button>
</div>
