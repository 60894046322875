import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O080MaterialMediacenterComponent } from './o080-material-mediacenter.component';
import { M0110MediaTileModule } from '../../molecules/m0110-media-tile/m0110-media-tile.module';
import { A00070TertiaryButtonSmallModule } from '../../atoms/a00070-tertiary-button-small/a00070-tertiary-button-small.module';

@NgModule({
    declarations: [O080MaterialMediacenterComponent],
    imports: [
        CommonModule,
        M0110MediaTileModule,
        A00070TertiaryButtonSmallModule
    ],
    exports: [O080MaterialMediacenterComponent]
})
export class O080MaterialMediacenterModule {}
