import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialMediaComponent } from './social-media.component';
import { M0010KeyvisualModule } from '../../molecules/m0010-keyvisual/m0010-keyvisual.module';
import { O130SocialMediaModule } from '../../organisms/o130-social-media/o130-social-media.module';

@NgModule({
    declarations: [SocialMediaComponent],
    exports: [
        SocialMediaComponent
    ],
    imports: [CommonModule, M0010KeyvisualModule, O130SocialMediaModule]
})
export class SocialMediaModule {}
