export enum A00150ContactLinksType {
    A = 'a',
    B = 'b',
    C = 'c',
    D = 'd'
}

export interface A00150ContactLinks {
    type: A00150ContactLinksType;
    email: string;
    phone: string;
}
