<div class="a00170__label">Anrede*</div>
<div class="a00170">
    <ng-container *ngFor="let title of titles; last as isLast">
        <label class="radio">
            <input
                type="radio"
                [formControl]="inputControl"
                [name]="inputName"
                [value]="title.id"
                [class.hasError]="
                    inputControl.invalid &&
                    (inputControl.dirty || inputControl.touched || isSubmitted)
                "
            />
            <div class="radioBtn">{{ title.text }}</div>
        </label>
        <div *ngIf="!isLast" class="line"></div>
    </ng-container>
    <!-- <label class="radio">
        <input type="radio" name="title" value="Herr">
        <div class="radioBtn">Herr</div>
    </label>
    <div class="line"></div>
    <label class="radio">
        <input type="radio" name="title" value="Frau">
        <div class="radioBtn">Frau</div>
    </label>
    <div class="line"></div>
    <label class="radio">
        <input type="radio" name="title" value="Divers">
        <div class="radioBtn">Divers</div>
    </label> -->
</div>
<div
    class="a00170__error"
    *ngIf="
        inputControl.invalid &&
        (inputControl.dirty || inputControl.touched || isSubmitted)
    "
>
    {{ error }}
</div>
