import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0110MediaTileComponent } from './m0110-media-tile.component';
import { A00220MediaCardModule } from '../../atoms/a00220-media-card/a00220-media-card.module';
import { A00100DownloadButtonModule } from '../../atoms/a00100-download-button/a00100-download-button.module';

@NgModule({
    declarations: [M0110MediaTileComponent],
    imports: [CommonModule, A00220MediaCardModule, A00100DownloadButtonModule],
    exports: [M0110MediaTileComponent]
})
export class M0110MediaTileModule {}
