<div class="o010 o010--{{ modifier }}">
    <header>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <nav class="main-navigation navbar">
                        <ul class="nav navbar-nav">
                            <li *ngFor="let item of menu" class="menuitem">
                                <a
                                    [href]="item.link"
                                    [attr.target]="item.target"
                                    [class.active]="item.active"
                                >
                                    {{ item.name }}
                                </a>
                            </li>
                            <li class="menuitem__metaNav">
                                <div class="menuitem__metaNavWrapper">
                                    <ul>
                                        <li
                                            *ngFor="let item of metaMenu"
                                            class="menuitem"
                                        >
                                            <a
                                                [href]="item.link"
                                                [attr.target]="item.target"
                                                [class.active]="item.active"
                                            >
                                                {{ item.name }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li class="menuitem menuitem--download">
                                <a [href]="downloadCenter">
                                    <span class='icon'
                                          [inlineSVG]="downloadCenterService.countItems() > 0 ? '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_black.svg' : '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_border_black.svg'"></span>
                                    <span class='download-count' *ngIf="downloadCenterService.countItems() > 0"> {{ downloadCenterService.countItems() }} </span>
                                </a>
                            </li>
                        </ul>

                    </nav>
                </div>
            </div>
            <px-a00010-header-logo></px-a00010-header-logo>
        </div>
    </header>

    <div id="mobile-nav" class="mobileNav">
        <div
            id="nav-content"
            [ngClass]="openMobileNav ? '' : 'd-none'"
            class="container-fluid"
        >
            <div class="row">
                <div class="col-md-12">
                    <div class="container navContainer">
                        <div class="row">
                            <px-m0030-navigation-button-list
                                [menuItems]="mobileMenu"
                            ></px-m0030-navigation-button-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="mobile-nav-bar" class="mobileNav__container">
            <div class="mobileNav__itemWrapper"
                 *ngFor="let item of specialMobileMenu"
                 [class.mobileNav__itemWrapper--contact]="item.mobileSpecialIcon === 'contact'">
                <a class="mobileNav__item" [href]="item.link" [attr.target]="item.target">
                    <div
                        *ngIf="item.mobileSpecialIcon"
                        class="icon {{ item.mobileSpecialIcon }}"
                    ></div>
                    <span
                        class="navDesc"
                    >
                        {{ item.name }}
                    </span>
                </a>
            </div>
            <div class="mobileNav__itemWrapper">
                <div class="mobileNav__item" (click)="toggleNav()">
                    <div
                        class="icon mobileNav__menuIcon"
                        [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/burger-nav.svg'"
                    ></div>
                    <span class="navDesc">Menü</span>
                </div>
            </div>
            <div class="mobileNav__itemWrapper mobileNav__itemWrapper--downloadCenter">
                <div class="mobileNav__item">
                    <a [href]="downloadCenter">
                        <div>
                            <div class="assets">
                                <span class='icon'
                                      [inlineSVG]="downloadCenterService.countItems() > 0 ? '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_black.svg' : '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_border_black.svg'"></span>
                                <span class='download-count' *ngIf="downloadCenterService.countItems() > 0" [class.download-count-small]="downloadCenterService.countItems() > 99"> {{ downloadCenterService.countItems() }} </span>
                            </div>
                        </div>
                        <span class="navDesc">Medien</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
