import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
    selector: 'px-a00170-input-title',
    templateUrl: './a00170-input-title.component.html',
    styleUrls: ['./a00170-input-title.component.scss']
})
export class A00170InputTitleComponent implements OnInit {
    @Input() inputName: string;
    @Input() isSubmitted = true;
    @Input() inputControl: AbstractControl;
    @Input() titles: [{ text: string; id: string | number }];
    @Input() error = '';

    constructor(public control: ControlContainer) {}

    ngOnInit(): void {}
}
