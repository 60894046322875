import { Component, Input, OnInit } from '@angular/core';
import { MenuEntry } from '../../../interfaces/MenuEntry';

@Component({
    selector: 'px-footer-nav',
    templateUrl: './footer-nav.component.html',
    styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {
    _menu: MenuEntry[];

    @Input()
    set menu(menu: string | MenuEntry[]) {
        if (typeof menu === 'string') {
            this._menu = JSON.parse(menu);
        } else {
            this._menu = menu;
        }
    }

    constructor() {}

    ngOnInit(): void {}
}
