<div class="a00070" (click)="onClick()">
    <span class="text">{{ text }}</span>
    <span
        class="arrow"
        [class.arrow--rotated]="modifier === 'b'"
        [inlineSVG]="
            '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/black.svg'
        "
    ></span>
</div>
