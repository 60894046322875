import { Component, Input } from '@angular/core';
import {A00320IconButton} from '../../../interfaces/atoms/a00320-icon-button';

@Component({
  selector: 'px-m0180-podcast',
  templateUrl: './m0180-podcast.component.html',
  styleUrls: ['./m0180-podcast.component.scss']
})
export class M0180PodcastComponent {
    @Input() image: string;
    @Input() links: A00320IconButton[];
}
