import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00040PrimaryButtonIconComponent } from './a00040-primary-button-icon.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [A00040PrimaryButtonIconComponent],
    imports: [CommonModule, HttpClientModule, InlineSVGModule.forRoot()],
    exports: [A00040PrimaryButtonIconComponent]
})
export class A00040PrimaryButtonIconModule {}
