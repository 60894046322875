import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0230TextVideoComponent } from './m0230-text-video.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [M0230TextVideoComponent],
    imports: [CommonModule, PipesModule, InlineSVGModule.forRoot()],
    exports: [M0230TextVideoComponent]
})
export class M0230TextVideoModule {}
