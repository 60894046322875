import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterNavComponent } from './footer-nav.component';
import { A00290FooterNavModule } from '../../atoms/a00290-footer-nav/a00290-footer-nav.module';

@NgModule({
    declarations: [FooterNavComponent],
    imports: [CommonModule, A00290FooterNavModule]
})
export class FooterNavModule {}
