import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'px-a00200-searchfield',
    templateUrl: './a00200-searchfield.component.html',
    styleUrls: ['./a00200-searchfield.component.scss']
})
export class A00200SearchfieldComponent implements OnInit {
    @Input() placeholder: string;
    @Input() inputName: string;
    @Input() inputControl: FormControl;

    constructor() {}

    ngOnInit(): void {}
}
