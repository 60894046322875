<px-m0190-content-teaser
[image]="content.image"
[responsiveImages]="content.responsiveImages"
[headline]="content.headline"
[headlineType]="content.headlineType"
[text]="content.text"
[ctaText]="content.ctaText"
[ctaLink]="content.ctaLink"
[ctaSuperscript]="content.ctaSuperscript"
[textColor]="content.textColor"
[bgColor]="content.bgColor"
[modifier]="content.modifier"
[position]="content.position"
></px-m0190-content-teaser>
