import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'px-a00270-scrollbar',
    templateUrl: './a00270-scrollbar.component.html',
    styleUrls: ['./a00270-scrollbar.component.scss']
})
export class A00270ScrollbarComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
