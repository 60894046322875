import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00310AssetSavedComponent } from './a00310-asset-saved.component';
import {InlineSVGModule} from "ng-inline-svg";



@NgModule({
  declarations: [A00310AssetSavedComponent],
    imports: [
        CommonModule,
        InlineSVGModule
    ]
})
export class A00310AssetSavedModule { }
