import { Component, Input, OnInit } from '@angular/core';
import { M0150ContactCardBig } from '../../../interfaces/molecules/m0150-contact-card-big';
import { O110ContactBigType } from '../../../interfaces/organisms/o110-contact-big';

@Component({
    selector: 'px-o110-contact-big',
    templateUrl: './o110-contact-big.component.html',
    styleUrls: ['./o110-contact-big.component.scss']
})
export class O110ContactBigComponent implements OnInit {
    @Input() modifier: O110ContactBigType = O110ContactBigType.A;

    @Input() employees: M0150ContactCardBig[] = [];

    constructor() {}

    ngOnInit(): void {}
}
