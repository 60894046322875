import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0070NewsteaserTileComponent } from './m0070-newsteaser-tile.component';
import { A00020AttributeModule } from '../../atoms/a00020-attribute/a00020-attribute.module';
import { A00110ForwardBackLinkModule } from './../../atoms/a00110-forward-back-link/a00110-forward-back-link.module';
import { NgMathPipesModule, NgStringPipesModule } from 'ngx-pipes';
import { InlineSVGModule } from 'ng-inline-svg';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [M0070NewsteaserTileComponent],
    imports: [
        CommonModule,
        A00020AttributeModule,
        A00110ForwardBackLinkModule,
        NgMathPipesModule,
        NgStringPipesModule,
        InlineSVGModule.forRoot(),
        MatTooltipModule
    ],
    exports: [M0070NewsteaserTileComponent]
})
export class M0070NewsteaserTileModule {}
