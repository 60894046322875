import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoTeaserComponent } from './video-teaser.component';
import { M0180VideoTeaserModule } from '../../molecules/m0180-video-teaser/m0180-video-teaser.module';

@NgModule({
    declarations: [VideoTeaserComponent],
    imports: [CommonModule, M0180VideoTeaserModule],
    exports: [VideoTeaserComponent]
})
export class VideoTeaserModule {}
