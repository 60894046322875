import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextImageComponent } from './text-image.component';
import { M0120TextImageModule } from '../../molecules/m0120-text-image/m0120-text-image.module';

@NgModule({
    declarations: [TextImageComponent],
    imports: [CommonModule, M0120TextImageModule]
})
export class TextImageModule {}
