import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CroppedAreaOverlayComponent } from './cropped-area-overlay.component';

@NgModule({
    declarations: [CroppedAreaOverlayComponent],
    imports: [CommonModule],
    exports: [CroppedAreaOverlayComponent]
})
export class CroppedAreaOverlayModule {}
