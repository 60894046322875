import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    transform(value: string): SafeResourceUrl | null {
        if (value) {
            return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
        } else {
            return null;
        }
    }
}
