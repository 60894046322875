import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00300TooltipComponent } from './a00300-tooltip.component';



@NgModule({
    declarations: [A00300TooltipComponent],
    exports: [
        A00300TooltipComponent
    ],
    imports: [
        CommonModule
    ]
})
export class A00300TooltipModule { }
