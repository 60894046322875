import {Component, OnInit} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {ApiService} from 'src/app/services/api.service';
import {Observable} from 'rxjs';
import {FormEntries} from 'src/app/interfaces/Wamtek/FormEntries';
import {share, tap} from 'rxjs/operators';
import {ErrorStateMatcher} from '@angular/material/core';
import {RegexLib} from '@carmato/px-validation';
import {FormSubmit} from 'src/app/interfaces/Wamtek/FormSubmit';

export class PXErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

export enum PressMailerResponse {
    duplicateEmail = 'duplicateEmail',
    mandatoryFieldMissing = 'mandatoryFieldMissing',
    duplicateUsername = 'duplicateUsername',
    apiError = 'apiError'
}

@Component({
    selector: 'px-press-mailer',
    templateUrl: './press-mailer.component.html',
    styleUrls: ['./press-mailer.component.scss']
})
export class PressMailerComponent implements OnInit {
    public matcher = new PXErrorStateMatcher();

    public error = '';
    public mailErrorText =
        'Bitte überprüfen Sie die angegebene E-Mail Adresse.';

    public complete = false;

    public isSubmitted = false;

    public formEntries$: Observable<FormEntries>;

    public mailingListForm = new FormGroup({
        salutation: new FormControl(null, [Validators.required]),
        contactGroups: new FormGroup({}, [atLeastOneCheckboxChecked()]),
        firstName: new FormControl('', [
            Validators.required,
            Validators.pattern(/^[A-Za-zäüöÄÜÖßéáúÀÈÙ.\-'´` ]+$/)
        ]),
        lastName: new FormControl('', [
            Validators.required,
            Validators.pattern(/^[A-Za-zäüöÄÜÖßéáúÀÈÙ.\-'´` ]+$/)
        ]),
        medium: new FormControl('', [
            Validators.required,
            Validators.pattern(/^[A-Za-zäüöÄÜÖßéáúÀÈÙ.\-'´` ]+$/)
        ]),
        street: new FormControl('', [Validators.pattern(RegexLib.Street._)]),
        zip: new FormControl('', [
            Validators.pattern(/^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/)
        ]),
        city: new FormControl('', [Validators.pattern(RegexLib.City._)]),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl('', [Validators.pattern(/^[+]?[0-9 -]{1,20}$/)])
    });

    constructor(private api: ApiService) {
    }

    get contactGroups(): FormGroup {
        return this.mailingListForm.get('contactGroups') as FormGroup;
    }

    get salutation(): AbstractControl {
        return this.mailingListForm.get('salutation');
    }

    get firstName(): AbstractControl {
        return this.mailingListForm.get('firstName');
    }

    get lastName(): AbstractControl {
        return this.mailingListForm.get('lastName');
    }

    get medium(): AbstractControl {
        return this.mailingListForm.get('medium');
    }

    get street(): AbstractControl {
        return this.mailingListForm.get('street');
    }

    get zip(): AbstractControl {
        return this.mailingListForm.get('zip');
    }

    get city(): AbstractControl {
        return this.mailingListForm.get('city');
    }

    get email(): AbstractControl {
        return this.mailingListForm.get('email');
    }

    get phone(): AbstractControl {
        return this.mailingListForm.get('phone');
    }

    ngOnInit(): void {
        this.formEntries$ = this.api.getFormEntries().pipe(
            tap((formEntries) => {
                const contactGroups = formEntries.contactGroups;
                contactGroups.forEach((element) => {
                    this.contactGroups.addControl(
                        '' + element.id,
                        new FormControl(true)
                    );
                });
            }),
            share()
        );
    }

    public async onSubmit(): Promise<void> {
        this.isSubmitted = true;
        if (this.mailingListForm.invalid) {
            return;
        }
        this.error = '';
        this.mailErrorText =
            'Bitte überprüfen Sie die angegebene E-Mail Adresse.';
        const data = this.mailingListForm.getRawValue() as FormSubmit;
        const tmp: number[] = [];

        for (const [key, value] of Object.entries(this.contactGroups.value)) {
            if (value) {
                tmp.push(parseInt(key, 10));
            }
        }

        data.contactGroups = tmp;
        try {
            const res = await this.api.postSubmitPressMailerForm(data);

            if (res.length < 1) {
                this.complete = true;
            } else {
                for (const error of res) {
                    switch (error) {
                        case PressMailerResponse.apiError:
                            this.error =
                                'Es ist ein technischer Fehler aufgetreten. Bitte haben Sie ein wenig Geduld und versuchen Sie es später noch einmal.';
                            break;
                        case PressMailerResponse.duplicateEmail:
                            this.mailErrorText =
                                'Die E-Mail Adresse ist bereits registriert.';
                            this.email.setErrors({duplicateEmail: true});
                            break;
                        case PressMailerResponse.mandatoryFieldMissing:
                            this.error =
                                'Bitte füllen Sie alle *Pflichtfelder aus.';
                            break;
                        case PressMailerResponse.duplicateUsername:
                            this.error =
                                'Der Nutzername ist bereits registriert.';
                            break;
                        default:
                            break;
                    }
                }
            }
        } catch (e) {
            this.error =
                'Es ist etwas schief gegangen, bitte versuchen Sie es später erneut.';
        }
    }
}

function atLeastOneCheckboxChecked(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
        let checked = 0;

        Object.keys(formGroup.controls).forEach((key) => {
            const control = formGroup.controls[key];

            if (control.value) {
                checked++;
            }
        });

        if (checked < minRequired) {
            return {
                requireCheckboxToBeChecked: true
            };
        }

        return null;
    };
}
