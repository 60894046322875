import {Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'px-m0240-audio-player',
    templateUrl: './m0240-audio-player.html',
    styleUrls: ['./m0240-audio-player.component.scss']
})
export class M0240AudioPlayerComponent implements OnInit {
    @Input() headline: string;
    @Input() text: string;
    @Input() image: string;
    @Input() file: string;
    @ViewChild('audioPlayer') audioPlayer: ElementRef;

    constructor() {
    }

    ngOnInit(): void {
    }
}
