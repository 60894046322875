import { Component, Input, OnInit } from '@angular/core';
import { A00150ContactLinksType } from '../../../interfaces/atoms/a00150-contact-links';

@Component({
    selector: 'px-a00150-contact-links',
    templateUrl: './a00150-contact-links.component.html',
    styleUrls: ['./a00150-contact-links.component.scss']
})
export class A00150ContactLinksComponent implements OnInit {
    @Input() modifier: A00150ContactLinksType = A00150ContactLinksType.A;
    @Input() phone: string;
    @Input() email: string;

    A00150ContactLinksType = A00150ContactLinksType;

    constructor() {}

    ngOnInit(): void {}
}
