import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'px-a00070-tertiary-button-small',
    templateUrl: './a00070-tertiary-button-small.component.html',
    styleUrls: ['./a00070-tertiary-button-small.component.scss']
})
export class A00070TertiaryButtonSmallComponent implements OnInit {
    @Input() text: string;
    @Input() modifier: string;

    @Output()
    clickA00070TertiaryButtonSmall: EventEmitter<void> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        console.log('tertiary Button modifier', this.modifier);
    }

    onClick() {
        this.clickA00070TertiaryButtonSmall.emit();
    }
}
