import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsDetailComponent } from './news-detail.component';
import { M0010KeyvisualModule } from '../../molecules/m0010-keyvisual/m0010-keyvisual.module';
import { O020SubnavigationTextLinkModule } from '../../organisms/o020-subnavigation-text-link/o020-subnavigation-text-link.module';
import { A00020AttributeModule } from '../../atoms/a00020-attribute/a00020-attribute.module';
import { A00250TextAndLineDividerModule } from '../../atoms/a00250-text-and-line-divider/a00250-text-and-line-divider.module';
import { O090MaterialModule } from '../../organisms/o090-material/o090-material.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { M0090NewsteaserTileBigModule } from '../../molecules/m0090-newsteaser-tile-big/m0090-newsteaser-tile-big.module';
import { M0070NewsteaserTileModule } from '../../molecules/m0070-newsteaser-tile/m0070-newsteaser-tile.module';
import { M0130AccordeonModule } from '../../molecules/m0130-accordeon/m0130-accordeon.module';
import { PressfolderModule } from '../pressfolder/pressfolder.module';
import { ContactFooterModule } from '../contact-footer/contact-footer.module';
import { ContactBigModule } from '../contact-big/contact-big.module';
import { VideoTeaserModule } from '../video-teaser/video-teaser.module';
import { M0180VideoTeaserModule } from '../../molecules/m0180-video-teaser/m0180-video-teaser.module';
import { M0240AudioPlayerModule } from '../../molecules/m0240-audio-player/m0240-audio-player.module';
import { M0120TextImageModule } from '../../molecules/m0120-text-image/m0120-text-image.module';
import { M0230TextVideoModule } from '../../molecules/m0230-text-video/m0230-text-video.module';
import { O130SocialMediaModule } from '../../organisms/o130-social-media/o130-social-media.module';
import { SocialMediaModule } from '../social-media/social-media.module';
import { EnvkvValuesModule } from '../envkv-values/envkv-values.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
    declarations: [NewsDetailComponent],
    imports: [
        CommonModule,
        M0010KeyvisualModule,
        O020SubnavigationTextLinkModule,
        A00020AttributeModule,
        A00250TextAndLineDividerModule,
        O090MaterialModule,
        MatExpansionModule,
        M0090NewsteaserTileBigModule,
        M0070NewsteaserTileModule,
        M0130AccordeonModule,
        PressfolderModule,
        ContactFooterModule,
        ContactBigModule,
        VideoTeaserModule,
        M0180VideoTeaserModule,
        M0240AudioPlayerModule,
        M0120TextImageModule,
        M0230TextVideoModule,
        O130SocialMediaModule,
        SocialMediaModule,
        EnvkvValuesModule,
        PipesModule
    ]
})
export class NewsDetailModule {}
