import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O100ContactFooterModule } from './o100-contact-footer/o100-contact-footer.module';
import { O080MaterialMediacenterModule } from './o080-material-mediacenter/o080-material-mediacenter.module';
import { O030FilterModule } from './o030-filter/o030-filter.module';
import { O060TileListViewModule } from './o060-tile-list-view/o060-tile-list-view.module';
import { O010MainNavModule } from './o010-main-nav/o010-main-nav.module';
import { O020SubnavigationTextLinkModule } from './o020-subnavigation-text-link/o020-subnavigation-text-link.module';
import { O090MaterialModule } from './o090-material/o090-material.module';
import { O120SliderModule } from './o120-slider/o120-slider.module';
import { O130SocialMediaModule } from './o130-social-media/o130-social-media.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        O010MainNavModule,
        O030FilterModule,
        O060TileListViewModule,
        O080MaterialMediacenterModule,
        O100ContactFooterModule,
        O020SubnavigationTextLinkModule,
        O090MaterialModule,
        O120SliderModule
    ],
    exports: [
        O010MainNavModule,
        O030FilterModule,
        O060TileListViewModule,
        O080MaterialMediacenterModule,
        O100ContactFooterModule,
        O020SubnavigationTextLinkModule,
        O090MaterialModule,
        O120SliderModule,
        O130SocialMediaModule
    ]
})
export class OrganismsModule {}
