<div class="m0090">
    <div class="row" *ngIf="tile">
        <div class="col-12">
            <div class="m0090__back">
                <a [href]="tile.detailUrl" class="m0090__image-container">
                    <img
                        class="img-fluid"
                        [src]="tile.img + '?height=420'"
                        alt=""
                    />
                </a>

                <div class="m0090__text-content">
                    <span class="date">
                        {{ tile.date | date: 'dd.MM.yyyy' }}
                    </span>
                    <!--   <div class="tags">
                        <px-a00020-attribute
                            *ngFor="let tag of tile.tags"
                            [modifier]="tag.modifier ? tag.modifier : 'a'"
                            [text]="tag.text"
                        ></px-a00020-attribute>
                    </div> -->
                    <a [href]="tile.detailUrl">
                        <h3>{{ tile.headline | shorten: 136:'...':false }}</h3>
                    </a>
                    <p>{{ tile.copy }}</p>

                    <div class="m0090__downloadAssets" *ngIf="tile.assets && tile.assets.length > 0">
                        <a [matTooltip]="checkAllAssetsInDownloadCenter(tile.assets) ? 'Vom Downloadcenter entfernen' : 'Zum Downloadcenter hinzufügen'"
                           matTooltipPosition="above"
                           [matTooltipClass]="['tooltip-a00300', 'tooltip-a00300__big']"
                           matTooltipTouchGestures="off"
                           matTooltipHideDelay="500" (click)="addNewsToDownlodCenter(tile.assets)">
                            <div [inlineSVG]="checkAllAssetsInDownloadCenter(tile.assets) ? '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_black.svg' : '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_border_black.svg'">
                            </div>
                        </a>
                    </div>
                    <a [href]="tile.detailUrl">
                        <px-a00110-forward-back-link
                            [modifier]="'b'"
                            [text]="'Weiterlesen'"
                        ></px-a00110-forward-back-link>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
