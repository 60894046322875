import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {A00310AssetSavedComponent} from '../components/atoms/a00310-asset-saved/a00310-asset-saved.component';
import {BehaviorSubject} from "rxjs";
import {ApiService} from "./api.service";

export interface DownloadCenter {
    files?: DownloadCenterItem[];
}

export interface DownloadCenterItem {
    file: number;
    variant?: number;
}

export enum AlertStatus {
    ADD = 'ADD',
    REMOVE = 'REMOVE'
}

@Injectable({
    providedIn: 'root'
})
export class DownloadCenterService {

    public storageSubject= new BehaviorSubject<String>('');

    constructor(readonly snackBar: MatSnackBar, readonly api: ApiService) {
    }

    get myDownloadsJson(): DownloadCenter {
        return JSON.parse(localStorage.getItem('myDownloads'));
    }

    async cleanupDownLoadJson():Promise<void> {



        if(this.myDownloadsJson && this.myDownloadsJson.files) {
            const filesNumberArray = [];
            for (const item of this.myDownloadsJson.files) {
                filesNumberArray.push(item.file);
            }

            console.log(filesNumberArray, 'filesNumberArray');
            const data = await this.api.getFilesDownloadCenter(filesNumberArray);

            for (const item of this.myDownloadsJson.files) {
                if(data.files.find(file => item.file === file.uid) === undefined) {
                    this.removeItem(item.file);
                }
            }
        }

    }

    /**
     * che if item exists
     * @param item
     */
    itemExists(item: number): boolean {
        const myDownloadsJson = this.myDownloadsJson;
        if (myDownloadsJson !== null && myDownloadsJson.files && myDownloadsJson.files.map((el) => el.file).indexOf(item) !== -1) {
            return true;
        }
        return false;
    }

    /**
     * add item to Download Center,
     * if toggle flag if set
     * then items is removed if it already exists
     *
     * @param item
     * @param toggle
     * @param severalAtOnce: if the function is called by a asset group
     */
    addItem(item: number, toggle = false, severalAtOnce?: boolean): void {
        let added = AlertStatus.ADD;

        let myDownloadsJson = this.myDownloadsJson;
        if (myDownloadsJson === null) {
            myDownloadsJson = {files: []};
            myDownloadsJson.files = [
                {file: item, variant: 0}
            ];
        } else {
            const index = myDownloadsJson.files.map((el) => el.file).indexOf(item);
            if (myDownloadsJson.files) {
                if (index === -1) {
                    myDownloadsJson.files.push({file: item, variant: 0});
                } else {
                    if (toggle === true) {
                        myDownloadsJson.files.splice(index, 1);
                        added = AlertStatus.REMOVE;
                    }
                }
            } else {
                myDownloadsJson = {files: [{file: item, variant: 0}]};
            }
            if (!severalAtOnce) {
                this.openAlert(added);
            }
        }
        localStorage.setItem('myDownloads', JSON.stringify(myDownloadsJson));
        this.storageSubject.next(JSON.stringify(myDownloadsJson));
    }

    /**
     * remove Item from Download Center
     * @param item
     * @param severalAtOnce: if the function is called by a asset group
     */
    removeItem(item: number, severalAtOnce?: boolean): void {
        const myDownloadsJson = this.myDownloadsJson;
        if (myDownloadsJson !== null && myDownloadsJson.files.length > 0) {
            const index = myDownloadsJson.files.map((el) => el.file).indexOf(item);
            if (index !== -1) {
                myDownloadsJson.files.splice(index, 1);
            }
        }
        localStorage.setItem('myDownloads', JSON.stringify(myDownloadsJson));
        this.storageSubject.next(JSON.stringify(myDownloadsJson));
        if (!severalAtOnce) {
            this.openAlert(AlertStatus.REMOVE);
        }
    }

    /**
     * remove Item from Download Center
     * @param item
     */
    updateItem(item: DownloadCenterItem): void {
        const myDownloadsJson = this.myDownloadsJson;
        if (myDownloadsJson !== null && myDownloadsJson.files.length > 0) {
            const index = myDownloadsJson.files.map((el) => el.file).indexOf(item.file);
            if (index !== -1) {
                myDownloadsJson.files[index] = item;
            } else {
                myDownloadsJson.files.push(item);
            }
        }
        localStorage.setItem('myDownloads', JSON.stringify(myDownloadsJson));
        this.storageSubject.next(JSON.stringify(myDownloadsJson));
    }



    /**
     * remove Item from Download Center
     * @param item
     */
    removeAllItems(): void {
        const myDownloadsJson = this.myDownloadsJson;
        const count = this.myDownloadsJson.files.length;
        myDownloadsJson.files = [];
        localStorage.setItem('myDownloads', JSON.stringify(myDownloadsJson));
        this.storageSubject.next(JSON.stringify(myDownloadsJson));
        if (count > 1) {
            this.openAlert(AlertStatus.REMOVE, count);
        } else {
            this.openAlert(AlertStatus.REMOVE);
        }
    }

    /**
     * count Items in Download Center
     */
    countItems(): number {
        let count = 0;
        const myDownloadsJson = this.myDownloadsJson;
        if (myDownloadsJson && myDownloadsJson.files) {
            count = myDownloadsJson.files.length;
        }
        return count;
    }

    /**
     * @param assets
     */
    checkAssetsArray(assets: number[]): boolean {
        if (this.myDownloadsJson === null || this.myDownloadsJson.files === null) {
            return false;
        }
        return assets.every((assetId) => {
            return this.myDownloadsJson.files.map((el) => el.file).indexOf(assetId) !== -1 && assets.length > 0;
        });
    }

    /**
     * @param news
     */
    addNewsToDownlodCenter(news: number[]) {
        let added: AlertStatus;
        let count: number;

        if (this.checkAssetsArray(news)) {
            news.map((item) => {
                this.removeItem(item, true);
            });
            added = AlertStatus.REMOVE;
        } else {
            const itemList: boolean[] = [];

            news.map((item) => {
                itemList.push(this.itemExists(item));
                this.addItem(item, false, true);
            });

            if (itemList && itemList.every(elem => elem === false)) {
                count = news.length;
                added = AlertStatus.ADD;
            } else {
                count = news.length;
                for (let i = 0; i <= itemList.length; i++) {
                    if (itemList[i]) {
                        count--;
                    }
                }
                added = AlertStatus.ADD;
            }
        }

        this.openAlert(added, count);
    }

    /**
     * open Alert after addItems and removeItems
     */
    openAlert(status: AlertStatus, count?: number): void {
        let message: string;

        if (status === AlertStatus.ADD) {
            if (count && count > 1) {
                message = count + ' Medien gespeichert';
            } else {
                message = 'Medium gespeichert';
            }
        } else if (status === AlertStatus.REMOVE) {
            if (count && count > 1) {
                message = count + ' Medien entfernt';
            } else {
                message = 'Medium entfernt';
            }
        } else {
            message = 'Fehler ist aufgetreten!';
        }

        const pageYOffset = window.pageYOffset;

        this.snackBar.openFromComponent(A00310AssetSavedComponent, {
            data: {message, pageYOffset},
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: 'mat-snack-bar-download-center',
            duration: 3000
        });
    }
}
