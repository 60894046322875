import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges
} from '@angular/core';

class Point {
    constructor(private x: number, private y: number) {}

    public toString(): string {
        return `${this.x} ${this.y}`;
    }
}

@Component({
    selector: 'px-cropped-area-overlay',
    templateUrl: './cropped-area-overlay.component.html',
    styleUrls: ['./cropped-area-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CroppedAreaOverlayComponent implements OnChanges {
    @Input()
    x1 = 0;

    @Input()
    y1 = 0;

    @Input()
    x2 = 0;

    @Input()
    y2 = 0;

    @Input()
    width = 0;

    @Input()
    height = 0;

    @Input()
    color = '';

    @Input()
    opacity = 1;

    @Input()
    disabled = false;

    d = '';

    ngOnChanges() {
        const p1 = new Point(this.x1, this.y2);
        const p2 = new Point(this.x2, this.y2);
        const p3 = new Point(this.x2, this.y1);
        const p4 = new Point(this.x1, this.y1);
        const base = `M 0 0 h${this.width} v${this.height} h-${this.width}z`;
        this.d = `${base} M ${p1} L ${p2} L ${p3} L ${p4} Z`;
    }
}
