import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0160FormsModule } from './m0160-forms/m0160-forms.module';
import { M0140ContactCardSmallModule } from './m0140-contact-card-small/m0140-contact-card-small.module';
import { M0120TextImageModule } from './m0120-text-image/m0120-text-image.module';
import { M0110MediaTileModule } from './m0110-media-tile/m0110-media-tile.module';
import { M0060TagSlideModule } from './m0060-tag-slide/m0060-tag-slide.module';
import { M0050FilterCardModule } from './m0050-filter-card/m0050-filter-card.module';
import { M0030NavigationButtonListModule } from './m0030-navigation-button-list/m0030-navigation-button-list.module';
import { M0170OverlayModule } from './m0170-overlay/m0170-overlay.module';
import { M0070NewsteaserTileModule } from './m0070-newsteaser-tile/m0070-newsteaser-tile.module';
import { M0080NewsteaserListModule } from './m0080-newsteaser-list/m0080-newsteaser-list.module';
import { M0090NewsteaserTileBigModule } from './m0090-newsteaser-tile-big/m0090-newsteaser-tile-big.module';
import { M0150ContactCardBigModule } from './m0150-contact-card-big/m0150-contact-card-big.module';
import { M0010KeyvisualModule } from './m0010-keyvisual/m0010-keyvisual.module';
import { M0020LinkListsModule } from './m0020-link-lists/m0020-link-lists.module';
import { M0130AccordeonModule } from './m0130-accordeon/m0130-accordeon.module';
import { M0180VideoTeaserModule } from './m0180-video-teaser/m0180-video-teaser.module';
import { M0180PodcastModule } from './m0180-podcast/m0180-podcast.module';
import { M0230TextVideoModule } from './m0230-text-video/m0230-text-video.module';
import { M0240AudioPlayerModule } from './m0240-audio-player/m0240-audio-player.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        M0010KeyvisualModule,
        M0030NavigationButtonListModule,
        M0050FilterCardModule,
        M0060TagSlideModule,
        M0070NewsteaserTileModule,
        M0110MediaTileModule,
        M0120TextImageModule,
        M0140ContactCardSmallModule,
        M0150ContactCardBigModule,
        M0160FormsModule,
        M0170OverlayModule,
        M0080NewsteaserListModule,
        M0090NewsteaserTileBigModule,
        M0020LinkListsModule,
        M0130AccordeonModule,
        M0180VideoTeaserModule,
        M0180PodcastModule,
        M0230TextVideoModule,
        M0240AudioPlayerModule
    ],
    exports: [
        M0010KeyvisualModule,
        M0030NavigationButtonListModule,
        M0050FilterCardModule,
        M0060TagSlideModule,
        M0070NewsteaserTileModule,
        M0110MediaTileModule,
        M0120TextImageModule,
        M0140ContactCardSmallModule,
        M0150ContactCardBigModule,
        M0160FormsModule,
        M0170OverlayModule,
        M0080NewsteaserListModule,
        M0090NewsteaserTileBigModule,
        M0020LinkListsModule,
        M0130AccordeonModule,
        M0180VideoTeaserModule,
        M0180PodcastModule,
        M0230TextVideoModule,
        M0240AudioPlayerModule
    ]
})
export class MoleculesModule {}
