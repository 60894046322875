import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';

interface M0210AccordionTeaser {
    image?: string;
    imageTablet?: string;
    imageMobile?: string;
    headline?: string;
    text?: string;
    active?: boolean;
}

@Component({
    selector: 'px-m0210-accordion-teaser',
    templateUrl: './m0210-accordion-teaser.component.html',
    styleUrls: ['./m0210-accordion-teaser.component.scss']
})
export class M0210AccordionTeaserComponent implements OnInit, AfterViewInit {
    @Input() teasers: M0210AccordionTeaser[];
    @ViewChildren('content') content: QueryList<ElementRef>;

    contentHeights: number[] = [];

    ngOnInit(): void {
        if (this.teasers) {
            this.teasers[0].active = true;
        }
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setHeights();
        }, 1000);
    }

    closeOther(currentIndex: number): void {
        this.contentHeights = [];
        if (this.teasers[currentIndex].active) {
            this.teasers[currentIndex].active = false;
        } else {
            for (let i = 0; i < this.teasers.length; i++) {
                this.teasers[i].active = false;
            }
            this.teasers[currentIndex].active = true;
        }
        this.setHeights();
    }
    setHeights(): void {
        this.content
            .toArray()
            .forEach((element) =>
                this.contentHeights.push(element.nativeElement.offsetHeight)
            );
    }
}
