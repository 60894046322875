import { Component, OnInit, Input } from '@angular/core';
import { NewsChapter } from 'src/app/interfaces/News';

@Component({
    selector: 'px-m0130-accordeon',
    templateUrl: './m0130-accordeon.component.html',
    styleUrls: ['./m0130-accordeon.component.scss']
})
export class M0130AccordeonComponent implements OnInit {
    @Input() chapters: NewsChapter[];
    constructor() {}

    ngOnInit(): void {}
}
