export enum M0010KeyVisualModuleType {
    A = 'a',
    B = 'b',
    C = 'c'
}

export interface M0010KeyVisualModuleA {
    modifier: M0010KeyVisualModuleType.A;
    image: string;
    buttonFirstText: string;
    buttonFirstCta: string;
    buttonScndText: string;
    buttonScndCta: string;
    headline: string;
    headlineSecond: string;
    altTitle: string;
}

export interface M0010KeyVisualModuleB {
    modifier: M0010KeyVisualModuleType.B;
    image: string;
    headline: string;
    headlineSecond: string;
    altTitle: string;
}

export interface M0010KeyVisualModuleC {
    modifier: M0010KeyVisualModuleType.C;
    image: string;
    altTitle: string;
}

export type M0010KeyVisualModule =
    | M0010KeyVisualModuleA
    | M0010KeyVisualModuleB
    | M0010KeyVisualModuleC;
