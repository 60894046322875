import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextVideoComponent } from './text-video.component';
import { M0230TextVideoModule } from '../../molecules/m0230-text-video/m0230-text-video.module';

@NgModule({
    declarations: [TextVideoComponent],
    imports: [CommonModule, M0230TextVideoModule],
    exports: [TextVideoComponent]
})
export class TextVideoModule {}
