<div class="a00160 a00160--{{ modifier }}">
    <button
        [class.closed]="isCollapsed"
        (click)="toggleCollapse()"
        [attr.aria-expanded]="!isCollapsed"
        [attr.aria-controls]="text + '-a00160-' + modifier"
        #collapseButton
    >
        <span *ngIf="modifier == 'b'" class="text">{{ text }}</span>
        <span
            class="arrow"
            [inlineSVG]="
                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/black.svg'
            "
        ></span>
    </button>
    <div
        class="collapse"
        id="{{ text }}-a00160-{{ modifier }}"
        [ngbCollapse]="isCollapsed"
        #collapse
    >
        <div class="">
            <ng-content></ng-content>
        </div>
    </div>
</div>
