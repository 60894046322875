import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00240LineDividerComponent } from './a00240-line-divider.component';

@NgModule({
    declarations: [A00240LineDividerComponent],
    imports: [CommonModule],
    exports: [A00240LineDividerComponent]
})
export class A00240LineDividerModule {}
