<div class="m0080">
    <div class="row">
        <div class="col-12">
            <div class="m0080__back">
                <div class="row mobile">
                    <div class="col-9 offset-3">
                        <span class="date">
                            {{ tile.date | date: 'dd.MM.yyyy' }}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="m0080__img-spacer"></div>
                        <div class="m0080__img-container">
                            <img class="img-fluid" [src]="tile.img" alt=""/>
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="m0080__text-content">
                            <div>
                                <span class="date desktop">
                                    {{ tile.date | date: 'dd.MM.yyyy' }}
                                </span>

                                <h3>{{ tile.headline | shorten: 90:'...' }}</h3>
                                <p class="desktop">{{ tile.copy }}</p>
                            </div>
                            <div class="m0080__cta-container">
                                <a class="m0080__downloadAssets"
                                   [matTooltip]="checkAllAssetsInDownloadCenter(tile.assets) ? 'Vom Downloadcenter entfernen' : 'Zum Downloadcenter hinzufügen'"
                                   matTooltipPosition="above"
                                   [matTooltipClass]="['tooltip-a00300', 'tooltip-a00300__big']"
                                   matTooltipTouchGestures="off"
                                   matTooltipHideDelay="500"
                                   *ngIf="tile.assets && tile.assets.length > 0"
                                   (click)="addNewsToDownlodCenter(tile.assets)">
                                    <div [inlineSVG]="checkAllAssetsInDownloadCenter(tile.assets) ? '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_black.svg' : '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/bookmark_border_black.svg'">
                                    </div>
                                </a>
                                <a class="m0080__cta-container__forward-back-link" [href]="tile.detailUrl">
                                    <px-a00110-forward-back-link
                                        [modifier]="'b'"
                                        [text]="'Weiterlesen'"
                                    ></px-a00110-forward-back-link>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
