import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00120AccordionComponent } from './a00120-accordion.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [A00120AccordionComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        InlineSVGModule.forRoot(),
        NgbAccordionModule
    ],
    exports: [A00120AccordionComponent]
})
export class A00120AccordionModule {}
