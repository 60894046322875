import { M0150ContactCardBig } from '../molecules/m0150-contact-card-big';

export enum O110ContactBigType {
    A = 'a'
}

export interface O110ContactBig {
    type: O110ContactBigType;
    employees: M0150ContactCardBig[];
}
