import { Image } from '../../services/api.service';

export enum A00220MediaCardType {
    A = 'a',
    B = 'b',
    C = 'c',
    D = 'd',
    E = 'e',
    F = 'f',
    G = 'g',
    H = 'h'
}

export interface A00220MediaCard {
    type: A00220MediaCardType;
    image?: Image;
}
