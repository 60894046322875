<h2 class="assetEdit__headline">Upload bearbeiten</h2>
<form [formGroup]="form" class="assetEdit" (submit)="save()">
    <button
        *ngIf="
            (data.type === 'single' || data.type === 'upload') &&
            imageUrl &&
            !showCropper
        "
        (click)="showCropper = true"
    >
        Crop Image
    </button>
    <div *ngIf="showCropper">
        <image-cropper
            [imageURL]="imageUrl"
            [maintainAspectRatio]="true"
            [cropper]="relativeCropperPosition | async"
            [aspectRatio]="311 / 228"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperLoaded($event)"
            (imageCropped)="imageCropped($event)"
        ></image-cropper>
        <button (click)="saveCropping()">Save Cropping</button>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="40">
            <div>
                <mat-form-field class="full">
                    <mat-label>Titel</mat-label>
                    <input
                        matInput
                        #title
                        maxlength="55"
                        formControlName="title"
                    />
                    <mat-hint align="end">
                        {{ title.value.length }} /
                        {{ title.maxLength }}
                    </mat-hint>
                    <mat-error
                        *ngIf="form.controls['title']?.errors?.maxlength"
                    >
                        Titel darf nur
                        <strong>{{ title.maxLength }}</strong>
                        Zeichen lang sein
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full">
                    <mat-label>Beschreibung</mat-label>
                    <input
                        matInput
                        #description
                        maxlength="750"
                        formControlName="description"
                    />
                    <mat-hint align="end">
                        {{ description.value.length }} /
                        {{ description.maxLength }}
                    </mat-hint>
                    <mat-error
                        *ngIf="form.controls['description']?.errors?.maxlength"
                    >
                        Beschreibung darf nur
                        <strong>{{ description.maxLength }}</strong>
                        Zeichen lang sein
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex="5"></div>
        <div fxFlex="40">
            <div>
                <mat-form-field>
                    <mat-label>Datum</mat-label>
                    <input
                        matInput
                        [matDatepicker]="date"
                        formControlName="date"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="date"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #date></mat-datepicker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full">
                    <mat-label>Fotograf / Quelle</mat-label>
                    <input matInput formControlName="source" />
                </mat-form-field>
            </div>
        </div>
        <div fxFlex="15"></div>
    </div>
    <h2 class="tagsHeader">Tags ergänzen</h2>
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="55">
        </div>
        <div fxFlex="55">
            <h4>Themen</h4>
            <mat-form-field class="searchForm">
                <input
                    matInput
                    #tagFilterInput
                    (keyup)="tagSearch = tagFilterInput.value; filterTags()"
                />
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class='assetEdit__spaceAfter'>
        <div fxFlex="50">
            <h4>Modelle</h4>
            <div fxLayout="row" fxLayoutAlign="space-between end">
                <div fxFlex="50">
                    <select multiple class="select-list">
                        <option
                            *ngFor="let model of models"
                            (click)="toggleModel(model)"
                        >
                            {{ model.title }}
                        </option>
                    </select>
                </div>
                <div fxFlex="50">
                    <select multiple class="select-list">
                        <option
                            *ngFor="let model of selectedModels"
                            (click)="toggleModel(model)"
                        >
                            {{ model.title }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div fxFlex="50">
            <h5>Tags</h5>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div fxFlex="50">
                    <select multiple class="select-list">
                        <option
                            *ngFor="let tag of filteredTags"
                            (click)="toggleTag(tag)"
                        >
                            {{ tag.title }}
                        </option>
                    </select>
                </div>
                <div fxFlex="50">
                    <select multiple class="select-list">
                        <option
                            *ngFor="let tag of selectedTags"
                            (click)="toggleTag(tag)"
                        >
                            {{ tag.title }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="50">
            <h4>Modelljahr</h4>
            <div fxLayout="row" fxLayoutAlign="space-between end">
                <div fxFlex="50">
                    <select multiple class="select-list">
                        <option
                            *ngFor="let modelYear of modelYears"
                            (click)="toggleModelYear(modelYear)"
                        >
                            {{ modelYear.title }}
                        </option>
                    </select>
                </div>
                <div fxFlex="50">
                    <select multiple class="select-list">
                        <option
                            *ngFor="let modelYear of selectedModelYears"
                            (click)="toggleModelYear(modelYear)"
                        >
                            {{ modelYear.title }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isVideo">
        <button
            mat-icon-button
            *ngIf="tmpImageThumb || getCustomVideoThumb()"
            (click)="removeVideoThumbFile($event)"
        >
            <mat-icon>delete</mat-icon>
        </button>
        <img
            *ngIf="data.type === 'single' && getCustomVideoThumb()"
            [src]="
                '/api/asset/' + data.file.uid + '/show?height=80&t=' + tstamp
            "
            height="80"
        />
        <img *ngIf="tmpImageThumb" [src]="tmpImageThumb" height="80" />
        <input
            #fileUploadE
            type="file"
            style="display: none"
            accept="image/*"
            (change)="onVideoThumbUpload($event)"
        />
        <button
            mat-raised-button
            (click)="$event.preventDefault(); fileUploadE.click()"
            *ngIf="!tmpImageThumb && !getCustomVideoThumb()"
        >
            Upload Image for Frontend
        </button>
    </div>
    <div class="buttonRow">
        <button
            type="button"
            class="exitBtn"
            mat-raised-button
            (click)="$event.preventDefault(); dialogRef.close()"
        >
            Verwerfen
        </button>
        <button
            mat-raised-button
            color="primary"
            [disabled]="form.invalid"
            class="saveBtn"
        >
            Speichern
        </button>
    </div>
</form>
