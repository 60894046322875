import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00030PrimaryButtonComponent } from './a00030-primary-button.component';

@NgModule({
    declarations: [A00030PrimaryButtonComponent],
    imports: [CommonModule],
    exports: [A00030PrimaryButtonComponent]
})
export class A00030PrimaryButtonModule {}
