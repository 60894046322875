import { Category } from './Category';
import { AssetFile } from './AssetFile';
import { ContentElement } from './ContentElement';

export interface NewsChapter {
    uid: number;
    headline: string;
    content: string;
}

export enum NewsType {
    NEWS = 'news',
    PRESSFOLDER_1 = 'press1',
    PRESSFOLDER_2 = 'press2',
    PAGE = 'page'
}

export interface News {
    uid: number;
    type: NewsType;
    models: Category[];
    modelYears: Category[];
    tags: Category[];
    teaserImage: string;
    // headerImage?: string;
    headline: string;
    summary: string;
    content?: string;
    date: Date;
    chapters?: NewsChapter[];
    related?: Array<
        Omit<News, 'related' | 'chapters' | 'headerImage' | 'content'>
    >;
    assets?: AssetFile[];
    assetsDownloadCenter?: number[];
    envkv?: string;
    envkvModels?: number[];
    envkvVehicles?: string[];
    detailUrl?: string;
    hide?: boolean;
    crop?: {
        x1: number;
        y1: number;
        x2: number;
        y2: number;
    };
    teaserAssetUid?: number;
    pageId?: number;
    pageContent?: ContentElement[];
}

interface NewsRelatedDto
    extends Omit<
        News,
        'related' | 'chapters' | 'headerImage' | 'content' | 'date'
    > {
    date: number;
}

export interface NewsDto extends Omit<News, 'date' | 'related'> {
    date: number;
    related: NewsRelatedDto[];
}

export interface NewsSaveDto
    extends Omit<NewsDto, 'related' | 'models' | 'modelYears' | 'tags' | 'assets' | 'envkv'> {
    related: number[];
    models: number[];
    modelYears: number[];
    tags: number[];
    assets: number[];
    envkvModels: number[];
    envkvVehicles: string[];
/*
    pageContent?: any[];
*/
}
