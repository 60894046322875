import { Component, Input, OnInit } from '@angular/core';

export interface Image {
    image?: string;
    imagePopup?: string;
    imageTablet?: string;
    imagePopupTablet?: string;
    imageMobile?: string;
    imagePopupMobile?: string;
}

@Component({
    selector: 'px-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
    content: Image[];

    @Input()
    set data(data: string) {
        this.content = [];
        const content = JSON.parse(data);
        for (const key in content) {
            const element = content[key];
            this.content.push(element);
        }
    }

    constructor() {}

    ngOnInit(): void {}
}
