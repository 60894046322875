<div class="o080">
    <div class="row tileRow">
        <div class="col-12 tileWrapper">
            <ng-container *ngFor="let tile of tiles; trackBy: tilesTrackBy">
                <px-m0110-media-tile
                    [modifier]="tile.type"
                    [preview]="tile.preview"
                    [download]="tile.download"
                ></px-m0110-media-tile>
            </ng-container>
        </div>
        <div class="col-12 loadMore" *ngIf="hasMore">
            <px-a00070-tertiary-button-small
                (click)="loadMore.emit()"
                text="Mehr laden"
            ></px-a00070-tertiary-button-small>
        </div>
    </div>
</div>
