import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0220ImageGalleryComponent } from './m0220-image-gallery.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InlineSVGModule } from 'ng-inline-svg';
import { GalleryOverlayModule } from '../../helpers/gallery-overlay/gallery-overlay.module';

@NgModule({
    declarations: [M0220ImageGalleryComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        InlineSVGModule.forRoot(),
        GalleryOverlayModule
    ],
    exports: [M0220ImageGalleryComponent]
})
export class M0220ImageGalleryModule {}
