import { A00210InputCheckboxType } from '../atoms/a00210-input-checkbox';

export enum M0050FilterCardType {
    A = 'a',
    B = 'b'
}

export interface M0050FilterCardTag {
    id: string | number;
    label: string;
    isTop: boolean;
    modifier: A00210InputCheckboxType;
}

export interface M0050FilterCard {
    modifier: M0050FilterCardType;
    tags: M0050FilterCardTag[];
}
