import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0050FilterCardComponent } from './m0050-filter-card.component';
import { A00160ExpanderModule } from '../../atoms/a00160-expander/a00160-expander.module';
import { A00200SearchfieldModule } from '../../atoms/a00200-searchfield/a00200-searchfield.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A00210InputCheckboxModule } from '../../atoms/a00210-input-checkbox/a00210-input-checkbox.module';

@NgModule({
    declarations: [M0050FilterCardComponent],
    imports: [
        CommonModule,
        A00160ExpanderModule,
        A00200SearchfieldModule,
        FormsModule,
        ReactiveFormsModule,
        A00210InputCheckboxModule
    ],
    exports: [M0050FilterCardComponent]
})
export class M0050FilterCardModule {}
