<h1 class="termsOfUseOverlay__headline">
    Nutzungsbedingungen - private und redaktionelle Nutzung
</h1>
<button
    class="termsOfUseOverlay__close"
    (click)="dialogRef.close()"
    [inlineSVG]="
        '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/close.svg'
    "
></button>
<div class="content">
    <p>
        Die Inhalte dürfen heruntergeladen, genutzt und mit Dritten geteilt
        werden, sofern die Inhalte nicht bearbeitet, im Ganzen und
        ausschließlich für private und redaktionelle Zwecke genutzt werden. Der
        Urheber oder Ersteller soll in einer Weise und in dem Umfang kenntlich
        gemacht werden, wie es den üblichen Gepflogenheiten entspricht (dies
        bedeute z.B., dass der Fotograf immer namentlich genannt werden muss).
    </p>
</div>
<div>
    <px-a00210-input-checkbox
        [inputControl]="checkbox"
        inputName="tosCheckbox"
        label="Ich habe die Nutzungsbedingungen gelesen und erkläre mich damit einverstanden."
    ></px-a00210-input-checkbox>
</div>
<div>
    <px-a00040-primary-button-icon
        [modifier]="A00040PrimaryButtonIconType.B"
        text="Bestätigen"
        [disabled]="checkbox.invalid"
        (clicked)="accept()"
    ></px-a00040-primary-button-icon>
</div>
