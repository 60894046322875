import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O010MainNavComponent } from './o010-main-nav.component';
import { A00010HeaderLogoModule } from '../../atoms/a00010-header-logo/a00010-header-logo.module';
import { M0030NavigationButtonListModule } from '../../molecules/m0030-navigation-button-list/m0030-navigation-button-list.module';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [O010MainNavComponent],
    imports: [
        CommonModule,
        A00010HeaderLogoModule,
        M0030NavigationButtonListModule,
        InlineSVGModule.forRoot()
    ],
    exports: [O010MainNavComponent]
})
export class O010MainNavModule {}
