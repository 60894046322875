import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { O030FilterGroup } from 'src/app/interfaces/organisms/o030-filter';
import { M0070Tile } from 'src/app/interfaces/molecules/m0070-newsteaser-tile';
import { O100ContactFooter } from '../../../interfaces/organisms/o100-contact-footer';

@Component({
    selector: 'px-t10-news-tile-list',
    templateUrl: './t10-news-tile-list.component.html',
    styleUrls: ['./t10-news-tile-list.component.scss']
})
export class T10NewsTileListComponent implements OnInit {
    @Output()
    selectedFilters = new EventEmitter<{
        [filter: string]: Array<string | number>;
    }>();

    @Output()
    loadMore = new EventEmitter<void>();

    @Input()
    filterGroups: O030FilterGroup[] = [];

    @Input()
    newsteaserTiles: M0070Tile[];

    @Input()
    hasMore = true;

    @Input()
    contactFooter: O100ContactFooter;

    @Input()
    isBlog = false;

    constructor() {}

    ngOnInit(): void {}
}
