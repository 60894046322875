import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsComponent } from './news.component';
import { O030FilterModule } from '../../organisms/o030-filter/o030-filter.module';
import { T10NewsTileListModule } from '../../templates/t10-news-tile-list/t10-news-tile-list.module';

@NgModule({
    declarations: [NewsComponent],
    imports: [CommonModule, O030FilterModule, T10NewsTileListModule]
})
export class NewsModule {}
