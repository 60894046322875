import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0150ContactCardBigComponent } from './m0150-contact-card-big.component';
import { A00150ContactLinksModule } from '../../atoms/a00150-contact-links/a00150-contact-links.module';

@NgModule({
    declarations: [M0150ContactCardBigComponent],
    imports: [CommonModule, A00150ContactLinksModule],
    exports: [M0150ContactCardBigComponent]
})
export class M0150ContactCardBigModule {}
