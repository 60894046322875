import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulletListTeaserComponent } from './bullet-list-teaser.component';
import { M0200BulletListTeaserModule } from '../../molecules/m0200-bullet-list-teaser/m0200-bullet-list-teaser.module';

@NgModule({
    declarations: [BulletListTeaserComponent],
    imports: [CommonModule, M0200BulletListTeaserModule],
    exports: [BulletListTeaserComponent]
})
export class BulletListTeaserModule {}
