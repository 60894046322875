import { Component, Input, OnInit } from '@angular/core';
import { O020SubnavigationTextLink } from '../../../interfaces/organisms/o020-subnavigation-text-link';
import { M0020LinkListsType } from '../../../interfaces/molecules/m0020-link-list';

@Component({
    selector: 'px-sub-navigation',
    templateUrl: './sub-navigation.component.html',
    styleUrls: ['./sub-navigation.component.scss']
})
export class SubNavigationComponent implements OnInit {
    nav: O020SubnavigationTextLink;

    @Input()
    set links(str: string) {
        this.nav = this.buildSubNav(JSON.parse(str));
    }

    constructor() {}

    ngOnInit(): void {}

    buildSubNav(
        links: Array<{ title: string; identifier: string }>
    ): O020SubnavigationTextLink {
        return {
            m0020LinkListsContent: {
                links: links.map((l) => ({
                    text: l.title,
                    href: '#' + l.identifier,
                    target: ''
                })),
                modifier: M0020LinkListsType.B
            }
        };
    }
}
