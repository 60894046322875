import {Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {DOCUMENT} from '@angular/common';

export interface SnackBarData {
    message: string;
    pageYOffset?: number;
}

@Component({
    selector: 'px-a00310-asset-saved',
    templateUrl: './a00310-asset-saved.component.html',
    styleUrls: ['./a00310-asset-saved.component.scss']
})
export class A00310AssetSavedComponent implements OnInit {
    @ViewChild('divElement') el: ElementRef;
    window: Window;
    screenWidth: number;
    top: number;
    right: number;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
        private renderer: Renderer2,
    ) {
        this.window = this.document.defaultView;
        this.getScreenSize();
        if (this.screenWidth >= 1200) {
            if (this.data?.pageYOffset >= 100) {
                this.top = 0;
            } else {
                this.top = 100 - this.data?.pageYOffset;
            }
        } else {
            if (this.data?.pageYOffset >= 100) {
                this.top = 0;
            } else {
                this.top = 50 - this.data?.pageYOffset;
            }
        }
    }

    ngOnInit(): void {
    }

    @HostListener('window:scroll', ['$event'])
    scroll(): void {
        if (this.window && this.window.pageYOffset < 100) {
            this.renderer.setStyle(
                this.el.nativeElement, 'top',
                this.screenWidth > 1200 ?
                    100 - this.window.pageYOffset + 'px' :
                    50 - this.window.pageYOffset + 'px');
        } else {
            this.renderer.setStyle(this.el.nativeElement, 'top', 0 + 'px');
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        this.screenWidth = this.window.innerWidth;

        if (this.screenWidth <= 767) {
            this.right = 0; // (this.screenWidth - 540) / 2;
        } else if (this.screenWidth <= 991) {
            this.right = 0; //(this.screenWidth - 720) / 2;
        } else if (this.screenWidth <= 1200) {
            this.right = 0; //(this.screenWidth - 960) / 2;
        } else if (this.screenWidth >= 1290) {
            this.right = (this.screenWidth - 1290) / 2;
        } else {
            this.right = 0;
        }
    }

}
