import { Component, Input, OnInit } from '@angular/core';
import { AssetFile, AssetType } from '../../../interfaces/AssetFile';
import { ApiService } from '../../../services/api.service';
import { Category } from '../../../interfaces/Category';
import { O030FilterGroup } from '../../../interfaces/organisms/o030-filter';
import { M0050FilterCardType } from '../../../interfaces/molecules/m0050-filter-card';
import { A00210InputCheckboxType } from '../../../interfaces/atoms/a00210-input-checkbox';
import {
    M0110MediaTile,
    M0110MediaTileType
} from '../../../interfaces/molecules/m0110-media-tile';
import { A00220MediaCardType } from '../../../interfaces/atoms/a00220-media-card';
import { A00100DownloadButtonType } from '../../../interfaces/atoms/a00100-download-button';
import { O100ContactFooter } from '../../../interfaces/organisms/o100-contact-footer';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'px-media-center',
    templateUrl: './media-center.component.html',
    styleUrls: ['./media-center.component.scss']
})
export class MediaCenterComponent implements OnInit {

    _historic = false;

    @Input()
    set historic(h: string) {
        this._historic = h === '1';
    }

    filter: {
        models: number[];
        modelYears: number[];
        tags: number[];
        years: number[];
        types: AssetType[];
        page: number;
    } = {
        models: [],
        modelYears: [],
        tags: [],
        years: [],
        types: [],
        page: 0
    };

    models: Category[] = [];

    modelYears: Category[] = [];

    tags: Category[] = [];

    years: Category[] = [];

    files: AssetFile[] = [];

    tiles: M0110MediaTile[] = [];

    totalFileCount = 0;

    mediaTypes: Array<{
        id: string;
        name: string;
    }> = [];

    filterGroups: O030FilterGroup[] = [];

    _contactFooter: O100ContactFooter;

    @Input()
    set contactFooter(footer: string | O100ContactFooter) {
        if (typeof footer === 'string') {
            this._contactFooter = JSON.parse(footer);
        } else {
            this._contactFooter = footer;
        }
    }

    get contactFooter() {
        return this._contactFooter;
    }

    constructor(
        private readonly api: ApiService,
        private readonly breakpointObserver: BreakpointObserver
    ) {}


    async ngOnInit(): Promise<void> {
        this.loadFiles();
        this.filterGroups = [];
        const data = await this.api.getAssetCategories(this._historic);
        this.models = data.models;
        this.modelYears = data.modelYears;
        this.tags = data.others;
        this.years = data.years;
        this.mediaTypes = data.types;

        const filterGroups: O030FilterGroup[] = [];

        if (this.models.length > 0) {
            filterGroups.push({
                id: 'models',
                label: 'Modelle',
                filterCard: {
                    modifier: M0050FilterCardType.A,
                    tags: this.models.map((m) => ({
                        id: m.uid,
                        label: m.title,
                        isTop: m.isTop,
                        modifier: A00210InputCheckboxType.B
                    }))
                }
            });
        }

        if (this.modelYears.length > 0) {
            filterGroups.push({
                id: 'modelYears',
                label: 'Modelljahr',
                filterCard: {
                    modifier: M0050FilterCardType.A,
                    tags: this.modelYears.map((m) => ({
                        id: m.uid,
                        label: m.title,
                        isTop: m.isTop,
                        modifier: A00210InputCheckboxType.B
                    }))
                }
            });
        }

        if (this.tags.length > 0 && !this._historic) {
            filterGroups.push({
                id: 'tags',
                label: 'Themen',
                filterCard: {
                    modifier: M0050FilterCardType.B,
                    tags: this.tags.map((t) => ({
                        id: t.uid,
                        label: t.title,
                        isTop: t.isTop,
                        modifier: A00210InputCheckboxType.B
                    }))
                }
            });
        }

        if (this.years.length > 0) {
            filterGroups.push({
                id: 'years',
                label: 'Veröffentlichungsdatum',
                filterCard: {
                    modifier: M0050FilterCardType.A,
                    tags: this.years.map((y) => ({
                        id: y.uid,
                        label: y.title,
                        isTop: false,
                        modifier: A00210InputCheckboxType.B
                    }))
                }
            });
        }

        if (this.mediaTypes.length > 0) {
            filterGroups.push({
                id: 'types',
                label: 'Medium',
                filterCard: {
                    modifier: M0050FilterCardType.A,
                    tags: this.mediaTypes.map((mt) => ({
                        id: mt.id,
                        label: mt.name,
                        isTop: false,
                        modifier: A00210InputCheckboxType.B
                    }))
                }
            });
        }

        this.filterGroups = filterGroups;
    }

    async loadFiles() {
        const data = await this.api.getFiles(
            this.filter.models,
            this.filter.modelYears,
            this.filter.tags,
            this.filter.years,
            this.filter.types,
            this.filter.page,
            this.breakpointObserver.isMatched('(max-width: 991px)'),
            this._historic
        );

        this.totalFileCount = data.count;

        if (this.filter.page > 0) {
            this.files = [...this.files, ...data.files];
        } else {
            this.files = data.files;
        }

        this.tiles = this.files.map((f) => this.buildTile(f));
    }

    buildTile(file: AssetFile): M0110MediaTile {
        const tile: M0110MediaTile = {
            id: file.uid,
            type: M0110MediaTileType.A,
            preview: {
                type: A00220MediaCardType.A,
                image: this.api.getAssetPreview(file.uid, 300)
            },
            download: {
                type: A00100DownloadButtonType.A,
                title: file.title,
                downloads: [],
                file
            }
        };

        let label = 'High Res';

        if (file.type === AssetType.VIDEO) {
            tile.preview.type = A00220MediaCardType.C;
            label = 'Video herunterladen';
        } else if (file.fileExtension === 'pdf') {
            tile.preview.type = A00220MediaCardType.G;
            label = 'PDF herunterladen';
        } else if (file.type === AssetType.OTHER) {
            tile.preview.type = A00220MediaCardType.E;
            label = 'Datei herunterladen';
        }

        const low = file.variants.find((v) => v.type === 'low');
        if (low) {
            tile.download.downloads.push({
                label: 'Low Res',
                active: false,
                size: low.size,
                link: this.api.getDownloadLink(file.uid, low.uid),
                click: false
            });
        }

        tile.download.downloads.push({
            label,
            active: false,
            size: file.size,
            link: this.api.getDownloadLink(file.uid),
            click: false
        });

        return tile;
    }

    async loadMore() {
        this.filter.page++;
        await this.loadFiles();
    }

    onFilterChanged(filter: { [filter: string]: Array<string | number> }) {
        if (filter.models) {
            this.filter.models = filter.models.map((m) => parseInt(m + '', 10));
        } else {
            this.filter.models = [];
        }

        if (filter.modelYears) {
            this.filter.modelYears = filter.modelYears.map((m) =>
                parseInt(m + '', 10)
            );
        } else {
            this.filter.modelYears = [];
        }

        if (filter.tags) {
            this.filter.tags = filter.tags.map((t) => parseInt(t + '', 10));
        } else {
            this.filter.tags = [];
        }

        if (filter.years) {
            this.filter.years = filter.years.map((y) => parseInt(y + '', 10));
        } else {
            this.filter.years = [];
        }

        if (filter.types) {
            this.filter.types = [...filter.types.map((t) => t as AssetType)];
        } else {
            this.filter.types = [];
        }
        this.filter.page = 0;
        this.loadFiles();
    }
}
