import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0060TagSlideComponent } from './m0060-tag-slide.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { A00020AttributeModule } from '../../atoms/a00020-attribute/a00020-attribute.module';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [M0060TagSlideComponent],
    imports: [
        CommonModule,
        NgbCollapseModule,
        A00020AttributeModule,
        HttpClientModule,
        InlineSVGModule.forRoot()
    ],
    exports: [M0060TagSlideComponent]
})
export class M0060TagSlideModule {}
