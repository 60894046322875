import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'px-a00280-overlay-lightbox',
    templateUrl: './a00280-overlay-lightbox.component.html',
    styleUrls: ['./a00280-overlay-lightbox.component.scss']
})
export class A00280OverlayLightboxComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
