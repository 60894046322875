import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LastNewsComponent } from './last-news.component';
import { T10NewsTileListModule } from '../../templates/t10-news-tile-list/t10-news-tile-list.module';
import { M0090NewsteaserTileBigModule } from '../../molecules/m0090-newsteaser-tile-big/m0090-newsteaser-tile-big.module';
import { A00250TextAndLineDividerModule } from '../../atoms/a00250-text-and-line-divider/a00250-text-and-line-divider.module';

@NgModule({
    declarations: [LastNewsComponent],
    imports: [
        CommonModule,
        T10NewsTileListModule,
        M0090NewsteaserTileBigModule,
        A00250TextAndLineDividerModule
    ]
})
export class LastNewsModule {}
