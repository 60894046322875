import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00060TertiaryButtonFullComponent } from './a00060-tertiary-button-full.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [A00060TertiaryButtonFullComponent],
    imports: [CommonModule, HttpClientModule, InlineSVGModule.forRoot()],
    exports: [A00060TertiaryButtonFullComponent]
})
export class A00060TertiaryButtonFullModule {}
