export enum O120Modifier {
    E = 'e'
}

export interface O120Image {
    mode: string;
    image?: string;
    imageTablet?: string;
    imageMobile?: string;
    ctaText?: string;
    ctaLink?: string;
    videotype?: string;
    mp4?: string;
    ctaLinkOverlay?: string;
    youtubeid?: string;
}
