import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
    selector: 'px-a00180-input-text',
    templateUrl: './a00180-input-text.component.html',
    styleUrls: ['./a00180-input-text.component.scss']
})
export class A00180InputTextComponent implements OnInit {
    @Input() inputName: string;
    @Input() placeholder: string;
    @Input() isSubmitted = false;
    @Input() required: boolean;
    @Input() error: string;

    @Input() inputControl: AbstractControl;

    constructor(public control: ControlContainer) {}

    ngOnInit(): void {}
}
