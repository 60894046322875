import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../services/api.service';
import {
    M0170Overlay,
    M0170OverlayType
} from '../../../interfaces/molecules/m0170-overlay';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DownloadCenterService } from '../../../services/download-center.service';
import { AssetFile } from '../../../interfaces/AssetFile';

@Component({
    selector: 'px-m0170-overlay',
    templateUrl: './m0170-overlay.component.html',
    styleUrls: ['./m0170-overlay.component.scss']
})
export class M0170OverlayComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<M0170OverlayComponent>,
        private readonly api: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: M0170Overlay,
        public breakpointObserver: BreakpointObserver,
        private downloadCenterService: DownloadCenterService
    ) {
        if (data.type === M0170OverlayType.A) {
            this.image = this.api
                .getAssetPreview(data.file.uid, 0, 500)
                .toUrl();
            this.downloadLink = this.api.getDownloadLink(data.file.uid);
            this.file = data.file;
        }
    }

    M0170OverlayType = M0170OverlayType;

    downloadLink = '';

    image = '';

    file: AssetFile;

    public isMobile: boolean;

    inDownloadCenter = false;

    toggleDownloadCenter() {
        this.downloadCenterService.addItem(this.file.uid, true);
        this.inDownloadCenter = !this.inDownloadCenter;
    }

    ngOnInit(): void {
        this.breakpointObserver
            .observe(['(min-width: 922px)'])
            .subscribe((state: BreakpointState) => {
                this.isMobile = !state.matches;
            });
        this.inDownloadCenter = this.downloadCenterService.itemExists(this.file.uid);
    }
}
