<div class="m0130">
    <ngb-accordion
        #acc="ngbAccordion"
        activeIds="ngb-panel-0"
        closeOthers="true"
    >
        <ng-container *ngFor="let chapter of chapters">
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <px-a00120-accordion
                        [headline]="chapter.headline"
                    ></px-a00120-accordion>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div [innerHTML]="chapter.content"></div>
                </ng-template>
            </ngb-panel>
        </ng-container>
    </ngb-accordion>
</div>
