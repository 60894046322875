
<px-m0180-video-teaser
[videotype]="content.videotype"
[size]="content.size"
[headline]="content.headline"
[headlineType]="content.headlineType"
[textColor]="content.textColor"
[text]="content.text"
[ctaType]="content.ctaType"
[youtubeid]="content.youtubeid"
[mp4]="content.mp4"
[bgColor]="content.bgColor"
[ctaLink]="content.ctaLink"
[ctaText]="content.ctaText"
></px-m0180-video-teaser>
