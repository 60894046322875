import { Component, Input, OnInit, ElementRef, HostListener } from '@angular/core';
import {
    A00100DownloadButtonLink,
    A00100DownloadButtonType
} from '../../../interfaces/atoms/a00100-download-button';
import { AssetFile } from '../../../interfaces/AssetFile';
import { MatDialog } from '@angular/material/dialog';
import {
    M0170Overlay,
    M0170OverlayType
} from '../../../interfaces/molecules/m0170-overlay';
import { M0170OverlayComponent } from '../../molecules/m0170-overlay/m0170-overlay.component';
import {DownloadCenterService} from "../../../services/download-center.service";

@Component({
    selector: 'px-a00100-download-button',
    templateUrl: './a00100-download-button.component.html',
    styleUrls: ['./a00100-download-button.component.scss']
})
export class A00100DownloadButtonComponent implements OnInit {
    @Input() modifier: A00100DownloadButtonType = A00100DownloadButtonType.A;
    @Input() title: string;
    @Input() downloads: A00100DownloadButtonLink[] = [];
    @Input() file: AssetFile;
    inDownloadCenter = false;
    public open = false;

    constructor(private dialog: MatDialog, private downloadCenterService: DownloadCenterService, private eRef: ElementRef) {}

    @HostListener('document:click', ['$event'])
    clickout(event: Event):void {
        if(this.eRef.nativeElement.contains(event.target)) {
            console.log('Inside');
        } else {
            console.log('Outside');
            setTimeout(() => {
                if (this.open) {
                    this.open = false;
                }
            }, 0);
        }
    }

    ngOnInit(): void {
        this.downloadCenterService.storageSubject.subscribe(() => {
            this.inDownloadCenter = this.downloadCenterService.itemExists(this.file.uid);
        });
    }

    openCtas() {
        setTimeout(() => {
            if (!this.open) {
                this.open = true;
            }
        }, 0);
    }

    toggleDownloadCenter() {
        this.downloadCenterService.addItem(this.file.uid, true);
        //this.inDownloadCenter = !this.inDownloadCenter;
    }

    openFileDetail() {
        const data: M0170Overlay = {
            type: M0170OverlayType.A,
            file: this.file
        };
        this.dialog.open(M0170OverlayComponent, {
            panelClass: 'm0170-cdk-overlay-pane',
            data
        });
    }
}
