import { Component, OnInit, Input } from '@angular/core';
import { Category } from 'src/app/interfaces/Category';
import { M0050FilterCardType } from 'src/app/interfaces/molecules/m0050-filter-card';
import { A00210InputCheckboxType } from 'src/app/interfaces/atoms/a00210-input-checkbox';
import { ApiService } from 'src/app/services/api.service';
import { O030FilterGroup } from 'src/app/interfaces/organisms/o030-filter';
import { M0070Tile } from 'src/app/interfaces/molecules/m0070-newsteaser-tile';
import { NewsDto } from 'src/app/interfaces/News';
import { O100ContactFooter } from '../../../interfaces/organisms/o100-contact-footer';

@Component({
    selector: 'px-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
    _historic = false;
    _blog = false;

    @Input()
    set historic(h: string) {
        this._historic = h === '1';
    }

    @Input()
    set blog(b: string) {
        this._blog = b === '1';
    }

    totalNewsCount = 0;

    news: NewsDto[] = [];

    tiles: M0070Tile[] = [];

    models: Category[] = [];

    modelYears: Category[] = [];

    tags: Category[] = [];

    years: Category[] = [];

    filterGroups: O030FilterGroup[] = [];

    filter: {
        models: number[];
        modelYears: number[],
        tags: number[];
        years: number[];
        page: number;
    } = {
        models: [],
        modelYears: [],
        tags: [],
        years: [],
        page: 0
    };

    _contactFooter: O100ContactFooter;

    @Input()
    set contactFooter(footer: string | O100ContactFooter) {
        if (typeof footer === 'string') {
            this._contactFooter = JSON.parse(footer);
        } else {
            this._contactFooter = footer;
        }
    }

    get contactFooter() {
        return this._contactFooter;
    }

    constructor(private api: ApiService) {}

    async ngOnInit(): Promise<void> {
        this.loadNews();
        this.filterGroups = [];
        const data = await this.api.getNewsCategories(this._historic, this._blog);
        this.models = data.models;
        this.modelYears = data.modelYears;
        this.tags = data.others;
        this.years = data.years;

        const filterGroups: O030FilterGroup[] = [];

        if (this.models.length > 0) {
            filterGroups.push({
                id: 'models',
                label: 'Modelle',
                filterCard: {
                    modifier: M0050FilterCardType.A,
                    tags: this.models.map((m) => ({
                        id: m.uid,
                        label: m.title,
                        isTop: m.isTop,
                        modifier: A00210InputCheckboxType.B
                    }))
                }
            });
        }

        if (this.modelYears.length > 0) {
            filterGroups.push({
                id: 'modelYears',
                label: 'Modelljahr',
                filterCard: {
                    modifier: M0050FilterCardType.A,
                    tags: this.modelYears.map((m) => ({
                        id: m.uid,
                        label: m.title,
                        isTop: m.isTop,
                        modifier: A00210InputCheckboxType.B
                    }))
                }
            });
        }

        if (this.tags.length > 0) {
            filterGroups.push({
                id: 'tags',
                label: 'Themen',
                filterCard: {
                    modifier: M0050FilterCardType.B,
                    tags: this.tags.map((t) => ({
                        id: t.uid,
                        label: t.title,
                        isTop: t.isTop,
                        modifier: A00210InputCheckboxType.B
                    }))
                }
            });
        }

        if (this.years.length) {
            filterGroups.push({
                id: 'years',
                label: 'Veröffentlichungsdatum',
                filterCard: {
                    modifier: M0050FilterCardType.A,
                    tags: this.years.map((y) => ({
                        id: y.uid,
                        label: y.title,
                        isTop: false,
                        modifier: A00210InputCheckboxType.B
                    }))
                }
            });
        }

        this.filterGroups = filterGroups;
    }

    async loadMore() {
        this.filter.page++;
        await this.loadNews();
    }

    onFilterChanged(filter: { [filter: string]: Array<string | number> }) {

        if (filter.models) {
            this.filter.models = filter.models.map((m) => parseInt(m + '', 10));
        } else {
            this.filter.models = [];
        }

        if (filter.modelYears) {
            this.filter.modelYears = filter.modelYears.map((m) => parseInt(m + '', 10));
        } else {
            this.filter.modelYears = [];
        }

        if (filter.tags) {
            this.filter.tags = filter.tags.map((t) => parseInt(t + '', 10));
        } else {
            this.filter.tags = [];
        }

        if (filter.years) {
            this.filter.years = filter.years.map((y) => parseInt(y + '', 10));
        } else {
            this.filter.years = [];
        }

        this.filter.page = 0;
        this.loadNews();
    }

    async loadNews() {
        const data = await this.api.getNews(
            this.filter.models,
            this.filter.modelYears,
            this.filter.tags,
            this.filter.years,
            this.filter.page,
            false,
            15,
            this._historic,
            this._blog
        );

        this.totalNewsCount = data.count;

        if (this.filter.page > 0) {
            this.news = [...this.news, ...data.news];
        } else {
            this.news = data.news;
        }

        this.tiles = this.news.map((n) => this.buildTile(n));
    }

    buildTile(news: NewsDto): M0070Tile {
        const assets = [];
        if (news.assetsDownloadCenter) {
            for (let asset of news.assetsDownloadCenter) {
                if (asset ) {
                    assets.push(asset);
                }
            }
        }


        return {
            uid: news.uid,
            date: new Date(news.date * 1000),
            headline: news.headline,
            copy: news.summary,
            img: this.api.getNewsPreview(news.uid, 'teaser'),
            tags: [
                ...news.models.map((t) => {
                    return {
                        modifier: '',
                        text: t.title
                    };
                }),
                ...news.modelYears.map((t) => {
                    return {
                        modifier: '',
                        text: t.title
                    };
                }),
                ...news.tags.map((t) => {
                    return {
                        modifier: '',
                        text: t.title
                    };
                })
            ],
            detailUrl: news.detailUrl,
            assets: assets
        };
    }
}
