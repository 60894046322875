import { M0100Pressfolder } from '../molecules/m0100-pressfolder';

export enum O070PressfolderType {
    A = 'a'
}

export interface O070Pressfolder {
    type: O070PressfolderType;
    tiles: M0100Pressfolder[];
}
