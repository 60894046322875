import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-a00250-text-and-line-divider',
    templateUrl: './a00250-text-and-line-divider.component.html',
    styleUrls: ['./a00250-text-and-line-divider.component.scss']
})
export class A00250TextAndLineDividerComponent implements OnInit {
    @Input() text: string;

    constructor() {}

    ngOnInit(): void {}
}
