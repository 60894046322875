import { Component, Input, OnInit } from '@angular/core';
import { MenuEntry } from '../../../interfaces/MenuEntry';

@Component({
    selector: 'px-a00290-footer-nav',
    templateUrl: './a00290-footer-nav.component.html',
    styleUrls: ['./a00290-footer-nav.component.scss']
})
export class A00290FooterNavComponent implements OnInit {
    @Input() menu: MenuEntry[] = [];

    constructor() {}

    ngOnInit(): void {}
}
