import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0180PodcastComponent } from './m0180-podcast.component';
import { A00320IconButtonModule } from '../../atoms/a00320-icon-button/a00320-icon-button.module'



@NgModule({
  declarations: [M0180PodcastComponent],
  imports: [
    CommonModule, A00320IconButtonModule
  ],
  exports: [M0180PodcastComponent]
})
export class M0180PodcastModule { }
