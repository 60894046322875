import {Component, Input, OnInit} from '@angular/core';

export interface A00300 {
    text: string;
    position?: A00300Position;
}

enum A00300Position {
    LEFT = 'LEFT',
    CENTER = 'CENTER',
    RIGHT = 'RIGHT'
}

@Component({
    selector: 'px-a00300-tooltip',
    templateUrl: './a00300-tooltip.component.html',
    styleUrls: ['./a00300-tooltip.component.scss']
})
export class A00300TooltipComponent implements OnInit {
    @Input() data?: A00300;

    constructor() {
    }

    ngOnInit(): void {
    }

}
