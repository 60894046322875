import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ElementRef,
    AfterViewInit
} from '@angular/core';
import { M0050FilterCardTag } from '../../../interfaces/molecules/m0050-filter-card';

@Component({
    selector: 'px-m0060-tag-slide',
    templateUrl: './m0060-tag-slide.component.html',
    styleUrls: ['./m0060-tag-slide.component.scss']
})
export class M0060TagSlideComponent implements OnInit, AfterViewInit {
    public isCollapsed = true;
    public sticky = false;

    @Input()
    selectedTags: Array<{
        tag: M0050FilterCardTag;
        filterId: string;
    }> = [];

    @Output()
    removeSelectedTag = new EventEmitter<{
        filterId: string;
        tagId: string | number;
    }>();

    @ViewChild('scrollWrapper') scrollWrapper: ElementRef;

    constructor() {}

    ngOnInit(): void {}
    ngAfterViewInit(): void {
        this.m0060Scroll();
    }

    public m0060Scroll() {
        const m0060position = this.scrollWrapper.nativeElement.scrollLeft;
        this.sticky = m0060position >= 47;
    }
}
