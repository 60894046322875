<div class="m0120 mobile">
    <div class="m0120__imageWrapper">
        <img [src]="image" [alt]="headline" />
    </div>
    <h3 class="m0120__headline">
        {{ headline }}
    </h3>
    <p class="m0120__text" [innerHTML]="text"></p>
</div>

<div class="m0120 desktop">
    <div class="content">
        <div *ngIf="imageright === '1'" class="row m0120__desktopContent">
            <div class="col-md-6 m0120__innerImage">
                <div class="m0120__imageWrapper">
                    <img [src]="image" [alt]="headline" />
                </div>
            </div>
            <div class="col-md-6 m0120__innerContent__right">
                <h3 class="m0120__headline">
                    {{ headline }}
                </h3>
                <p class="m0120__text" [innerHTML]="text"></p>
            </div>
        </div>

        <div *ngIf="imageright === '0'" class="row m0120__desktopContent">
            <div class="col-md-6 m0120__innerContent__left">
                <h3 class="m0120__headline">
                    {{ headline }}
                </h3>
                <p class="m0120__text" [innerHTML]="text"></p>
            </div>
            <div class="col-md-6 m0120__innerImage">
                <div class="m0120__imageWrapper">
                    <img [src]="image" [alt]="headline" />
                </div>
            </div>
        </div>
    </div>
</div>
