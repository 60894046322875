import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PressfolderComponent } from './pressfolder.component';
import { O070PressfolderModule } from '../../organisms/o070-pressfolder/o070-pressfolder.module';

@NgModule({
    declarations: [PressfolderComponent],
    exports: [
        PressfolderComponent
    ],
    imports: [CommonModule, O070PressfolderModule]
})
export class PressfolderModule {}
