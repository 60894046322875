import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O130SocialMediaComponent } from './o130-social-media.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InlineSVGModule } from 'ng-inline-svg';
import { VideoOverlayModule } from '../../helpers/video-overlay/video-overlay.module';
import {
    A00040PrimaryButtonIconModule
} from '../../atoms/a00040-primary-button-icon/a00040-primary-button-icon.module';

@NgModule({
    declarations: [O130SocialMediaComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        InlineSVGModule.forRoot(),
        VideoOverlayModule,
        A00040PrimaryButtonIconModule
    ],
    exports: [O130SocialMediaComponent]
})
export class O130SocialMediaModule {}
