import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { O110ContactBigComponent } from './o110-contact-big.component';
import { M0150ContactCardBigModule } from '../../molecules/m0150-contact-card-big/m0150-contact-card-big.module';

@NgModule({
    declarations: [O110ContactBigComponent],
    imports: [CommonModule, M0150ContactCardBigModule],
    exports: [O110ContactBigComponent]
})
export class O110ContactBigModule {}
