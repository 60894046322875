import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'px-m0010-keyvisual',
    templateUrl: './m0010-keyvisual.component.html',
    styleUrls: ['./m0010-keyvisual.component.scss']
})
export class M0010KeyvisualComponent implements OnInit {
    @Input() modifier: string;
    @Input() image: string;
    @Input() buttonFirstText: string;
    @Input() buttonFirstCta: string;
    @Input() buttonSecondText: string;
    @Input() buttonSecondCta: string;
    @Input() headline: string;
    @Input() headlineSecond: string;
    @Input() altTitle: string;

    constructor() {}

    ngOnInit(): void {
    }
}
