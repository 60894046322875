import { A00130UnderlineLink } from '../atoms/a00130-underline-link';

export enum M0020LinkListsType {
    A = 'a',
    B = 'b',
    C = 'c'
}

interface M0020LinkListsA {
    modifier: M0020LinkListsType.A;
    links: A00130UnderlineLink[];
}

interface M0020LinkListsB {
    modifier: M0020LinkListsType.B;
    links: A00130UnderlineLink[];
}

interface M0020LinkListsC {
    modifier: M0020LinkListsType.C;
    links: A00130UnderlineLink[];
}

export type M0020LinkLists =
    | M0020LinkListsA
    | M0020LinkListsB
    | M0020LinkListsC;
