<div class="row full-width" [ngStyle]="{'background-color': bgColor}" >
    <div class="container o-120__container o-120__container--e o-120__textColor--{{textColor}}"
        (swipeleft)="nextSlide()"
        (swiperight)="prevSlide()">
        <div class="o-120 o-120--e">
            <div class="o-120__track" [ngStyle]="trackStyle">
                <ng-container *ngFor="let slide of images">
                    <div class="o-120__slide o-120--e__slide">
                            <div class="m-0010 m-0010--{{slide.mode === 'video' ? 'c' : 'a'}}">
                                <div class="m-0010__image">
                                    <ng-container *ngIf="slide.mode === 'image'">
                                        <a [href]="slide.ctaLink">
                                            <ng-container [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{ slide: slide }"></ng-container>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="slide.mode === 'video'">
                                        <div class="a-00030 a-00030--c">
                                            <ng-container *ngIf="slide.externalurl">
                                                <a [href]="slide.externalurl">
                                                    <ng-container [ngTemplateOutlet]="prevImg"
                                                    [ngTemplateOutletContext]="{ slide: slide }"></ng-container>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngIf="!slide.externalurl">
                                                <a class="cursor-pointer" (click)="openDialog(slide.youtubeid, slide.mp4)">
                                                    <ng-container [ngTemplateOutlet]="prevImg"
                                                    [ngTemplateOutletContext]="{ slide: slide }"></ng-container>
                                                </a>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="m-0010__cta">
                                    <div class="a-00210 a-00210--c">
                                        <ng-container *ngIf="slide.externalurl || slide.ctaLink">
                                            <a class="link" [href]="slide.externalurl ? slide.externalurl : slide.ctaLink">
                                                <span>{{slide.ctaText}}</span>
                                                <div class="arrowRight" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/pfeil_klein_rot.svg'">
                                                </div>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="!slide.externalurl && !slide.ctaLink">
                                            <a class="link" (click)="openDialog(slide.youtubeid, slide.mp4)">
                                                <span>{{slide.ctaText}}</span>
                                                <div class="arrowRight" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/pfeil_klein_rot.svg'">
                                                </div>
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                </ng-container>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="prevArrow"></ng-container>
        <ng-container [ngTemplateOutlet]="nextArrow"></ng-container>
    </div>
</div>

<ng-template #prevImg let-slide="slide">
    <ng-container [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{ slide: slide }"></ng-container>
    <div class="icon" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_player.svg'"></div>
</ng-template>

<ng-template #image let-slide="slide">
    <img class="img-fluid mobile-only"
    alt="{{slide.ctaText}}"
    title="{{slide.ctaText}}"
    src="{{slide.imageMobile}}"
    *ngIf="slide.imageMobile">

    <img class="img-fluid "
    alt="{{slide.ctaText}}"
    title="{{slide.ctaText}}"
    src="{{slide.imageTablet}}"
    *ngIf="slide.imageTablet"
    [ngClass]="{'tablet': slide.imageMobile, 'mobile': slide.imageMobile}">

    <img class="img-fluid"
    alt="{{slide.ctaText}}"
    title="{{slide.ctaText}}"
    src="{{slide.image}}"
    [ngClass]="{'desktop': slide.imageTablet , 'from-tablet': !slide.imageTablet && slide.imageMobile}">
</ng-template>

<ng-template #prevArrow>
    <div class="o-120__sliderBtn o-120__sliderBtn--mobile o-120__sliderBtn--prev o-120__sliderBtn--prev-e"
    [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_half.svg'"
    (click)="prevSlide()">
    </div>
    <div class="o-120__sliderBtn o-120__sliderBtn--prev o-120__sliderBtn--prev-e"
    [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_full.svg'"
    (click)="prevSlide()">
    </div>
</ng-template>

<ng-template #nextArrow>
    <div class="o-120__sliderBtn o-120__sliderBtn--mobile o-120__sliderBtn--next o-120__sliderBtn--next-e"
    [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_half.svg'"
    (click)="nextSlide()">
    </div>
    <div class="o-120__sliderBtn o-120__sliderBtn--next o-120__sliderBtn--next-e"
    [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/icn_navigation_full.svg'"
    (click)="nextSlide()">
    </div>
</ng-template>
