import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FixedImageGridComponent} from "./fixed-image-grid.component";
import { O120SliderModule } from '../../organisms/o120-slider/o120-slider.module';

@NgModule({
    declarations: [FixedImageGridComponent],
    imports: [CommonModule, O120SliderModule]
})
export class FixedImageGridModule {}
