<div class="container o100">
    <px-a00250-text-and-line-divider
        text="Schnellkontakt"
    ></px-a00250-text-and-line-divider>
    <div class="row">
        <div class="col-12 col-lg-8 contacts">
            <ng-container *ngFor="let employee of employees">
                <px-m0140-contact-card-small
                    [image]="employee.image"
                    [forename]="employee.forename"
                    [surname]="employee.surname"
                    [jobTitle]="employee.jobTitle"
                    [email]="employee.email"
                    [phone]="employee.phone"
                ></px-m0140-contact-card-small>
            </ng-container>
        </div>
        <div class="col-12 col-lg-4 pressMailerCta">
            <h3 class="headline">{{ teaserHeadline }}</h3>
            <p class="text">{{ teaserDescription }}</p>
            <px-a00040-primary-button-icon
                [link]="teaserLink"
                [text]="teaserLinkName"
                [modifier]="A00040PrimaryButtonIconType.A"
            ></px-a00040-primary-button-icon>
        </div>
    </div>
</div>
