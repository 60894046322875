import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00130UnderlineLinkComponent } from './a00130-underline-link.component';

@NgModule({
    declarations: [A00130UnderlineLinkComponent],
    imports: [CommonModule],
    exports: [A00130UnderlineLinkComponent]
})
export class A00130UnderlineLinkModule {}
