import { AssetFile } from '../AssetFile';

export enum M0170OverlayType {
    A = 'a',
    B = 'b'
}

interface M0170OverlayA {
    type: M0170OverlayType.A;
    file: AssetFile;
}

interface M0170OverlayB {
    type: M0170OverlayType.B;
}

export type M0170Overlay = M0170OverlayA | M0170OverlayB;
