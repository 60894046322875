import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A00250TextAndLineDividerComponent } from './a00250-text-and-line-divider.component';

@NgModule({
    declarations: [A00250TextAndLineDividerComponent],
    imports: [CommonModule],
    exports: [A00250TextAndLineDividerComponent]
})
export class A00250TextAndLineDividerModule {}
