import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs/operators';
import { Category } from '../interfaces/Category';
import {
    AssetFile,
    AssetType,
    UpdateFiles,
    UploadFiles
} from '../interfaces/AssetFile';
import { FormSubmit } from '../interfaces/Wamtek/FormSubmit';
import { FormEntries } from '../interfaces/Wamtek/FormEntries';
import { PressMailerResponse } from '../components/apps/press-mailer/press-mailer.component';
import { News, NewsDto, NewsSaveDto } from '../interfaces/News';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { EnvkvVehicle } from '../interfaces/EnvkvVehicle';
import { EnvkvModel } from '../interfaces/EnvkvModel';

export interface ApiResponseError {
    error: true;
    message: string;
}

export interface ApiResponseSuccess<T> {
    error: false;
    data: T;
}

export type ApiResponse<T> = ApiResponseSuccess<T> | ApiResponseError;

export const enum ApiErrorType {
    HTTP,
    API
}

export class ApiError extends Error {
    constructor(message: string, public type: ApiErrorType = ApiErrorType.API) {
        super(message);
    }
}

// tslint:disable-next-line:no-any
export function isApiError(arg: any): arg is ApiError {
    return typeof arg === 'object' && arg instanceof ApiError;
}

export class Image {
    constructor(public url: string, public width = 0, public height = 0) {}

    toUrl(width = 0, height = 0): string {
        const w = width > 0 ? width : this.width;
        const h = height > 0 ? height : this.height;
        let url = this.url;
        if (w > 0 || h > 0) {
            url += '?';
        }

        if (w > 0) {
            url += 'width=' + w;
        }

        if (h > 0) {
            if (w > 0) {
                url += '&';
            }
            url += 'height=' + h;
        }

        return url;
    }

    toString(): string {
        return this.toUrl();
    }
}

export class NewsImage extends Image {
    constructor(
        public baseUrl: string,
        public width = 0,
        public height = 0,
        public field = ''
    ) {
        super(baseUrl, width, height);
    }

    toUrl(width = 0, height = 0, field = ''): string {
        const f = field ? field : this.field;
        this.url = this.baseUrl + `/${f}/show`;
        return super.toUrl(width, height);
    }
}

export interface AssetFilter {
    model: number;
    modelYear: number;
    tag: number;
    dateFrom: Date;
    dateTo: Date;
}

export class FileDataSource extends DataSource<AssetFile> {
    dataStream = new BehaviorSubject<Array<AssetFile | undefined>>([]);
    private _pageSize = 10;
    private _cachedData: AssetFile[] = [];
    private _fetchedPages = new Set<number>();
    private _sub = new Subscription();
    private _filter: AssetFilter;

    constructor(private apiService: ApiService) {
        super();
    }

    connect(
        collectionViewer: CollectionViewer
    ): Observable<Array<AssetFile | undefined>> {
        console.log('collection viewer', collectionViewer);
        this._sub.add(
            collectionViewer.viewChange.subscribe((range) => {
                console.log('range', range);
                const startPage = this._getPageForIndex(range.start);
                const endPage = this._getPageForIndex(range.end);
                console.log('start', startPage, 'end', endPage);
                for (let i = startPage; i <= endPage; i++) {
                    this._fetchPage(i);
                }
            })
        );
        return this.dataStream;
    }

    disconnect(): void {
        this._sub.unsubscribe();
    }

    private async _fetchPage(page: number) {
        console.log('fetch page', page);
        if (this._fetchedPages.has(page)) {
            return;
        }
        this._fetchedPages.add(page);
        const models = [];
        const tags = [];
        if (this._filter && this._filter.model) {
            models.push(this._filter.model);
        }
        if (this._filter && this._filter.tag) {
            tags.push(this._filter.tag);
        }
        const dateFrom = this._filter.dateFrom
            ? Math.floor(this._filter.dateFrom.getTime() / 1000)
            : 0;
        const dateTo = this._filter.dateTo
            ? Math.floor(this._filter.dateTo.getTime() / 1000)
            : 0;
        const files = await this.apiService.backendGetFiles(
            models,
            tags,
            dateFrom,
            dateTo,
            page
        );
        console.log('got files', files);
        this._cachedData.splice(page * this._pageSize, files.length, ...files);
        console.log('cache', this._cachedData);
        this.dataStream.next(this._cachedData);
    }

    private _getPageForIndex(index: number): number {
        return Math.floor(index / this._pageSize);
    }

    public setFilter(filter: AssetFilter) {
        this._filter = filter;
    }

    public async reset() {
        console.log('reset');
        this._cachedData = [];
        this._fetchedPages.clear();
        this.dataStream.next([]);
        await this._fetchPage(0);
    }
}

export class NewsDataSource extends DataSource<News> {
    dataStream = new BehaviorSubject<Array<News | undefined>>([]);
    private _pageSize = 10;
    private _cachedData: News[] = [];
    private _fetchedPages = new Set<number>();
    private _sub = new Subscription();
    private _filter: AssetFilter = {
        model: 0,
        modelYear: 0,
        tag: 0,
        dateFrom: null,
        dateTo: null
    };

    constructor(private apiService: ApiService) {
        super();
    }

    connect(
        collectionViewer: CollectionViewer
    ): Observable<Array<News | undefined>> {
        console.log('collection viewer', collectionViewer);
        this._sub.add(
            collectionViewer.viewChange.subscribe((range) => {
                console.log('range', range);
                const startPage = this._getPageForIndex(range.start);
                const endPage = this._getPageForIndex(range.end);
                console.log('start', startPage, 'end', endPage);
                for (let i = startPage; i <= endPage; i++) {
                    this._fetchPage(i);
                }
            })
        );
        return this.dataStream;
    }

    disconnect(): void {
        this._sub.unsubscribe();
    }

    private async _fetchPage(page: number) {
        console.log('fetch page', page);
        if (this._fetchedPages.has(page)) {
            return;
        }
        this._fetchedPages.add(page);
        const models = [];
        const tags = [];
        if (this._filter.model) {
            models.push(this._filter.model);
        }
        if (this._filter.tag) {
            tags.push(this._filter.tag);
        }
        const dateFrom = this._filter.dateFrom
            ? Math.floor(this._filter.dateFrom.getTime() / 1000)
            : 0;
        const dateTo = this._filter.dateTo
            ? Math.floor(this._filter.dateTo.getTime() / 1000)
            : 0;
        const files = await this.apiService.backendGetNewsList(
            models,
            tags,
            dateFrom,
            dateTo,
            page
        );
        console.log('got files', files);
        this._cachedData.splice(page * this._pageSize, files.length, ...files);
        console.log('cache', this._cachedData);
        this.dataStream.next(this._cachedData);
    }

    private _getPageForIndex(index: number): number {
        return Math.floor(index / this._pageSize);
    }

    public setFilter(filter: AssetFilter) {
        this._filter = filter;
    }

    public async reset() {
        console.log('reset');
        this._cachedData = [];
        this._fetchedPages.clear();
        this.dataStream.next([]);
        await this._fetchPage(0);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {}

    public static async toPromise<T>(
        observable$: Observable<ApiResponse<T>>
    ): Promise<T> {
        let res: ApiResponse<T>;

        try {
            res = await observable$.toPromise();
        } catch (err) {
            console.log('ERROR =>', err);
            if (err instanceof ErrorEvent) {
                throw new ApiError(err.error.message, ApiErrorType.HTTP);
            }

            throw new ApiError(err.error, ApiErrorType.HTTP);
        }

        if (res.error === false) {
            return res.data;
        }
        throw new Error(res.message);
    }

    async get<T>(
        endpoint: string,
        args: { [key: string]: string | string[] } | HttpParams = {}
    ): Promise<T> {
        return ApiService.toPromise(
            this.http
                .get<ApiResponse<T>>(environment.apiUrl + endpoint, {
                    params: args
                })
                .pipe(retry(2))
        );
    }

    async post<T>(endpoint: string, args: object | FormData = {}): Promise<T> {
        return ApiService.toPromise(
            this.http
                .post<ApiResponse<T>>(environment.apiUrl + endpoint, args)
                .pipe(retry(2))
        );
    }

    async backendGetAllAssetCategories() {
        return this.get<{
            models: Category[];
            modelYears: Category[];
            others: Category[];
        }>('asset-manager/categories');
    }

    async backendUpdateFiles(
        updateFiles: UpdateFiles,
        videoThumb: File = null
    ) {
        const formData = new FormData();
        if (videoThumb) {
            formData.append('videoThumb', videoThumb);
        }
        formData.set(
            'files',
            new Blob([JSON.stringify(updateFiles)], {
                type: 'application/json'
            })
        );
        return this.post<boolean>('asset-manager/update-files', formData);
    }

    async backendUploadFiles(
        files: File[],
        data: UploadFiles,
        videoThumb: File = null
    ) {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files[]', file, file.name);
        }
        if (videoThumb) {
            formData.append('videoThumb', videoThumb);
        }
        formData.set(
            'data',
            new Blob([JSON.stringify(data)], { type: 'application/json' })
        );
        return this.post<boolean>('asset-manager/upload-files', formData);
    }

    async backendGetFiles(
        models: number[],
        tags: number[],
        dateFrom: number,
        dateTo: number,
        page: number = 0
    ) {
        let formData = new HttpParams();
        for (const model of models) {
            formData = formData.append('models[]', model + '');
        }
        for (const tag of tags) {
            formData = formData.append('tags[]', tag + '');
        }
        formData = formData
            .set('dateFrom', dateFrom + '')
            .set('dateTo', dateTo + '')
            .set('page', page + '');

        console.log(formData);
        return this.get<AssetFile[]>('asset-manager/files/list', formData);
    }

    getFormEntries(): Observable<FormEntries> {
        return from(this.get<FormEntries>('forms/formEntries'));
    }

    async postSubmitPressMailerForm(
        formSubmit: FormSubmit
    ): Promise<PressMailerResponse[]> {
        return this.post<PressMailerResponse[]>('forms/submitForm', formSubmit);
    }

    async removeAllCategories(uids: number[]) {
        const formData = new FormData();
        for (const uid of uids) {
            formData.append('uids[]', uid + '');
        }
        return this.post<boolean>('asset-manager/remove-categories', formData);
    }

    async backendDeleteFiles(uids: number[]) {
        const formData = new FormData();
        for (const uid of uids) {
            formData.append('uids[]', uid + '');
        }
        return this.post<boolean>('asset-manager/delete-files', formData);
    }

    async backendGetAllNewsCategories() {
        return this.get<{
            models: Category[];
            modelYears: Category[];
            others: Category[];
        }>('news-manager/categories');
    }

    async backendGetNewsList(
        models: number[],
        tags: number[],
        dateFrom: number,
        dateTo: number,
        page: number = 0
    ): Promise<News[]> {
        let formData = new HttpParams();
        for (const model of models) {
            formData = formData.append('models[]', model + '');
        }
        for (const tag of tags) {
            formData = formData.append('tags[]', tag + '');
        }
        formData = formData
            .set('dateFrom', dateFrom + '')
            .set('dateTo', dateTo + '')
            .set('page', page + '')
            .set('t', Date.now() + '');

        console.log(formData);
        return (await this.get<NewsDto[]>('news-manager/list', formData)).map(
            (n) => {
                return {
                    ...n,
                    date: new Date(n.date * 1000),
                    related: (n.related || []).map((r) => ({
                        ...r,
                        date: new Date(r.date * 1000)
                    }))
                };
            }
        );
    }

    async backendSaveNews(
        news: NewsSaveDto,
        teaserImage?: File,
        headerImage?: File
    ) {
        const formData = new FormData();
        if (
            teaserImage &&
            typeof teaserImage === 'object' &&
            teaserImage instanceof File
        ) {
            formData.set('teaserImage', teaserImage, teaserImage.name);
        }
        if (
            headerImage &&
            typeof headerImage === 'object' &&
            headerImage instanceof File
        ) {
            formData.set('headerImage', headerImage, headerImage.name);
        }
        formData.set(
            'data',
            new Blob([JSON.stringify(news)], { type: 'application/json' })
        );
        return this.post('news-manager/save', formData);
    }

    async backendGetNewsDetail(uid: number): Promise<News> {
        const news = await this.get<NewsDto>(`news-manager/${uid}/detail`, {
            t: Date.now() + ''
        });

        return {
            ...news,
            date: new Date(news.date * 1000),
            related: (news.related || []).map((r) => ({
                ...r,
                date: new Date(r.date * 1000)
            }))
        };
    }

    async getAllModelsYearsList(): Promise<EnvkvModel[]> {
        return await this.get<EnvkvModel[]>(`news-manager/modelYearsList`);
    }

    async getAllVehiclesYearsList(): Promise<EnvkvVehicle[]> {
        return await this.get<EnvkvVehicle[]>(`news-manager/vehicleYearsList`);
    }

    async removeNews(uid: number) {
        return this.post<boolean>(`news-manager/${uid}/delete`);
    }

    async getNews(
        models: number[],
        modelYears: number[],
        tags: number[],
        years: number[],
        page: number = 0,
        mobile: boolean = false,
        maxNews?: number,
        historic = false,
        blog = false
    ) {
        let formData = new HttpParams();

        for (const model of models) {
            formData = formData.append('models[]', model + '');
        }

        for (const modelYear of modelYears) {
            formData = formData.append('modelYears[]', modelYear + '');
        }

        for (const tag of tags) {
            formData = formData.append('tags[]', tag + '');
        }

        for (const year of years) {
            formData = formData.append('years[]', year + '');
        }

        let pageSize = 15;
        if (page === 0 && mobile) {
            pageSize = 3;
        }

        let offset = page * pageSize;
        if (mobile && page > 0) {
            offset = 3 + page * pageSize;
        }

        const limit = maxNews ? maxNews : pageSize;

        formData = formData.set('offset', offset + '');
        formData = formData.set('limit', limit + '');

        if (blog) {
            return this.get<{
                count: number;
                news: NewsDto[];
            }>('news/list' +  '/blog', formData);
        }
        return this.get<{
            count: number;
            news: NewsDto[];
        }>('news/list' + (historic ? '/historic' : ''), formData);
    }

    async getNewsDetail(uid: number): Promise<{
        news: News;
        aboutMitsubishi?: {
            headline: string;
            content: string;
        };
        envkvMitsubishi?: string;
    }> {
        const ret = await this.get<{
            news: NewsDto;
            aboutMitsubishi?: {
                headline: string;
                content: string;
            };
            envkvMitsubishi?: string;
        }>(`news/${uid}/detail`);

        return {
            aboutMitsubishi: ret.aboutMitsubishi,
            envkvMitsubishi: ret.envkvMitsubishi,
            news: {
                ...ret.news,
                date: new Date(ret.news.date * 1000),
                related: (ret.news.related || []).map((r) => ({
                    ...r,
                    date: new Date(r.date * 1000)
                }))
            }
        };
    }

    async getFiles(
        models: number[],
        modelYears: number[],
        tags: number[],
        years: number[],
        types: AssetType[],
        page = 0,
        mobile = false,
        historic = false
    ) {
        let formData = new HttpParams();

        for (const model of models) {
            formData = formData.append('models[]', model + '');
        }

        for (const modelYear of modelYears) {
            formData = formData.append('modelYears[]', modelYear + '');
        }

        for (const tag of tags) {
            formData = formData.append('tags[]', tag + '');
        }

        for (const type of types) {
            formData = formData.append('types[]', type);
        }

        for (const year of years) {
            formData = formData.append('years[]', year + '');
        }

        let pageSize = 12;
        if (page === 0 && mobile) {
            pageSize = 3;
        }

        let offset = page * pageSize;
        if (mobile && page > 0) {
            offset = 3 + page * pageSize;
        }

        formData = formData.set('offset', offset + '');
        formData = formData.set('limit', pageSize + '');

        return this.get<{
            count: number;
            files: AssetFile[];
        }>('asset/files/list' + (historic ? '/historic' : ''), formData);
    }

    async getAssetCategories(historic = false) {
        return this.get<{
            models: Category[];
            modelYears: Category[];
            others: Category[];
            years: Category[];
            types: Array<{
                id: string;
                name: string;
            }>;
        }>('asset/categories' + (historic ? '/historic' : ''));
    }

    async getNewsCategories(historic = false, blog = false) {
        if (blog) {
            return this.get<{
                models: Category[];
                modelYears: Category[];
                others: Category[];
                years: Category[];
            }>('news/categories' + '/blog');
        }
        return this.get<{
            models: Category[];
            modelYears: Category[];
            others: Category[];
            years: Category[];
        }>('news/categories' + (historic ? '/historic' : ''));
    }

    getDownloadLink(fileUid: number, variant = 0) {
        let url = `${environment.apiUrl}asset/${fileUid}/download`;

        if (variant > 0) {
            url += '/' + variant;
        }

        return url;
    }

    getZipDownloadLink(files: Array<{ file: number; variant?: number }>) {
        const url = `${environment.apiUrl}asset/download-zip?`;

        const fileParams: string[] = [];
        for (const file of files) {
            fileParams.push(
                'files[]=' +
                    file.file +
                    (file?.variant > 0 ? '|' + file.variant : '')
            );
        }

        return url + fileParams.join('&');
    }

    getAssetPreview(fileUid: number, width = 0, height = 0) {
        const url = `${environment.apiUrl}asset/${fileUid}/show`;

        return new Image(url, width, height);
    }

    getNewsPreview(newsUid: number, field: string, width = 0, height = 0) {
        const url = `${environment.apiUrl}news/${newsUid}`;

        return new NewsImage(url, width, height, field);
    }

    async getFilesDownloadCenter(uids: number[]) {
        let formData = new HttpParams();

        for (const uid of uids) {
            formData = formData.append('files[]', uid + '');
        }

        return this.get<{
            count: number;
            files: AssetFile[];
        }>('asset/files/list-download-center', formData);
    }
}
