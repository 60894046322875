<div *ngIf="news && form" class="news-edit">
    <form [formGroup]="form" (submit)="save()">
        <h1 mat-dialog-title>
            {{ news.uid === 0 ? 'Neue News anlegen' : 'News bearbeiten' }}
        </h1>
        <mat-dialog-content>
            <mat-form-field>
                <mat-label>Variante</mat-label>
                <mat-select formControlName="type">
                    <mat-option [value]="NewsType.NEWS">News</mat-option>
                    <mat-option [value]="NewsType.PRESSFOLDER_1">
                        Launch-Mappe
                    </mat-option>
                    <mat-option [value]="NewsType.PRESSFOLDER_2">
                        Testwagen-Mappe
                    </mat-option>
                    <mat-option [value]="NewsType.PAGE">
                        Blogbeitrag
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <mat-checkbox formControlName="hide">
                    In der Nutzerausgabe Ausblenden
                </mat-checkbox>
            </div>
            <div>
                <p>Teaser Bild*</p>
                <div
                    *ngIf="
                        (form.controls.teaserImage.value ||
                            selectedTeaserAsset) &&
                        !showCropper
                    "
                    class="news-edit__imgWrapper"
                >
                    <button
                        class="news-edit__imgBtn"
                        type="button"
                        (click)="
                            $event.preventDefault();
                            form.controls.teaserImage.setValue('');
                            cropperPosition.next(null);
                            selectedTeaserAsset = null
                        "
                    >
                        X
                    </button>
                    <button
                        class="news-edit__imgBtnCrop"
                        type="button"
                        (click)="showCroppingTool()"
                    >
                        Crop
                    </button>
                    <ng-container *ngIf="cropperPosition | async; let crop">
                        <px-cropped-area-overlay
                            *ngIf="teaserImageSize"
                            color="grey"
                            [opacity]="0.5"
                            [x1]="crop.x1"
                            [y1]="crop.y1"
                            [x2]="crop.x2"
                            [y2]="crop.y2"
                            [width]="teaserImageSize.width"
                            [height]="teaserImageSize.height"
                        >
                            <img height="200" [src]="teaserImageUrl" alt="" />
                        </px-cropped-area-overlay>
                    </ng-container>
                    <img
                        *ngIf="!(cropperPosition | async)"
                        height="200"
                        [src]="teaserImageUrl"
                        alt=""
                    />
                </div>
                <div
                    *ngIf="
                        (form.controls.teaserImage.value ||
                            selectedTeaserAsset) &&
                        showCropper
                    "
                    class="news-edit__imgWrapper"
                >
                    <button (click)="saveCropping()">Save Cropping</button>
                    <image-cropper
                        [imageURL]="teaserImageUrl"
                        [cropper]="relativeCropperPosition | async"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="1280 / 558"
                        (imageLoaded)="imageLoaded($event)"
                        (cropperReady)="cropperLoaded($event)"
                        (imageCropped)="imageCropped($event)"
                    ></image-cropper>
                </div>
                <div
                    *ngIf="
                        !form.controls.teaserImage.value && !selectedTeaserAsset
                    "
                >
                    <input type="file" (change)="onTeaserImageChange($event)" />
                </div>
            </div>
            <!-- <div>
                <p>Header Bild</p>
                <div *ngIf="form.controls.headerImage.value">
                    <button
                        type="button"
                        (click)="
                            $event.preventDefault();
                            form.controls.headerImage.setValue('')
                        "
                    >
                        X
                    </button>
                    <img height="200" [src]="headerImageUrl" alt="" />
                </div>
                <div *ngIf="!form.controls.headerImage.value">
                    <input type="file" (change)="onHeaderImageChange($event)" />
                </div>
            </div> -->
            <mat-form-field class="full">
                <mat-label>Überschrift*</mat-label>
                <input
                    matInput
                    #headline
                    maxlength="250"
                    formControlName="headline"
                    [errorStateMatcher]="matcher"
                />
                <mat-hint align="end">
                    {{ headline.value.length }} /
                    {{ headline.maxLength }}
                </mat-hint>
                <mat-error *ngIf="form.controls.headline?.errors?.maxlength">
                    Überschrift darf nur
                    <strong>{{ headline.maxLength }}</strong>
                    Zeichen lang sein
                </mat-error>
            </mat-form-field>
            <mat-form-field
                *ngIf="
                    form.controls.type.value === NewsType.NEWS ||
                    form.controls.type.value === NewsType.PRESSFOLDER_2
                "
                class="full"
            >
                <mat-label>Zusammenfassung</mat-label>
                <input
                    matInput
                    #summary
                    maxlength="250"
                    formControlName="summary"
                    [errorStateMatcher]="matcher"
                />
                <mat-hint align="end">
                    {{ summary.value.length }} /
                    {{ summary.maxLength }}
                </mat-hint>
                <mat-error *ngIf="form.controls.summary?.errors?.maxlength">
                    Zusammenfassung darf nur
                    <strong>{{ summary.maxLength }}</strong>
                    Zeichen lang sein
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Datum</mat-label>
                <input
                    matInput
                    [matDatepicker]="date"
                    formControlName="date"
                    [errorStateMatcher]="matcher"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="date"
                ></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxLayout="row" *ngIf="form.controls.type.value === NewsType.PAGE">
                <mat-label>Page (ID)</mat-label>
                <input
                    matInput
                    #pageId
                    maxlength="10"
                    formControlName="pageId"
                    [errorStateMatcher]="matcher"
                />
                <mat-hint align="end">
                    {{ pageId.value.length }} /
                    {{ pageId.maxLength }}
                </mat-hint>
                <mat-error *ngIf="form.controls.headline?.errors?.maxlength">
                    Page ID darf nur
                    <strong>{{ pageId.maxLength }}</strong>
                    Zeichen lang sein
                </mat-error>
            </mat-form-field>
            <ckeditor
                *ngIf="
                    form.controls.type.value === NewsType.NEWS ||
                    form.controls.type.value === NewsType.PRESSFOLDER_2
                "
                [editor]="editor"
                [config]="editorConfig"
                formControlName="content"
            ></ckeditor>
            <hr />
            <h2 class="tagsHeader">Tags ergänzen</h2>
            <div fxLayout="row" fxLayoutAlign="space-between flex-end">
                <div fxFlex="50">
                </div>
                <div fxFlex="50">
                    <h4 class="news-edit__noSpace">Themen</h4>
                    <mat-form-field class="searchForm">
                        <input
                            matInput
                            #tagFilterInput
                            (keyup)="
                                tagSearch = tagFilterInput.value; filterTags()
                            "
                        />
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" class="news-edit__spaceAfter">
                <div fxFlex="50">
                    <h4>Modell</h4>
                    <div fxLayout="row" fxLayoutAlign="space-between end">
                        <div fxFlex="40">
                            <select multiple class="select-list">
                                <option
                                    *ngFor="let model of models"
                                    (click)="toggleModel(model)"
                                >
                                    {{ model.title }}
                                </option>
                            </select>
                        </div>
                        <div fxFlex="40">
                            <select multiple class="select-list">
                                <option
                                    *ngFor="let model of selectedModels"
                                    (click)="toggleModel(model)"
                                >
                                    {{ model.title }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div fxFlex="50">
                    <h4>Tags</h4>
                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <div fxFlex="40">
                            <select multiple class="select-list">
                                <option
                                    *ngFor="let tag of filteredTags"
                                    (click)="toggleTag(tag)"
                                >
                                    {{ tag.title }}
                                </option>
                            </select>
                        </div>
                        <div fxFlex="40">
                            <select multiple class="select-list">
                                <option
                                    *ngFor="let tag of selectedTags"
                                    (click)="toggleTag(tag)"
                                >
                                    {{ tag.title }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div fxFlex="50">
                    <h4>Modelljahr</h4>
                    <div fxLayout="row" fxLayoutAlign="space-between end">
                        <div fxFlex="40">
                            <select multiple class="select-list">
                                <option
                                    *ngFor="let modelYear of modelYears"
                                    (click)="toggleModelYear(modelYear)"
                                >
                                    {{ modelYear.title }}
                                </option>
                            </select>
                        </div>
                        <div fxFlex="40">
                            <select multiple class="select-list">
                                <option
                                    *ngFor="let modelYear of selectedModelYears"
                                    (click)="toggleModelYear(modelYear)"
                                >
                                    {{ modelYear.title }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div
                *ngIf="form.controls.type.value === NewsType.PRESSFOLDER_2"
                class="news-edit__spaceAfter"
            >
                <h2>Kapitel:</h2>
                <div
                    class="news-edit__spaceAfter"
                    *ngFor="
                        let chapter of (form.controls.chapters | formArrayCast)
                            .controls;
                        let i = index
                    "
                    [formGroup]="chapter | formGroupCast"
                >
                    <button
                        class="news-edit__spaceAfter--small"
                        type="button"
                        (click)="$event.preventDefault(); removeChapter(i)"
                    >
                        Löschen
                    </button>
                    <mat-form-field class="full">
                        <mat-label>Überschrift</mat-label>
                        <input
                            matInput
                            #headline
                            maxlength="55"
                            formControlName="headline"
                            [errorStateMatcher]="matcher"
                        />
                        <mat-hint align="end">
                            {{ headline.value.length }} /
                            {{ headline.maxLength }}
                        </mat-hint>
                        <mat-error
                            *ngIf="form.controls.headline?.errors?.maxlength"
                        >
                            Überschrift darf nur
                            <strong>{{ headline.maxLength }}</strong>
                            Zeichen lang sein
                        </mat-error>
                    </mat-form-field>
                    <ckeditor
                        [editor]="editor"
                        [config]="editorConfig"
                        formControlName="content"
                    ></ckeditor>
                </div>
                <button
                    type="button"
                    (click)="$event.preventDefault(); addNewChapter()"
                >
                    Neues Kapitel Hinzufügen
                </button>
            </div>
            <p>* Pflichtfelder</p>
            <div class="news-edit__spaceAfter">
                <h2>Ähnliche News</h2>
                <cdk-virtual-scroll-viewport
                    itemSize="60"
                    style="height: 300px"
                >
                    <div
                        class="file news-edit__otherNewsItem"
                        *cdkVirtualFor="let news of newsDataSource"
                        style="height: 60px"
                    >
                        <div
                            class="list-item"
                            fxLayout="row"
                            fxLayoutAlign="space-between"
                        >
                            <div class="col-md-10">
                                <div
                                    fxLayout="row"
                                    fxLayoutAlign="space-between"
                                >
                                    <div class="col-md-1">
                                        <mat-checkbox
                                            [checked]="isSelectedNews(news)"
                                            (change)="toggleSelectedNews(news)"
                                        ></mat-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <img
                                            class="list-item__thumb thumb"
                                            [src]="
                                                '/api/news/' +
                                                news.uid +
                                                '/teaser/show?height=60'
                                            "
                                            [alt]="news.headline"
                                        />
                                    </div>
                                    <div class="col-md-9">
                                        <div>
                                            <strong
                                                class="list-item__description"
                                                [title]="news.headline"
                                            >
                                                {{
                                                    news.headline
                                                        | shorten: 60:'...'
                                                }}
                                            </strong>
                                        </div>
                                        <div
                                            class="list-item__subDescription"
                                            [title]="news.summary"
                                        >
                                            {{
                                                news.summary | shorten: 60:'...'
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <span class="fileUploadText">
                                    {{ news.date | date: 'dd.MM.yyyy' }}
                                </span>
                            </div>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
            <div class="file-list news-edit__spaceAfter">
                <h2>Assets</h2>
                <div class="filter row">
                    <div class="col-2">
                        <mat-form-field class="dropDown">
                            <mat-select [(value)]="filter.model">
                                <mat-option [value]="0">Auswählen</mat-option>
                                <mat-option
                                    *ngFor="let model of assetModels"
                                    [value]="model.uid"
                                >
                                    {{ model.title }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field class="dropDown">
                            <mat-select [(value)]="filter.modelYear">
                                <mat-option [value]="0">Auswählen</mat-option>
                                <mat-option
                                    *ngFor="let modelYear of assetModelYears"
                                    [value]="modelYear.uid"
                                >
                                    {{ modelYear.title }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field class="dropDown">
                            <mat-select
                                [(value)]="filter.tag"
                                (openedChange)="
                                    assetTagFilterInput.value = '';
                                    filterAssetsTags('')
                                "
                            >
                                <mat-form-field>
                                    <input
                                        #assetTagFilterInput
                                        matInput
                                        (keyup)="
                                            filterAssetsTags(
                                                assetTagFilterInput.value
                                            )
                                        "
                                    />
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <mat-option [value]="0">Auswählen</mat-option>
                                <mat-option
                                    *ngFor="let tag of assetFilteredTags"
                                    [value]="tag.uid"
                                >
                                    {{ tag.title }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <span class="datePick">Datum von</span>
                        <mat-form-field class="datePicker">
                            <mat-label>TT.MM.JJJJ</mat-label>
                            <input
                                [(ngModel)]="filter.dateFrom"
                                [ngModelOptions]="{ standalone: true }"
                                name="dateFromInput"
                                matInput
                                [matDatepicker]="dateFrom"
                            />
                            <div matSuffix *ngIf="filter.dateFrom">
                                <mat-icon (click)="filter.dateFrom = null">
                                    clear
                                </mat-icon>
                            </div>
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="dateFrom"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #dateFrom></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <span class="datePick">Datum bis</span>
                        <mat-form-field class="datePicker">
                            <mat-label>TT.MM.JJJJ</mat-label>
                            <input
                                [(ngModel)]="filter.dateTo"
                                [ngModelOptions]="{ standalone: true }"
                                name="dateToInput"
                                matInput
                                [matDatepicker]="dateTo"
                            />
                            <div matSuffix *ngIf="filter.dateTo">
                                <mat-icon (click)="filter.dateTo = null">
                                    clear
                                </mat-icon>
                            </div>
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="dateTo"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #dateTo></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <button
                            mat-raised-button
                            type="button"
                            (click)="applyFileFilter()"
                        >
                            Übernehmen
                        </button>
                    </div>
                </div>
                <div
                    class="file-list-header"
                    fxLayout="row"
                    fxLayoutAlign="space-between"
                >
                    <div class="col-md-7">Name</div>
                    <div class="col-md-2">Größe</div>
                    <div class="col-md-2">Datum</div>
                    <div class="col-md-1">Teaser</div>
                </div>
                <cdk-virtual-scroll-viewport
                    itemSize="60"
                    style="height: 300px"
                >
                    <div
                        class="file"
                        *cdkVirtualFor="let file of fileDataSource"
                        style="height: 60px"
                    >
                        <div
                            class="list-item"
                            fxLayout="row"
                            fxLayoutAlign="space-between"
                        >
                            <div class="col-md-7">
                                <div
                                    fxLayout="row"
                                    fxLayoutAlign="space-between"
                                >
                                    <div class="col-md-1">
                                        <mat-checkbox
                                            [checked]="isAssetSelected(file)"
                                            (change)="toggleSelectedAsset(file)"
                                        ></mat-checkbox>
                                    </div>
                                    <div
                                        class="col-md-2"
                                        *ngIf="
                                            file.type !== AssetType.OTHER ||
                                            file.fileExtension === 'pdf'
                                        "
                                    >
                                        <img
                                            class="list-item__thumb thumb"
                                            [src]="
                                                '/api/asset/' +
                                                file.uid +
                                                '/show?height=60'
                                            "
                                            [alt]="file.title"
                                        />
                                    </div>
                                    <div class="col-md-9">
                                        <div>
                                            <strong
                                                class="list-item__description"
                                                [title]="file.title"
                                            >
                                                {{
                                                    file.title
                                                        | shorten: 60:'...'
                                                }}
                                            </strong>
                                        </div>
                                        <span
                                            class="list-item__subDescription"
                                            [title]="file.description"
                                        >
                                            {{
                                                file.description
                                                    | shorten: 30:'...'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <span class="fileUploadText">
                                    {{ file.size | bytes: 2 }}
                                </span>
                            </div>
                            <div class="col-md-2">
                                <span class="fileUploadText">
                                    {{ file.date | date: 'dd.MM.yyyy' }}
                                </span>
                            </div>
                            <div class="col-md-1">
                                <mat-checkbox
                                    *ngIf="file.type === AssetType.IMAGE"
                                    [checked]="selectedTeaserAsset === file.uid"
                                    (change)="selectAssetForTeaser(file)"
                                ></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
            <div>
                <h2>EnVKV Angaben</h2>
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <div fxFlex="50">
                        <h4>Modell</h4>
                    </div>
                </div>
                <div
                    fxLayout="row"
                    fxLayoutAlign="space-between"
                    class="news-edit__spaceAfter"
                >
                    <div fxFlex="100">
                        <div fxLayout="row" fxLayoutAlign="space-between end">
                            <div fxFlex="50">
                                <select multiple class="select-list">
                                    <option
                                        *ngFor="let model of allModelYears"
                                        (click)="toggleEnvkvModel(model)"
                                    >
                                        {{ model.title }}
                                    </option>
                                </select>
                            </div>
                            <div fxFlex="50">
                                <select multiple class="select-list">
                                    <option
                                        *ngFor="
                                            let model of selectedEnvkvModels
                                        "
                                        (click)="toggleEnvkvModel(model)"
                                    >
                                        {{ model.title }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <div fxFlex="50">
                        <h4>Fahrzeuge</h4>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <select multiple class="select-list">
                        <option
                            *ngFor="let vehicle of allVehicleYears"
                            (click)="toggleEnvkvVehicle(vehicle)"
                        >
                            {{ vehicle.title }}
                        </option>
                    </select>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <select multiple class="select-list">
                        <option
                            *ngFor="let vehicle of selectedEnvkvVehicles"
                            (click)="toggleEnvkvVehicle(vehicle)"
                        >
                            {{ vehicle.title }}
                        </option>
                    </select>
                </div>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <button>Speichern</button>
        </div>
    </form>
</div>
