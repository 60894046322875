import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-m0230-text-video',
    templateUrl: './m0230-text-video.component.html',
    styleUrls: ['./m0230-text-video.component.scss']
})
export class M0230TextVideoComponent implements OnInit {
    @Input() videoright: string;
    @Input() videotype: 'mp4' | 'youtube';
    @Input() headline: string;
    @Input() text: string;
    @Input() youtubeid: string;
    @Input() mp4: string;

    youtube = '';

    constructor() {}

    ngOnInit(): void {
        if (this.youtubeid) {
            this.youtube =
                'https://www.youtube-nocookie.com/embed/' + this.youtubeid;
        }
    }
}
