import { Component, OnInit, Input } from '@angular/core';

export interface TextVideo {
    videoright: string;
    videotype: string;
    size: string;
    headline?: string;
    mp4?: string;
    text?: string;
    youtubeid?: string;
}

@Component({
    selector: 'px-text-video',
    templateUrl: './text-video.component.html',
    styleUrls: ['./text-video.component.scss']
})
export class TextVideoComponent implements OnInit {
    content: TextVideo;

    @Input()
    set data(data: string) {
        this.content = JSON.parse(data);
    }

    constructor() {}

    ngOnInit(): void {}
}
