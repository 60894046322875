import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'px-a00240-line-divider',
    templateUrl: './a00240-line-divider.component.html',
    styleUrls: ['./a00240-line-divider.component.scss']
})
export class A00240LineDividerComponent implements OnInit {
    @Input() modifier: string;
    @Input() text: string;

    constructor() {}

    ngOnInit(): void {}
}
