<h1>News</h1>
<button (click)="addNewNews()">Neue News anlegen</button>

<cdk-virtual-scroll-viewport
    itemSize="60"
    style="
        min-height: 600px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 100px;
    "
    class="news-manager"
>
    <div
        class="file news-manager__file"
        *cdkVirtualFor="let news of newsDataSource"
        style="height: 80px"
    >
        <div class="list-item" fxLayout="row" fxLayoutAlign="space-between">
            <div class="col-md-10">
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <div class="col-md-2">
                        <button (click)="openEditDialog(news.uid)">
                            Bearbeiten
                        </button>
                        <button (click)="deleteNews(news.uid, news.headline)">
                            Löschen
                        </button>
                    </div>
                    <div class="col-md-2">
                        <img
                            class="list-item__thumb thumb"
                            [src]="
                                '/api/news/' +
                                news.uid +
                                '/teaser/show?height=80&t=' +
                                tstamp
                            "
                            [alt]="news.headline"
                        />
                    </div>
                    <div class="col-md-8">
                        <div>
                            <strong
                                class="list-item__description"
                                [title]="news.headline"
                            >
                                {{ news.headline | shorten: 60:'...' }}
                            </strong>
                        </div>
                        <div
                            class="list-item__subDescription"
                            [title]="news.summary"
                        >
                            {{ news.summary | shorten: 60:'...' }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <span class="fileUploadText">
                    {{ news.date | date: 'dd.MM.yyyy' }}
                </span>
            </div>
        </div>
    </div>
</cdk-virtual-scroll-viewport>
