<div class="a00040 a00040--{{ modifier }}">
    <a
        [attr.href]="link"
        (click)="clicked.emit()"
        class="a00040_{{ modifier }}"
        [class.disabled]="disabled"
        [type]="type"
    >
        <span class="text">{{ text }}</span>
        <div class="arrow">
            <span
                [inlineSVG]="
                    '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/arrow/white.svg'
                "
            ></span>
        </div>
    </a>
</div>
