import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { A00250TextAndLineDividerModule } from './a00250-text-and-line-divider/a00250-text-and-line-divider.module';
import { A00070TertiaryButtonSmallModule } from './a00070-tertiary-button-small/a00070-tertiary-button-small.module';
import { A00010HeaderLogoModule } from './a00010-header-logo/a00010-header-logo.module';
import { A00020AttributeModule } from './a00020-attribute/a00020-attribute.module';
import { A00030PrimaryButtonModule } from './a00030-primary-button/a00030-primary-button.module';
import { A00060TertiaryButtonFullModule } from './a00060-tertiary-button-full/a00060-tertiary-button-full.module';
import { A00050SecondaryButtonModule } from './a00050-secondary-button/a00050-secondary-button.module';
import { A00040PrimaryButtonIconModule } from './a00040-primary-button-icon/a00040-primary-button-icon.module';
import { A00080ToTopButtonModule } from './a00080-to-top-button/a00080-to-top-button.module';
import { A00090PaginationModule } from './a00090-pagination/a00090-pagination.module';
import { A00100DownloadButtonModule } from './a00100-download-button/a00100-download-button.module';
import { A00110ForwardBackLinkModule } from './a00110-forward-back-link/a00110-forward-back-link.module';
import { A00160ExpanderModule } from './a00160-expander/a00160-expander.module';
import { A00170InputTitleModule } from './a00170-input-title/a00170-input-title.module';
import { A00180InputTextModule } from './a00180-input-text/a00180-input-text.module';
import { A00190InputTextareaModule } from './a00190-input-textarea/a00190-input-textarea.module';
import { A00210InputCheckboxModule } from './a00210-input-checkbox/a00210-input-checkbox.module';
import { A00120AccordionModule } from './a00120-accordion/a00120-accordion.module';
import { A00130UnderlineLinkModule } from './a00130-underline-link/a00130-underline-link.module';
import { A00140TextLinkModule } from './a00140-text-link/a00140-text-link.module';
import { A00290FooterNavModule } from './a00290-footer-nav/a00290-footer-nav.module';
import { A00280OverlayLightboxModule } from './a00280-overlay-lightbox/a00280-overlay-lightbox.module';
import { A00150ContactLinksModule } from './a00150-contact-links/a00150-contact-links.module';
import { M0160FormsModule } from '../molecules/m0160-forms/m0160-forms.module';
import { A00270ScrollbarModule } from './a00270-scrollbar/a00270-scrollbar.module';
import { A00240LineDividerModule } from './a00240-line-divider/a00240-line-divider.module';
import { A00260TextLineIconDividerModule } from './a00260-text-line-icon-divider/a00260-text-line-icon-divider.module';
import { A00220MediaCardModule } from './a00220-media-card/a00220-media-card.module';
import { A00200SearchfieldModule } from './a00200-searchfield/a00200-searchfield.module';
import {A00310AssetSavedModule} from './a00310-asset-saved/a00310-asset-saved.module';
import {A00320IconButtonModule} from './a00320-icon-button/a00320-icon-button.module';

@NgModule({
    imports: [
        CommonModule,
        A00070TertiaryButtonSmallModule,
        A00250TextAndLineDividerModule,
        A00010HeaderLogoModule,
        A00020AttributeModule,
        A00030PrimaryButtonModule,
        A00040PrimaryButtonIconModule,
        A00050SecondaryButtonModule,
        A00060TertiaryButtonFullModule,
        A00080ToTopButtonModule,
        A00090PaginationModule,
        A00100DownloadButtonModule,
        A00110ForwardBackLinkModule,
        A00160ExpanderModule,
        A00170InputTitleModule,
        A00180InputTextModule,
        A00190InputTextareaModule,
        A00210InputCheckboxModule,
        A00120AccordionModule,
        A00130UnderlineLinkModule,
        A00140TextLinkModule,
        A00290FooterNavModule,
        A00280OverlayLightboxModule,
        A00150ContactLinksModule,
        M0160FormsModule,
        A00270ScrollbarModule,
        A00240LineDividerModule,
        A00260TextLineIconDividerModule,
        A00220MediaCardModule,
        A00200SearchfieldModule,
        A00310AssetSavedModule,
        A00320IconButtonModule,
    ],
    providers: [],
    declarations: [],
    exports: [
        A00070TertiaryButtonSmallModule,
        A00250TextAndLineDividerModule,
        A00010HeaderLogoModule,
        A00020AttributeModule,
        A00030PrimaryButtonModule,
        A00040PrimaryButtonIconModule,
        A00050SecondaryButtonModule,
        A00060TertiaryButtonFullModule,
        A00080ToTopButtonModule,
        A00090PaginationModule,
        A00100DownloadButtonModule,
        A00110ForwardBackLinkModule,
        A00160ExpanderModule,
        A00170InputTitleModule,
        A00180InputTextModule,
        A00190InputTextareaModule,
        A00210InputCheckboxModule,
        A00120AccordionModule,
        A00130UnderlineLinkModule,
        A00140TextLinkModule,
        A00290FooterNavModule,
        A00280OverlayLightboxModule,
        A00150ContactLinksModule,
        M0160FormsModule,
        A00270ScrollbarModule,
        A00240LineDividerModule,
        A00260TextLineIconDividerModule,
        A00220MediaCardModule,
        A00200SearchfieldModule,
        A00310AssetSavedModule,
        A00320IconButtonModule,
    ]
})
export class AtomsModule {}
