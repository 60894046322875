<div class="a00130">
    <a
        class="a00130__link"
        href="{{ href }}"
        target="{{ target }}"
        [class.a00130__link--active]="active"
    >
        <ng-content></ng-content>
    </a>
</div>
