<div class="a00260 a00260--{{ modifier }}">
    <div class="row">
        <div class="col-12">
            <div class="a00260__wrapper">
                <div class="text">{{ text }}</div>

                <ng-container *ngIf="modifier == 'a'">
                    <div class="right-icons underline">
                        <span
                            [inlineSVG]="
                                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/sort_kachel/active.svg'
                            "
                        ></span>
                        <span
                            (click)="onClick()"
                            [inlineSVG]="
                                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/sort_liste/inactive.svg'
                            "
                        ></span>
                    </div>
                </ng-container>

                <ng-container *ngIf="modifier == 'b'">
                    <div class="right-icons">
                        <span
                            (click)="onClick()"
                            [inlineSVG]="
                                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/sort_kachel/inactive.svg'
                            "
                        ></span>
                        <span
                            [inlineSVG]="
                                '/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/sort_liste/active.svg'
                            "
                        ></span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
