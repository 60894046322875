import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'px-new-footer',
  templateUrl: './new-footer.component.html',
  styleUrls: ['./new-footer.component.scss']
})
export class NewFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}

