
  <div class="row full-width" [style.background-color]="bgColor">
    <div class="container">
        <div class="m-0180">
            <div class="col-12" [ngClass]="{'m-0180__col--video': size === 'small'}">
                <div class="row">
                    <ng-container *ngIf="videotype === 'youtube'">
                        <ng-container [ngTemplateOutlet]="youtubeVideo"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="videotype === 'mp4'">
                        <ng-container [ngTemplateOutlet]="mp4Video"></ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="col-12" [ngClass]="{'m-0180__col--text': size === 'small'}">
                <div class="m-0180__content" [ngClass]="{'m-0180__content--small': size === 'small'}">

                    <ng-container *ngIf="headline">
                        <ng-container *ngIf="!headlineType || headlineType === 'h2'">
                            <h2 class="m-0180__headline" [ngClass]="{'m-0180__headline--white': textColor === 'white'}" [innerHTML]="headline"></h2>
                        </ng-container>
                        <ng-container *ngIf="headlineType === 'h1'">
                            <h1 class="m-0180__headline" [ngClass]="{'m-0180__headline--white': textColor === 'white'}" [innerHTML]="headline"></h1>
                        </ng-container>
                        <ng-container *ngIf="headlineType === 'h3'">
                            <h3 class="m-0180__headline" [ngClass]="{'m-0180__headline--white': textColor === 'white'}" [innerHTML]="headline"></h3>
                        </ng-container>
                        <ng-container *ngIf="headlineType === 'h4'">
                            <h4 class="m-0180__headline" [ngClass]="{'m-0180__headline--white': textColor === 'white'}" [innerHTML]="headline"></h4>
                        </ng-container>
                        <ng-container *ngIf="headlineType === 'h5'">
                            <h5 class="m-0180__headline" [ngClass]="{'m-0180__headline--white': textColor === 'white'}" [innerHTML]="headline"></h5>
                        </ng-container>
                        <ng-container *ngIf="headlineType === 'h6'">
                            <h6 class="m-0180__headline" [ngClass]="{'m-0180__headline--white': textColor === 'white'}" [innerHTML]="headline"></h6>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="text">
                        <div class="m-0180__copy" [ngClass]="{'m-0180__copy--white': textColor === 'white'}" [innerHTML]="text"></div>
                    </ng-container>

                    <ng-container *ngIf="size === 'small'">
                        <ng-container *ngIf="ctaType === 'button'">
                            <div class="m-0180__button">
                                <ng-container *ngIf="ctaLink">
                                    <a class="a-00200 a-00200--b" [href]="ctaLink">
                                        <div class="easing"></div>
                                        <span>{{ctaText}}</span>
                                        <div class="icon" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/pfeil_gross_schwarz.svg'">
                                        </div>
                                    </a>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="ctaType === 'link'">

                            <div class="m-0180__button">
                                <div class="a-00210" [ngClass]="{'a-00210--c': textColor === 'black', 'a-00210--g': textColor === 'white'}">
                                    <ng-container *ngIf="ctaLink">
                                        <a class="link" [href]="ctaLink">
                                            <span>{{ctaText}}</span>
                                            <div class="arrowRight" [inlineSVG]="'/typo3conf/ext/main_configuration/Resources/Public/dist/ng-press/assets/icons/pfeil_klein_rot.svg'">
                                            </div>
                                        </a>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #youtubeVideo>
    <div class="embed-responsive embed-responsive-16by9" *ngIf="youtube">
        <iframe class="embed-responsive-item m-0060__youtubeIframe" [src]="youtube | safeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope" allowfullscreen></iframe>
    </div>
</ng-template>

<ng-template #mp4Video>
    <div class="embed-responsive embed-responsive-16by9" *ngIf="mp4">
        <video class="embed-responsive-item" controls>
            <source muted src="{{mp4}}" type="video/mp4">
        </video>
    </div>
</ng-template>
