import { Component, OnInit, Input } from '@angular/core';
import { M0070Tile } from 'src/app/interfaces/molecules/m0070-newsteaser-tile';
import { DownloadCenterService } from '../../../services/download-center.service';

@Component({
    selector: 'px-m0080-newsteaser-list',
    templateUrl: './m0080-newsteaser-list.component.html',
    styleUrls: ['./m0080-newsteaser-list.component.scss']
})
export class M0080NewsteaserListComponent implements OnInit {
    @Input()
    tile: M0070Tile;

    constructor(private downloadCenterService: DownloadCenterService) {}

    ngOnInit(): void {}

    addNewsToDownlodCenter(news: number[]) {
        this.downloadCenterService.addNewsToDownlodCenter(news);
    }

    checkAllAssetsInDownloadCenter(news: number[]): boolean {
        return this.downloadCenterService.checkAssetsArray(news);
    }

}
