import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { T40MediacenterModule } from './t40-mediacenter/t40-mediacenter.module';
import { T10NewsTileListModule } from './t10-news-tile-list/t10-news-tile-list.module';

@NgModule({
    declarations: [],
    imports: [CommonModule, T10NewsTileListModule, T40MediacenterModule],
    exports: [T10NewsTileListModule, T40MediacenterModule]
})
export class TemplatesModule {}
