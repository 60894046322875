import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0160FormsComponent } from './m0160-forms.component';

@NgModule({
    declarations: [M0160FormsComponent],
    imports: [CommonModule],
    exports: [M0160FormsComponent]
})
export class M0160FormsModule {}
