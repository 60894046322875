import { Component, Input, OnInit } from '@angular/core';

export interface BulletListTeaser {
    image?: string;
    imageTablet?: string;
    imageMobile?: string;
    headline: string;
    headlineType: string;
    bulletpoints: BulletListBulletpoints[];
}
interface BulletListBulletpoints {
    bullet: {
        headline: string;
    };
}

@Component({
    selector: 'px-bullet-list-teaser',
    templateUrl: './bullet-list-teaser.component.html',
    styleUrls: ['./bullet-list-teaser.component.scss']
})
export class BulletListTeaserComponent implements OnInit {
    content: BulletListTeaser;
    bulletpointsChunks?: BulletListBulletpoints[][] = [];

    @Input()
    set data(data: string) {
        this.content = JSON.parse(data);

        const bulletpoints = [];
        for (const key in this.content.bulletpoints) {
            const bullet = this.content.bulletpoints[key];
            bulletpoints.push(bullet);
        }
        const tmpSplit = [];
        const chunkSize = Math.ceil(bulletpoints.length / 2);
        for (let i = 0; i < bulletpoints.length; i += chunkSize) {
            const chunk = bulletpoints.slice(i, i + chunkSize);
            tmpSplit.push(chunk);
        }
        this.bulletpointsChunks = [];
        this.bulletpointsChunks = tmpSplit;
    }

    constructor() {}

    ngOnInit(): void {}
}
