import { Component, Input, OnChanges } from '@angular/core';
import { A00220MediaCardType } from 'src/app/interfaces/atoms/a00220-media-card';
import { Image } from '../../../services/api.service';

@Component({
    selector: 'px-a00220-media-card',
    templateUrl: './a00220-media-card.component.html',
    styleUrls: ['./a00220-media-card.component.scss']
})
export class A00220MediaCardComponent implements OnChanges {
    @Input() modifier: A00220MediaCardType = A00220MediaCardType.A;
    @Input() image: Image;

    src = '';

    A00220MediaCardType = A00220MediaCardType;

    constructor() {}

    ngOnChanges(): void {
        if (this.image) {
            this.src = this.image.toUrl(311);
        }
    }
}
