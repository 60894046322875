import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'px-m0120-text-image',
    templateUrl: './m0120-text-image.component.html',
    styleUrls: ['./m0120-text-image.component.scss']
})
export class M0120TextImageComponent implements OnInit {
    @Input() imageright: string;
    @Input() image: string;
    @Input() headline: string;
    @Input() text: string;

    constructor() {
    }

    ngOnInit(): void {
    }
}
