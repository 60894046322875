export enum TYPO3PageContentTypes {
    // typo3 content
    HEADER = 'header',
    TEXT = 'text',
    BULLETS = 'bullets',
    // custom content
    ANCHOR = 'mainconfiguration_anchor',
    VIDEOTEASER = 'mainconfiguration_contentpagevideoteaser',
    HEADLINE = 'mainconfiguration_headline',
    MARGIN = 'mainconfiguration_margin',
    AUDIO = 'mainconfiguration_audio',
    KEYVISUAL = 'mainconfiguration_keyvisual',
    TEXTIMAGE = 'mainconfiguration_textimage',
    TEXTVIDEO = 'mainconfiguration_textvideo',
    SOCIALMEDIA = 'mainconfiguration_socialmedia'
}
