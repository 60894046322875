import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFooterComponent } from './contact-footer.component';
import { O100ContactFooterModule } from '../../organisms/o100-contact-footer/o100-contact-footer.module';

@NgModule({
    declarations: [ContactFooterComponent],
    exports: [
        ContactFooterComponent
    ],
    imports: [CommonModule, O100ContactFooterModule]
})
export class ContactFooterModule {}
