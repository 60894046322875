import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryOverlayComponent } from './gallery-overlay.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    declarations: [GalleryOverlayComponent],
    imports: [CommonModule, InlineSVGModule.forRoot()],
    exports: [GalleryOverlayComponent]
})
export class GalleryOverlayModule {}
