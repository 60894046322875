import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M0200BulletListTeaserComponent } from './m0200-bullet-list-teaser.component';

@NgModule({
    declarations: [M0200BulletListTeaserComponent],
    imports: [CommonModule],
    exports: [M0200BulletListTeaserComponent]
})
export class M0200BulletListTeaserModule {}
